@media (max-width: 767px) {
  .auth-main-content {
    .right-block {
      display: none !important;
    }

    .left-block {
      max-width: 100%;
      flex: 1 1 100%;

      div.footer-line p {
        position: relative;
        bottom: 0;
      }
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));
  grid-gap: 1rem;
}

// Responsive Typo For  Font Size
.dashboard-content {
  .dashboard-title {
    @media screen and (max-width: 1439px) {
      font-size: 17px !important;
      line-height: 17px;
    }

    @media screen and (max-width: 1350px) {
      font-size: 17px !important;
      line-height: 16px;
    }

    @media screen and (max-width: 1300px) {
      font-size: 16px !important;
      line-height: 15px;
    }

    @media screen and (max-width: 1250px) {
      font-size: 15px !important;
      line-height: 14px;
    }

    @media screen and (max-width: 1200px) {
      font-size: 14px !important;
      line-height: 13px;
    }
  }

  &.rt-dashboard-content {
    .dashboard-over-all-card {
      .dashboard-over-summary-list {
        .total-task-title {
          p {
            @media screen and (max-width: 1439px) {
              font-size: 14px !important;
            }

            @media screen and (max-width: 1350px) {
              font-size: 14px !important;
            }

            @media screen and (max-width: 1300px) {
              font-size: 13px !important;
            }

            @media screen and (max-width: 1250px) {
              font-size: 12px !important;
            }

            @media screen and (max-width: 1200px) {
              font-size: 11px !important;
            }
          }
        }

        .total-task-count {
          h2 {
            @media screen and (max-width: 1439px) {
              font-size: 25px !important;
            }

            @media screen and (max-width: 1350px) {
              font-size: 25px !important;
            }

            @media screen and (max-width: 1300px) {
              font-size: 24px !important;
            }

            @media screen and (max-width: 1250px) {
              font-size: 22px !important;
            }

            @media screen and (max-width: 1200px) {
              font-size: 20px !important;
            }
          }
        }
      }
    }

    .dashboard-today-sum {
      .dashboard-today-sum-inn {
        &.new-spc-dashboard-today-sum-inn {
          height: 172px;
          padding: 12px 14px;
          border: 1px solid transparent;
          transition: all 0.3s ease-in-out;

          &:hover {
            border: 1px solid #00366440;
            transition: all 0.3s ease-in-out;
          }
        }

        .dashboard-today-sum-title {
          h2 {
            @media screen and (max-width: 1439px) {
              font-size: 25px !important;
            }

            @media screen and (max-width: 1350px) {
              font-size: 25px !important;
            }

            @media screen and (max-width: 1300px) {
              font-size: 24px !important;
            }

            @media screen and (max-width: 1250px) {
              font-size: 22px !important;
            }

            @media screen and (max-width: 1200px) {
              font-size: 20px !important;
            }
          }
        }
      }
    }

    .no-task-title {
      @media screen and (max-width: 1439px) {
        font-size: 14px;
      }

      @media screen and (max-width: 1300px) {
        font-size: 14px;
      }

      @media screen and (max-width: 1200px) {
        font-size: 13px;
      }
    }

    button.add-task {
      @media screen and (max-width: 1300px) {
        font-size: 13px;
      }

      @media screen and (max-width: 1200px) {
        font-size: 12px;
      }
    }

    .fc {
      .fc-button {
        @media screen and (max-width: 1439px) {
          font-size: 15px;
          line-height: 14px;
        }

        @media screen and (max-width: 1300px) {
          font-size: 14px;
          line-height: 13px;
        }

        @media screen and (max-width: 1200px) {
          font-size: 13px;
        }
      }

      .fc-icon-chevron-left,
      .fc-icon-chevron-right {
        &::before {
          @media screen and (max-width: 1439px) {
            font-size: 20px;
          }

          @media screen and (max-width: 1350px) {
            font-size: 18px;
          }
        }
      }
    }

    .dashboard-full-model-cal-ctm {
      .fc-list-day-cushion {
        .fc-list-day-text {
          @media screen and (max-width: 1439px) {
            font-size: 15px;
          }

          @media screen and (max-width: 1300px) {
            font-size: 14px;
          }

          @media screen and (max-width: 1200px) {
            font-size: 13px;
          }
        }
      }
    }

    .team-incompleted-task {
      .team-incompleted-task-inn {
        .user-list {
          p {
            @media screen and (max-width: 1439px) {
              font-size: 13px;
            }

            @media screen and (max-width: 1300px) {
              font-size: 12px;
            }

            @media screen and (max-width: 1200px) {
              font-size: 11px;
            }
          }
        }
      }
    }

    .priority-task-block {
      .priority-task-inner {
        .priority-task {

          p,
          span {
            @media screen and (max-width: 1439px) {
              font-size: 13px;
            }

            @media screen and (max-width: 1300px) {
              font-size: 12px;
            }

            @media screen and (max-width: 1200px) {
              font-size: 11px;
            }
          }
        }
      }
    }

    .view-all {
      button {
        @media screen and (max-width: 1439px) {
          font-size: 13px !important;
        }

        @media screen and (max-width: 1300px) {
          font-size: 12px;
        }

        @media screen and (max-width: 1200px) {
          font-size: 11px;
        }
      }
    }

    .task--content-table {
      .mat-cell {
        @media screen and (max-width: 1439px) {
          font-size: 13px;
        }

        @media screen and (max-width: 1300px) {
          font-size: 12px;
        }
      }
    }

    .task--title {
      @media screen and (max-width: 1439px) {
        font-size: 13px;
      }

      @media screen and (max-width: 1300px) {
        font-size: 12px;
      }
    }

    .dashboard-recent-project {
      .inner-detail-card {
        .dash-mat-card-header {
          .dash-mat-card-header-inn {
            h4 {
              @media screen and (max-width: 1439px) {
                font-size: 15px;
              }

              @media screen and (max-width: 1300px) {
                font-size: 14px;
              }
            }
          }

          p {
            @media screen and (max-width: 1439px) {
              font-size: 13px;
            }

            @media screen and (max-width: 1300px) {
              font-size: 12px;
            }
          }
        }

        .dash-task-completed-ctn {
          p {
            @media screen and (max-width: 1439px) {
              font-size: 13px;
            }

            @media screen and (max-width: 1300px) {
              font-size: 12px;
            }
          }
        }
      }
    }

    .new-spc-statistics {
      .dashboard-title {
        margin-bottom: 3px;
      }

      .mat-tab-labels {
        .mat-tab-label {
          min-width: 250px !important;
        }
      }
    }
  }
}

.custom-calendar {
  .fc-header-toolbar {
    .fc-toolbar-chunk {
      .fc-toolbar-title {
        @media screen and (max-width: 1439px) {
          font-size: 15px !important;
          line-height: 15px;
        }

        @media screen and (max-width: 1300px) {
          font-size: 15px !important;
          line-height: 14px;
        }

        @media screen and (max-width: 1200px) {
          font-size: 14px !important;
          line-height: 13px;
        }
      }
    }
  }
}

.math_ui_inside-recent-discussion {
  .mat-tab-body-wrapper {
    &::-webkit-scrollbar-track {
      background-color: #fff !important;
      box-shadow: inset 0 0 5px #fff !important;
      border: 5px solid #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border: 5px solid #fff;
    }

    .mat-tab-body-content {
      &::-webkit-scrollbar-track {
        background-color: #fff !important;
        box-shadow: inset 0 0 5px #fff !important;
        border: 5px solid #fff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border: 5px solid #fff;
      }
    }
  }
}

.badge {
  @media screen and (max-width: 1439px) {
    font-size: 13px !important;
  }

  @media screen and (max-width: 1300px) {
    font-size: 12px !important;
  }
}

.mat-menu-panel {
  &.custom-user-list {
    ul {
      .c-details {
        h4 {
          @media screen and (max-width: 1439px) {
            font-size: 13px !important;
          }

          @media screen and (max-width: 1300px) {
            font-size: 12px !important;
          }
        }
      }

      li {
        padding-bottom: 5px;
      }
    }
  }
}

.discussion-title {
  @media screen and (max-width: 1439px) {
    font-size: 13px !important;
  }

  @media screen and (max-width: 1300px) {
    font-size: 12px !important;
  }
}

.mat-tabe-content {
  .mat-tab-body-wrapper {
    .mat-tab-body {
      .mat-tab-body-content {
        .user-chat-box-cover {
          .user-chat-box {
            .user-name {
              p {
                @media screen and (max-width: 1439px) {
                  padding-top: 0;
                }
              }

              h2 {
                gap: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.filter-notification-page-ctm {
  .mat-card {
    .notification-list-card {
      .card-user-information {
        .card-profile-title {
          .card-profile-img {
            p {
              @media screen and (max-width: 1439px) {
                font-size: 13px !important;
              }

              @media screen and (max-width: 1300px) {
                font-size: 12px !important;
              }
            }
          }
        }
      }

      .card-title-ctm {
        margin-bottom: 10px;

        .card-company-name {
          p {
            @media screen and (max-width: 1439px) {
              font-size: 13px !important;
            }

            @media screen and (max-width: 1300px) {
              font-size: 12px !important;
            }
          }
        }
      }
    }

    .filter-notification-page-ctm {
      .mat-card {
        .notification-list-card {
          .card-user-information {
            .card-profile-title {
              .card-profile-img {
                .online--status {
                  bottom: 3px;
                  right: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  .notification-wrp-content {
    .mat-tab-header {
      margin-bottom: 10px;
    }
  }
}

.filter-notification-page-ctm-parent {
  &.filter-notification-page-ctm {
    .mat-card {
      padding: 8px;

      .notification-list-card {
        .card-title-ctm {
          margin-bottom: 6px;
        }

        .card-user-information {
          .card-profile-title {
            .card-profile-img {

              .user-short-name,
              img {
                width: 30px;
                height: 30px;
              }
            }
          }
        }
      }
    }
  }
}

.main-sidebar {
  &.mat-drawer {
    .sidebar-menu-section {
      &>li {
        .nav-link {
          label {
            @media screen and (max-width: 1439px) {
              font-size: 13px;
            }

            @media screen and (max-width: 1300px) {
              font-size: 12px;
            }
          }

          img {
            @media screen and (max-width: 1439px) {
              width: 17px;
              height: 15px;
            }
          }
        }
      }
    }
  }
}

.math_ui_inside-custom-dred {
  .card-profile-img {
    .online--status {
      bottom: 4px !important;
      right: 14px !important;
    }
  }
}

// Tasks
.rt-task-content {
  .my-task-list-related-cls {
    .mat-expansion-panel-header-title {
      @media screen and (max-width: 1439px) {
        font-size: 17px !important;
      }

      @media screen and (max-width: 1300px) {
        font-size: 16px !important;
      }

      @media screen and (max-width: 1200px) {
        font-size: 15px !important;
      }
    }

    table {
      tbody {
        tr {
          td {
            .task--title {
              @media screen and (max-width: 1439px) {
                font-size: 12px;
              }

              @media screen and (max-width: 1300px) {
                font-size: 12px;
              }
            }

            .mat-column-status {
              .badge {
                @media screen and (max-width: 1439px) {
                  font-size: 12px;
                }

                @media screen and (max-width: 1300px) {
                  font-size: 12px;
                }
              }
            }

            .mat-column-due_date {
              div {
                @media screen and (max-width: 1439px) {
                  font-size: 12px !important;
                }

                @media screen and (max-width: 1300px) {
                  font-size: 12px !important;
                }
              }
            }

            .mat-column-phone_number {
              @media screen and (max-width: 1439px) {
                font-size: 12px !important;
              }

              @media screen and (max-width: 1300px) {
                font-size: 12px !important;
              }
            }

            .mat-column-reporter {
              @media screen and (max-width: 1439px) {
                font-size: 12px !important;
              }

              @media screen and (max-width: 1300px) {
                font-size: 12px !important;
              }
            }

            .mat-column-test_for_options {
              @media screen and (max-width: 1439px) {
                font-size: 12px !important;
              }

              @media screen and (max-width: 1300px) {
                font-size: 12px !important;
              }
            }

            .mat-column-test_for_auto_numberhgh {
              @media screen and (max-width: 1439px) {
                font-size: 12px !important;
              }

              @media screen and (max-width: 1300px) {
                font-size: 12px !important;
              }
            }

            .mat-column-url {
              @media screen and (max-width: 1439px) {
                font-size: 12px !important;
              }

              @media screen and (max-width: 1300px) {
                font-size: 12px !important;
              }
            }

            &.mat-cell {
              @media screen and (max-width: 1439px) {
                font-size: 12px !important;
              }

              @media screen and (max-width: 1300px) {
                font-size: 12px !important;
              }
            }
          }
        }
      }
    }
  }

  .c-s-input {
    @media screen and (max-width: 1439px) {
      font-size: 12px !important;
    }

    @media screen and (max-width: 1300px) {
      font-size: 12px !important;
    }
  }
}

// Task Filter
.rt-task-filter {
  ul {
    .task-search {
      input {
        @media screen and (max-width: 1439px) {
          font-size: 12px;
        }

        @media screen and (max-width: 1300px) {
          font-size: 12px;
        }
      }
    }

    li {
      button {
        .mat-button-wrapper {
          @media screen and (max-width: 1439px) {
            font-size: 12px !important;
          }

          @media screen and (max-width: 1300px) {
            font-size: 12px !important;
          }
        }
      }
    }
  }

  .c-s-input {
    @media screen and (max-width: 1439px) {
      font-size: 12px !important;
    }

    @media screen and (max-width: 1300px) {
      font-size: 12px !important;
    }
  }
}

.top-task-filter-module-ctm {
  .block-title {
    @media screen and (max-width: 1439px) {
      font-size: 15px;
    }

    @media screen and (max-width: 1300px) {
      font-size: 14px;
    }
  }

  ul {
    li {
      .mat-select-value-text {
        @media screen and (max-width: 1439px) {
          font-size: 13px;
        }

        @media screen and (max-width: 1300px) {
          font-size: 12px;
        }
      }

      .mat-form-field-hide-placeholder {
        .mat-select-placeholder {
          @media screen and (max-width: 1439px) {
            font-size: 13px;
          }

          @media screen and (max-width: 1300px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .mat-select-panel {
    .mat-option {
      @media screen and (max-width: 1439px) {
        font-size: 13px;
      }

      @media screen and (max-width: 1300px) {
        font-size: 12px;
      }
    }
  }

  .mat-menu-content {
    ul {
      li {
        .c-s-input {
          &.custom-input {
            @media screen and (max-width: 1439px) {
              font-size: 13px;
            }

            @media screen and (max-width: 1300px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.mat-select-panel-wrap {
  .mat-select-panel {
    &.mat-primary {
      .mat-option {
        @media screen and (max-width: 1439px) {
          line-height: 2.2em;
          height: 2.2em;
        }
      }

      span {
        &.mat-option-text {
          @media screen and (max-width: 1439px) {
            font-size: 13px !important;
          }

          @media screen and (max-width: 1300px) {
            font-size: 12px !important;
          }
        }
      }
    }
  }

  .c-s-input {
    @media screen and (max-width: 1439px) {
      font-size: 13px !important;
    }

    @media screen and (max-width: 1300px) {
      font-size: 12px !important;
    }
  }
}

.multi-select-chip-menu {
  &.status-select-chip-menu {
    .search-status {
      .custom-input {
        @media screen and (max-width: 1439px) {
          font-size: 13px;
        }

        @media screen and (max-width: 1300px) {
          font-size: 12px;
        }
      }
    }
  }
}

.custom-dropdown-menu {
  .mat-menu-item {
    font-weight: 600;
    color: #222;
  }

  &.filter {
    &.ctm-default-dropdown {
      ul {
        li {
          span {
            @media screen and (max-width: 1439px) {
              font-size: 15px;
            }

            @media screen and (max-width: 1300px) {
              font-size: 14px;
            }

            img {
              position: relative;
              top: -2px;
            }
          }
        }
      }
    }
  }
}

.task-list-date-left--block {
  .rt-createTaskFormTaskList-left {
    .create-task-block {
      .mat-card-content {
        .custom-tak-listing-left {
          .custom-tak-listing-left-first {
            .custom-input-field.mat-form-field {
              .mat-form-field-infix {
                margin-bottom: 0;
                padding-bottom: 9px;
              }
            }
          }
        }
      }

      .custom-textarea-form-control {
        resize: vertical;
      }
    }
  }
}

.rt-createTaskFormTaskList-right {
  width: 50%;

  .mat-tab-body.mat-tab-body-active {
    overflow-x: auto !important;
    overflow-y: hidden !important;
  }
}

.add-task-rght-block-tab {
  &.attachment-design {
    .mat-tab-body-wrapper {
      .time-log-tab-forTaskListRightTab {
        table {
          thead {
            background-color: #f9f8f8;

            tr {
              th {
                padding: 7px !important;

                &:nth-last-child(5) {
                  width: 100px;
                }

                &:last-child {
                  @media screen and (max-width: 1450px) {
                    width: 60px;
                  }

                  @media screen and (max-width: 1400px) {
                    width: 40px;
                  }
                }
              }
            }
          }

          tbody {
            tr {
              td {
                padding: 4px 12px !important;
                vertical-align: middle;
                text-align: left;
                border-right: 1px solid #eeeeee;

                &:nth-last-child(5) {
                  width: 100px;
                }

                &:last-child {
                  @media screen and (max-width: 1450px) {
                    width: 60px;
                  }

                  @media screen and (max-width: 1400px) {
                    width: 40px;
                  }
                }

                .table-inner {
                  td {
                    border-bottom: 0px solid #eeeeee !important;
                  }
                }
              }

              &.table-title {
                display: flex;
                background: #fafafa;

                td {
                  width: 100%;
                  padding: 0 !important;
                  min-width: 100%;
                  display: flex;
                  justify-content: center;
                }
              }
            }
          }
        }

        .timelog-for-table {
          .table-croll {
            max-width: 100%;

            &::-webkit-scrollbar-track {
              background-color: #fff !important;
              box-shadow: inset 0 0 5px #fff !important;
              border: 5px solid #fff;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #ccc;
              border: 5px solid #fff;
            }

            .list-view-table {
              border-right: 1px solid #fafafa;
            }

            @media screen and (max-width: 1855px) {
              width: calc(100vh + 168px);
            }
          }

          tbody {
            tr {
              td {
                border-bottom: 1px solid #eeeeee;
                border-top: 1px solid #eee;

                &:first-child {
                  background-color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

.tab-list-kanban-cards-for-close-main-block {
  &.rt-tabListKanbanCardsForCloseMainBlock {
    .mat-tab-label {
      @media screen and (max-width: 1439px) {
        font-size: 14px;
      }

      @media screen and (max-width: 1300px) {
        font-size: 12px;
        min-width: 10em;
      }
    }

    .mat-tab-body-wrapper {
      overflow: unset;
      display: unset;
    }

    .mat-tab-body-content {
      overflow: unset;

      .kanban-view-design-only {
        &.kanban-view-cards-for-only {
          overflow: unset;

          .kanbar-internal {
            @media screen and (max-width: 1700px) {
              max-width: 98%;
            }
          }

          .kanban-internal-cards {
            overflow: auto;
            max-width: 99.5%;

            &::-webkit-scrollbar-track {
              background-color: #fff !important;
              box-shadow: inset 0 0 5px #fff !important;
              border: 5px solid #fff;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #ccc;
              border: 5px solid #fff;
            }

            .add-task-kanban {
              cursor: pointer;
            }
          }
        }
      }
    }

    .create-task-scroll-box {
      width: 100%;
      overflow-y: auto !important;
      overflow-x: hidden;
      scrollbar-width: none;
      -ms-overflow-style: none;

      ::-webkit-scrollbar {
        /* Hide the scrollbar for WebKit browsers (Safari and Chrome) */
        width: 0;
        background: transparent;
      }
    }
  }
}

// For Customizing right sidebar

.mat-drawer {
  &.customize--drawer {
    @media screen and (max-width: 1350px) {
      width: 320px !important;
    }

    .mat-drawer-inner-container {
      .block-title {
        @media screen and (max-width: 1439px) {
          font-size: 15px;
        }

        @media screen and (max-width: 1300px) {
          font-size: 14px;
        }
      }

      .customize-box {
        @media screen and (max-width: 1439px) {
          font-size: 13px;
        }

        @media screen and (max-width: 1300px) {
          font-size: 12px;
        }
      }
    }
  }
}

// Recycle Bin
.math_block-user-profile-tasklist-tab-recycle {
  p {
    @media screen and (max-width: 1439px) {
      font-size: 15px;
    }

    @media screen and (max-width: 1300px) {
      font-size: 14px;
    }

    @media screen and (max-width: 1200px) {
      font-size: 13px;
    }
  }

  .table-responsive {
    &::-webkit-scrollbar-track {
      background-color: #fff !important;
      box-shadow: inset 0 0 5px #fff !important;
      border: 5px solid #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border: 5px solid #fff;
    }
  }
}

// Calendar Type Module
.calendar-type-module {

  .cal-title-ctm,
  span.mat-checkbox-label,
  .mat-radio-label-content,
  .selcted-date-ctm {
    @media screen and (max-width: 1439px) {
      font-size: 15px;
    }

    @media screen and (max-width: 1300px) {
      font-size: 14px;
    }

    @media screen and (max-width: 1200px) {
      font-size: 13px;
    }
  }

  .cal-modal-btns {
    .mat-button-wrapper {
      @media screen and (max-width: 1439px) {
        font-size: 15px;
      }

      @media screen and (max-width: 1300px) {
        font-size: 14px;
      }

      @media screen and (max-width: 1200px) {
        font-size: 13px;
      }
    }
  }
}

// Spacing Set For new one
.rt-dashboard-content {
  .new-spc-padding-right {
    padding-right: 5px !important;
  }

  .dashboard-calendar {
    &.new-spc-dashboard-calendar {
      .custom-calendar .fc-header-toolbar {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        position: relative;
      }
    }
  }

  .team-incompleted-task {
    .team-incompleted-task-inn {
      .user-list {
        .user-list-wrap {
          &:hover {
            background: #f9f8f8;
          }
        }
      }
    }
  }

  &.dashboard-content {
    padding-top: 70px;
    background-color: #e5e5e5;
    padding-left: 15px !important;
    padding-right: 15px !important;
    height: calc(100% - 1px);
    overflow-y: auto;

    .dashboard-over-all-card {
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(33, 33, 33, 0.08);
      border-radius: 8px;
      padding: 25px;

      .dashboard-over-summary-list {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .total-task-title {
          display: flex;
          align-items: center;

          .dashboard-bg-image {
            width: 57px;
            height: 57px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      &.new-spc-dashboard-padding {
        padding: 15px 25px;
        border: 1px solid transparent;
        transition: all 0.3s ease-in-out;

        &:hover {
          box-shadow: 0px 1px 8px 1px #00366459;
          border: 1px solid #00366440;
          transition: all 0.3s ease-in-out;
        }
      }

      &.new-spc-dashboard-padding-two {
        padding: 7px 15px !important;
      }
    }
  }

  .mat-tabe-content {
    .mat-tab-body-wrapper {
      .mat-tab-body {
        .mat-tab-body-content {
          .user-chat-box-cover {
            .user-chat-box {
              &:hover {
                background: #f3f3f3;
              }
            }
          }
        }
      }
    }
  }

  app-recent-projects {
    &.dashboard-over-all-card {
      &.dashboard-recent-project {
        .card {
          &.mat-card {
            &.box-card {
              border: 1px solid transparent;
              transition: all 0.3s ease-in-out;

              &:hover {
                box-shadow: 0px 0px 8px 1px #00366459 !important;
                border: 1px solid #00366440;
                transition: all 0.3s ease-in-out;
              }
            }
          }
        }
      }
    }
  }

  .math_ui_inside-dash-activity-notes {
    .math_ui_inside-dashboard-activity {
      .filter-notification-page-ctm {
        .scrollWindow {
          .mat-card {
            padding: 10px 0;
            margin-bottom: 0;

            &:hover {
              background: #f9f8f8;
            }
          }
        }
      }
    }
  }

  .slick-next:hover:before,
  .slick-next:focus:before {
    border: 1px solid #ccc;
    opacity: 1;
  }

  .dashboard-recent-project {
    .slick-prev:hover::before {
      border: 1px solid #ccc !important;
    }

    .slick-next:hover::before {
      border: 1px solid #ccc !important;
    }
  }

  .task--content-table .mat-cell {
    padding: 7px 10px;
  }
}

// For Hover
.hover-only {
  border-bottom: 2px solid transparent;

  &:hover {
    &.blue-link {
      border-bottom: 2px solid #276ef1 !important;
    }
  }
}

// Task List

.main-content-coantainer {
  &.rt-task-content {
    .rt-task-filter {
      ul {
        &.top-action-list {
          margin-bottom: 0;
        }
      }
    }
  }
}

// Task List Top Filter
.rt-top-task-filter-module-ctm {
  .mat-menu-content {
    padding: 7px 15px !important;

    .mat-form-field-infix {
      border-top: 5px solid transparent;
    }
  }
}

// Document
.rt-document {
  .main-sidebar {
    &.mat-drawer {
      &.dms-sidebar {
        .mat-drawer-inner-container {
          background-color: #fff;
          box-shadow: 12px -1px 28px 12px #00152800;
          border-right: 1px solid #0015280a;
          // padding-top: 1em;

          .sidebar-menu-section {
            &>li {
              a {
                label {
                  color: #616161;
                  font-size: 16px;
                }

                &:hover {
                  background-color: unset;
                }
              }
            }
          }
        }
      }
    }
  }

  .main-sidebar.mat-drawer.dms-sidebar .mat-drawer-inner-container .sidebar-menu-section>li a.active-link {
    background: #edf3fd;
    margin: 0 auto;
    max-width: 97%;
    border-radius: 10px;
    padding-left: 22px;

    label {
      color: #276ef1;
      font-size: 16px;
      font-weight: 600;
    }

    img {
      filter: invert(4%) sepia(90%) saturate(3305%) hue-rotate(192deg) brightness(86%) contrast(101%);
    }
  }

  .progress-block .mat-progress-bar .mat-progress-bar-fill:after {
    background-color: #001528;
  }

  .main-sidebar.mat-drawer.dms-sidebar .mat-drawer-inner-container .sidebar-menu-section>li a:hover label {
    color: #616161;
  }

  .main-sidebar.mat-drawer .sidebar-menu-section>li .nav-link>img {
    filter: unset;
  }

  .list-view-table tbody tr td {
    padding-bottom: 3px;
    padding-top: 3px;
    line-height: 38px !important;
  }

  .default-folder {
    font-weight: 500;
  }
}

// Discussion

.rt-block-discussion {
  .mat-tabe-content .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content .user-chat-box-cover .user-chat-box .user-name p {
    padding-top: 0 !important;
  }

  .dms-header-box {
    padding: 2px 20px !important;
  }

  .mat-tabe-content {
    .mat-tab-body-wrapper {
      .mat-tab-body {
        .mat-tab-body-content {
          height: calc(100vh - 200px);
        }
      }
    }
  }
}

// Timesheet styles

// Time Log Modal
.rt-time-log-detail-modal {
  &.custom-modal {
    .mat-dialog-container {
      @media screen and (max-height: 800px) {
        padding: 15px;
      }

      .mat-dialog-content {
        max-height: 100%;
      }
    }
  }
}

.accordian-wospc {
  padding: 0;
}

.input-custom-general-setting {
  width: 300px !important;
}

.mub-general-settings {
  .card-custom {
    box-shadow: none !important;
    border: 1px solid #eeeeee;
    margin: 12px;
    margin-bottom: 0;
    border-radius: 12px;
    transition: 0.2s ease;
    background: #fff;
    max-width: 97%;
    padding: 0 0 12px 0;
  }

  .mat-card-nones {
    border: 0px solid transparent !important;

    .mat-card-header {
      padding-top: 10px;
    }
  }
}

.custom-style-subscription-form {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.rt-subscription-management {
  .table.custom-table.c-d-table td {
    text-align: left !important;

    &:last-child {
      text-align: right !important;
    }

    @media screen and (max-width: 1680px) {
      padding: 5px 16px;
    }

    &.mat-column-amount {
      p {
        line-height: 40px;
      }
    }
  }
}

.rt-email-list-config {
  &.mub-email-list-config {
    padding-top: 0;
  }

  .config-top-bar {
    padding: 10px 20px;
  }

  .config-table {
    .table-responsive {
      max-height: calc(100vh - 285px);
      overflow: auto;
      margin-top: 185px;

      &::-webkit-scrollbar-track {
        background-color: #fff !important;
        box-shadow: inset 0 0 5px #fff !important;
        border: 5px solid #fff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border: 6px solid #fff;
      }
    }
  }
}

.rt-email-management-list-scroll {
  overflow: hidden;
}

// FOr Reposinsive 1580 to 1024

@media screen and (min-width: 1024px) and (max-width: 1580px) {

  // Organization, subscription, Email Management, Logout, Settings
  // For 24px => 22px:
  .component-heading,
  .company-card .bottom-info-list h6,
  .page-title,
  .mub_email_company_listing .email_heading,
  .sign-in,
  .slide-class h2,
  .mub-time-log-common-header .company-details-title h2 {
    font-size: 22px !important;
  }

  // For 22px => 20px
  .modal-block-title {
    font-size: 20px;
  }

  // For 20px => 18px:
  .modal-title {
    font-size: 18px;
  }

  // For 18px => 16px:
  .c-details h4,
  .block-title,
  .avatar--lg,
  .mat-card-header-text .card-title-list {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  .mub-email-list-config {
    .config-top-bar {
      .config-topbar-card {
        .sync-up-mail {
          font-size: 16px !important;
        }
      }
    }
  }

  .math_ui_block-project-overview-list .right-side-card-view .over-chart-title,
  .pro-details-edit .detail-title,
  .no-items-block h4,
  .tab-list-thrice-block .tab-list-thrice .tab-list-kanban-internal .kanban-internal-cards .kanban-container h6.process-title,
  .custom-calendar .fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title,
  .rt-timesheet-manually-cls ul li.log-title,
  .card-containner .report-name {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  // For 16px:
  .no-items-block h4,
  .multi-select-chip-menu ul li,
  .mub-reports.rt-mub-reports .example-card p {
    font-size: 16px;
  }

  // For 16px: !important
  .company-details-only .no-items-block h4 {
    font-size: 16px !important;
  }

  // For 14px:
  .btn.btn.mat-button,
  .custom-tab.mat-tab-group.mat-primary .mat-tab-label,
  .c-details p,
  .custom-dropdown-menu .mat-menu-item,
  .company-avatar,
  .sub-block-title,
  .table.custom-table tbody td,
  .badge,
  .card.mat-card.inner-detail-card .mat-card-header .block-title,
  .card.mat-card.p-d-card .mat-card-content .u-n,
  .form-control,
  .u-w-i-d h4,
  .mat-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-flat-button,
  .custom-input-field.mat-form-field input,
  .mat-option,
  .login-desc,
  .slide-class p,
  .mat-date-range-input-start-wrapper,
  .mat-date-range-input-end-wrapper,
  .timesheet-report tfoot th {
    font-size: 14px;
  }

  // 14px !importtant seperately
  .company-details-only .card.mat-card.inner-detail-card .mat-card-header .block-title,
  .mub-bulk-export-client form h5,
  .login-desc,
  .slide-class p,
  .ctm-select-all.select-all .mat-option {
    font-size: 14px !important;
  }

  // For 12px:
  .company-card .bottom-info-list p,
  .anchor-link,
  .table.custom-table thead tr th,
  .custom-modal .inner-label,
  .no-items-block p,
  .user-short-name {
    font-size: 12px;
  }

  .rt-tabListKanbanCardsForCloseMainBlock {
    .rt-custom-right-side-chart {
      .due-background {
        padding: 0 !important;
        font-size: 12px !important;
      }

      .custom-input-field.mat-form-field input {
        font-size: 14px !important;
      }
    }
  }

  // For 13px
  .footer-line p {
    font-size: 13px;
  }

  .no-item-icon {
    margin-bottom: 0 !important;
  }

  .document-sidebar {
    &.main-sidebar.mat-drawer .sidebar-menu-section>li .nav-link label {
      font-size: 13px;
    }
  }

  .rt-document-content-block .file-folder-card h4 {
    font-size: 13px;
  }
}

@media screen and (max-width: 1280px) {

  // For 12px:
  .badge {
    font-size: 12px !important;
  }
}

.rt-demo-app {
  .rt-full-calendar {
    button {
      &:focus {
        box-shadow: none !important;
      }
    }
  }
}

.timesheet-report {
  .mat-tab-list {
    .mat-tab-label {
      min-width: fit-content;
      margin-right: 24px;
    }

    .mat-tab-label-active {
      min-width: unset !important;
    }
  }
}

// Responsive styles
// For Organization

@media screen and (max-width: 1440px) {
  .spc-manage-organization .btn.blue-gredient-btn.mat-button.btn {
    padding: 12px 11px;
    line-height: 14px;
  }

  .spc-manage-organization {
    .row {
      &.mb-3 {
        margin-bottom: 0 !important;
      }
    }

    .card {
      &.mat-card {
        &.company-card {
          padding: 16px;

          .divider {
            margin: 12px 0 12px;
          }
        }
      }
    }
  }

  .spc-company-details-only {
    .company-icons {
      min-width: 70px !important;
      height: 70px !important;
    }

    .sub-block-title {
      margin-bottom: 0 !important;
    }

    .card {
      &.mat-card {
        .mat-card-header {
          padding: 15px;
        }

        &.box-card {
          border-radius: 12px;
          padding: 14px;
        }

        &.p-d-card {
          .mat-card-content {
            .u-p {
              margin-bottom: 8px !important;
            }
          }
        }
      }
    }

    .avatar--lg {
      height: 70px;
      width: 70px;
    }

    .mat-tab-label {
      height: 38px;
    }

    .clientonly-height {
      .mat-card {
        min-height: 195px !important;
      }
    }

    .company-info-block-card {
      overflow: auto;
      max-height: 270px;

      &::-webkit-scrollbar-track {
        background-color: #fff !important;
        box-shadow: inset 0 0 5px #fff !important;
        border: 5px solid #fff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border: 6px solid #fff;
      }
    }
  }

  .mub-general-settings {
    &.spc-general-settings {
      .card {
        &.mat-card {
          &.box-card {
            padding: 16px;
          }

          .mat-card-header {
            padding: 0 17px 14px;
          }
        }
      }

      mat-checkbox {
        &.px-3 {
          padding-left: 0 !important;
        }
      }

      .table {
        &.custom-table {
          &.header-bg {
            th {
              &.user-name {
                padding-left: 0;
              }
            }
          }

          tbody {
            td {
              &.custom-spc-padding {
                padding-top: 12px;
                padding-bottom: 12px;
              }
            }
          }
        }
      }

      .user-short-name {
        border: 0px solid transparent;
      }

      .mat-card-actions {
        padding: 0 !important;
      }
    }
  }
}

.add-checklist-permission-modal-table {
  .table-max-height {
    max-height: 320px;
    overflow: auto;

    .checklist-permission-table td {
      padding: 8px 0;
    }
  }
}

.create-client-custom-field {
  max-height: 320px;
  overflow: auto;

  &::-webkit-scrollbar-track {
    background-color: #eee;
    border: 5px solid #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eee;
  }
}

.mat-wrapper {
  .mat-form-field-wrapper {
    padding-top: 18px;
    padding-bottom: 6px;
  }
}

.org-setting__page {
  .u-w-i-d h4 {
    margin-top: 6px;
    margin-bottom: 0;
  }
}

.table-para {
  position: relative;

  .custom-stt {
    p {
      position: absolute;
      left: 22px;
      font-size: 14px;
      z-index: 123;
      color: black;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 625px !important;
      text-align: left !important;
      min-height: 25px;

      &.time-log-stt {
        position: absolute;
        left: 3px;
        width: 834px !important;
        min-height: 30px;
        min-width: 834px !important;
        margin-top: 4px;
        padding-bottom: 10px;
        padding-left: 13px;
      }
    }
  }
}

.custom-stt1.time-log-stt {
  padding: 7px 14px;
}

.custom-stt1 p {
  font-size: 14px;
}

.rt-submit-apporoval-body {
  .table-rt {
    tbody {
      tr {
        td {
          vertical-align: sub !important;

          h4 {
            margin-top: 6px;
          }
        }
      }
    }
  }
}

.rt-timesheet-month-table {
  width: calc(100vw - 15vw);
  overflow-x: auto;
  margin: 0 auto;

  max-height: calc(100vh - 29vh);

  &::-webkit-scrollbar-track {
    background-color: #fff !important;
    box-shadow: inset 0 0 5px #fff !important;
    border: 5px solid #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border: 5px solid #fff;
  }

  @media screen and (min-width: 2150px) {
    width: 100%;
  }

  @media screen and (min-height: 870px) {
    max-height: calc(100vh - 27vh);
  }

  @media screen and (max-width: 1800px) {
    width: calc(100vw - 17vw);
  }

  @media screen and (max-width: 1300px) {
    width: calc(100vw - 20vw);
  }

  @media screen and (max-height: 900px) {
    max-height: calc(100vh - 29vh);
  }

  @media screen and (max-height: 850px) {
    max-height: calc(100vh - 32vh);
  }

  @media screen and (max-height: 780px) {
    max-height: calc(100vh - 33vh);
  }

  @media screen and (max-height: 750px) {
    max-height: calc(100vh - 36vh);
  }

  table {
    thead {
      tr {
        th {
          min-width: 150px;

          &:last-child {
            min-width: 150px;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #ddd;

        &:hover {
          background-color: #fafafa;
        }

        td {
          &::before {
            content: none;
          }
        }
      }
    }
  }
}

.rt-pending-timesheet-manually-cls {
  max-width: 100%;

  @media screen and (max-width: 1460px) {
    max-width: 98%;
  }

  @media screen and (max-width: 1180px) {
    max-width: 97%;
  }
}

.pending-expansion-panel {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1525px) {
    max-width: 98%;
  }

  @media screen and (max-width: 1350px) {
    max-width: 96%;
  }
}

// Manage Members
.view-member-block {
  .custom-table {
    &.header-bg {
      th {
        &.mat-column-Permission {
          min-width: 350px;
        }
      }
    }
  }
}

.rt-add-new-member-modal {
  .modal-body-middle {
    max-height: 662px;
    overflow-x: hidden !important;
    overflow: auto;

    &::-webkit-scrollbar-track {
      background-color: #eee;
      border: 5px solid #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #eee;
    }

    .rt-add-member-date {
      .mat-icon-button {
        top: 0 !important;
        left: 0 !important;
      }
    }

    .row {
      max-width: 100%;
      margin: 0;
    }

    @media screen and (max-height: 900px) {
      max-height: 622px;
    }

    @media screen and (max-height: 864px) {
      max-height: 562px;
    }

    @media screen and (max-height: 800px) {
      max-height: 522px;
    }

    @media screen and (max-height: 768px) {
      max-height: 462px;
    }
  }
}

.rt-add-service {
  .another-expansion {
    .mat-expansion-panel-content {
      max-height: 250px;
      overflow: auto;

      &::-webkit-scrollbar-track {
        background-color: #eee;
        border: 5px solid #fff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #eee;
      }
    }
  }
}

// Add Notes
.rt-add-notes {
  padding: 10px 12px !important;
}

.rt-notes-section {
  .notes-edit-icons {
    .pin-icon {
      background-color: #010f1a96;
    }
  }

  .custom-grid {
    .mat-card {
      height: 270px !important;
    }

    .notes-fixed-scroll {
      height: 220px !important;
    }
  }
}

.shareNoteForm {
  &.rt-shareNoteForm {
    .shareNoteForm-title {
      padding: 10px 60px 10px 20px;
    }

    .padding-btm {
      padding-bottom: 10px !important;

      .mr-custom {
        margin-right: 10px !important;
      }
    }

    .row.mb-4 {
      margin-bottom: 0px !important;
    }

    table {
      th {
        &.username-head {
          width: 30%;
        }
      }

      td {
        &.username-cell {
          width: 30%;
        }
      }
    }

    .cross-icon {
      position: absolute;
      top: 8px;
      right: 0;
      width: 62px;

      img {
        width: 25px;
        height: 25px;
      }
    }

    .custom-input {
      &.custom-input-design {
        border: 1px solid #aaa;
        background-color: #fafafa;
        border-radius: 8px;
      }
    }
  }
}

// Add Note Modal
.main-custom-notes-module-width {
  min-width: 900px;

  &.notes-modal-md {
    app-add-notes {
      .ql-container {
        &.ql-snow {
          .ql-editor {
            height: 330px;
            max-height: 330px !important;
          }
        }
      }
    }
  }
}

.custom-input {
  &.custom-input-design {
    border: 1px solid #aaa !important;
    background-color: #fafafa;
    border-radius: 8px;
  }

  &.custom-w-one {
    max-width: 614px;
  }

  &.custom-w-two {
    max-width: 724px;
  }

  &.h-40 {
    min-height: 40px !important;
  }
}

.customize-icon {
  img {
    width: 20px !important;
    height: 20px !important;
  }

  font-size: 14px !important;
}

// Space Ornganization For Desktop
.spc-manage-organization {
  .main-content-coantainer {
    padding: 70px 20px;

    .custom-spacing {
      margin-bottom: 0px !important;
    }
  }

  .custom-tab.mat-tab-group.mat-primary .mat-tab-label {
    font-size: 18px;
    color: #222;
  }

  .company-card {
    transition: all 0.3s ease-in-out;

    &.hover.box-shadow {
      &:hover {
        box-shadow: 0 0 8px 1px #00366459 !important;
        border: 1px solid rgba(0, 54, 100, 0.2509803922) !important;
        transition: all 0.3s ease-in-out;
        border-radius: 8px;
      }
    }

    .bottom-info-list {
      p {
        font-size: 14px;
        color: #444;
      }

      h6 {
        font-size: 26px;
      }
    }
  }

  .c-details p {
    color: #444;
  }
}

// Add Company Modal
.add-company-modal {
  .add-company-only {
    .custom-input-field.mat-form-field input {
      font-size: 16px;
      color: #222;
      font-weight: 700;
    }

    textarea {
      font-weight: 700;
      color: #222;
      text-transform: capitalize;
      padding-bottom: 6px;
    }

    .custom-input-field.mat-form-field {
      .mat-form-field-infix {
        padding: 0.5em 0;
        border-top: 0px;
        margin-bottom: 0 !important;
      }

      &.for-only-textarea {
        .mat-form-field-infix {
          padding: 1.5em 0;
        }
      }
    }

    .for-only-textarea {
      &.custom-input-field.mat-form-field {
        padding-bottom: 20px;
      }
    }
  }
}

//  Space Compnay Details only For Desktop
.spc-company-details-only {
  &.company-details-only {
    .main-content-coantainer {
      padding: 74px 15px;
    }

    .circle-cog img {
      height: 24px;
      width: 26px;
    }

    .company-icons {
      height: 50px;
      width: 55px;
      min-width: 55px;
    }

    .company-info-top-block {
      .sub-block-title {
        line-height: 1.2;
        letter-spacing: 0.005em;
        margin-bottom: 8px !important;
      }
    }
  }

  .card.mat-card .mat-card-header {
    padding: 14px;
  }

  .block-title {
    font-size: 20px;
  }

  .main-redirect {
    text-decoration: none;
  }

  .anchor-link {
    font-size: 16px;
  }

  .table.custom-table thead tr th {
    font-size: 14px;
    color: #222;
  }

  .card.mat-card.inner-detail-card .mat-card-header .block-title {
    color: #222;
    font-weight: 700;
  }

  .mat-tab-label {
    height: 30px;
  }

  .card.mat-card.p-d-card .mat-card-content .u-n {
    color: #222;
  }

  .custom-tab.mat-tab-group.mat-primary .mat-tab-label {
    font-size: 16px;
  }

  .company-info-block-card {
    &.manage-stattus {
      table {
        tbody {
          tr {
            &:hover {
              background-color: #fafafa;
            }
          }
        }
      }
    }
  }

  .company-info-block {
    &.manage-status {
      .card.mat-card.box-card {
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        box-shadow: 0 0 8px 1px #00366459 !important;
        border: 1px solid rgba(0, 54, 100, 0.2509803922);
        transition: all 0.3s ease-in-out;
      }
    }

    &.manage-roles {
      .card.mat-card.box-card {
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        box-shadow: 0 0 8px 1px #00366459 !important;
        border: 1px solid rgba(0, 54, 100, 0.2509803922);
        transition: all 0.3s ease-in-out;
      }
    }

    &.manage-memberrs {
      .card.mat-card.box-card {
        width: 220px !important;
        padding: 14px;
        cursor: pointer;

        .avatar--lg {
          height: 60px;
          width: 60px;
          font-weight: 700;
          font-size: 18px;
          line-height: 28px;
          letter-spacing: 0.0025em;
          color: #fff;
          margin-top: 17px !important;
          transition: all 0.3s ease-in-out;
        }

        &:hover {
          box-shadow: 0 0 8px 1px #00366459 !important;
          border: 1px solid rgba(0, 54, 100, 0.2509803922);
          transition: all 0.3s ease-in-out;
        }

        @media screen and (max-width: 1800px) {
          margin-bottom: 15px;
        }
      }
    }

    &.manage-clientts {
      .card.mat-card.box-card {
        width: 220px !important;
        padding: 14px;

        cursor: pointer;

        .avatar--lg {
          height: 60px;
          width: 60px;
          font-weight: 700;
          font-size: 18px;
          line-height: 28px;
          letter-spacing: 0.0025em;
          color: #fff;
          margin-top: 17px !important;
          transition: all 0.3s ease-in-out;
        }

        &:hover {
          box-shadow: 0 0 8px 1px #00366459 !important;
          border: 1px solid rgba(0, 54, 100, 0.2509803922);
          transition: all 0.3s ease-in-out;
        }

        @media screen and (max-width: 1800px) {
          margin-bottom: 15px;
        }
      }
    }

    &.manage-serviices {
      transition: all 0.3s ease-in-out;

      &:hover {
        box-shadow: 0 0 8px 1px #00366459 !important;
        border: 1px solid rgba(0, 54, 100, 0.2509803922);
        transition: all 0.3s ease-in-out;
      }

      .s-list>li .sub-block-title {
        font-weight: 700;
        color: #222;
      }
    }
  }
}

.app-company-details .spc-company-details-only .company-info-block.company-info-top-block {
  background-color: #f5f5f5;
  padding: 6px 6px 6px 18px !important;
}

// View Status Only
.view-status-only {
  .custom-input {
    &.custom-input-design {
      border: 1px solid #aaa;
      background-color: #fafafa;
      border-radius: 8px;
    }
  }

  .table.custom-table {
    thead {
      tr {
        border-top: 1px solid rgba(0, 0, 0, 0.1215686275);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);

        th {
          font-size: 14px;
          color: #222;
          font-weight: 700;
        }
      }
    }

    tbody {
      tr {
        &:hover {
          background-color: #fafafa;
        }
      }
    }
  }
}

.role-management-only {
  padding-bottom: 0 !important;

  .custom-table-role-management {
    .table.custom-table.header-bg>thead>tr {
      border-top: 1px solid rgba(0, 0, 0, 0.1215686275);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);
    }

    .table.custom-table.header-bg th {
      padding-bottom: 8px;
      font-size: 14px;
      color: #222;
      vertical-align: middle;

      &.mat-column-name,
      &.mat-column-status,
      &.mat-column-Action {
        font-size: 14px;
        font-weight: 700;
      }

      &.mat-column-members {
        font-size: 16px;
        text-align: center;
      }

      &.mat-column-project {
        table {
          thead {
            tr {
              th {
                padding-bottom: 7px !important;
              }
            }
          }
        }
      }

      &.mat-column-discussion {
        table {
          thead {
            tr {
              th {
                padding-bottom: 7px !important;
              }
            }
          }
        }
      }

      &.mat-column-tasks {
        table {
          thead {
            tr {
              th {
                padding-bottom: 7px !important;
              }
            }
          }
        }
      }

      &.mat-column-DMS {
        table {
          thead {
            tr {
              th {
                padding-bottom: 7px !important;
              }
            }
          }
        }
      }

      &.mat-column-Action {
        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    .table.custom-table tbody tr {
      td {
        padding: 8px;

        .u-w-i-d h4 {
          font-weight: 600;
        }

        &.mat-column-members {
          text-transform: capitalize;
        }
      }
    }
  }

  .custom-input {
    &.custom-input-design {
      border: 1px solid #aaa;
      background-color: #fafafa;
      border-radius: 8px;
    }
  }

  .mat-paginator-range-label {
    color: #444;
  }

  .mat-paginator-page-size-label {
    color: #444;
  }
}

// Add Role Modal
.add-role-modal {

  th,
  td {
    color: #222 !important;
  }

  .custom-checkbox .mat-checkbox-frame {
    border-color: #666;
  }

  .custom-input-field.mat-form-field input {
    color: #222;
    font-weight: 700;
    height: 22px;
  }
}

// Manage Members
.rt-view-member-block {
  .mat-tab-label {
    .mat-tab-label-content {
      font-size: 16px;

      font-weight: 700;
    }

    .mat-tab-label-active .mat-tab-label-content {
      color: #222;
    }
  }

  .view-member-tabs {
    ul {
      li:nth-child(3) {
        .mat-button-wrapper {
          font-size: 16px;
          color: #444;
        }
      }
    }
  }

  .custom-input {
    border: 1px solid #aaa !important;
    background-color: #fafafa;
    border-radius: 8px;
  }

  table {
    thead {
      tr {
        border-top: 1px solid rgba(0, 0, 0, 0.1215686275);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);

        th {
          &.mat-header-cell {
            font-size: 14px;
            color: #222;
            font-weight: 700;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &.mat-column-Name {
            h4 {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

// Staff Member Only Inside
.rt-view-status-only .table.custom-table.c-d-table td,
.rt-staff-member-onlyinside-table .table.custom-table.c-d-table td,
.rt-co-member-onlyinside-table .table.custom-table.c-d-table td,
.rt-pending-member-onlyinside-table .table.custom-table.c-d-table td,
.rt-teamgroup-member-onlyinside-table .table.custom-table.c-d-table td {
  padding: 5px 16px;
  border-right: 1px solid #eee;

  &:last-child {
    border-right: 0px solid #eee;
  }
}

.rt-view-status-only {
  padding-bottom: 0 !important;

  .table.custom-table.c-d-table {
    th {
      border-right: 1px solid #eee;

      &:last-child {
        border-right: 0px solid #eee;
      }

      &.mat-column-Status {
        text-align: center !important;
      }
    }

    td {
      &.mat-column-Status {
        text-align: center !important;
      }
    }
  }
}

.rt-staff-member-onlyinside-table .table.custom-table.c-d-table {
  th {
    border-right: 1px solid #eee;

    &:last-child {
      border-right: 0px solid #eee;
    }
  }
}

.rt-co-member-onlyinside-table {
  .co-member-onlyinside-table {
    &::-webkit-scrollbar-track {
      background-color: #fff !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fafafa !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 31px solid #fafafa;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      z-index: 0;
      border-radius: 14px;
      overflow: auto;
      border: 6px solid #fff;
    }
  }

  .table.custom-table.c-d-table {
    th {
      border-right: 1px solid #eee;

      &:last-child {
        border-right: 0px solid #eee;
      }
    }
  }
}

.rt-pending-member-onlyinside-table {
  &::-webkit-scrollbar-track {
    background-color: #fff !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fafafa !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
    border: 31px solid #fafafa;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    z-index: 0;
    border-radius: 14px;
    overflow: auto;
    border: 6px solid #fff;
  }

  .table.custom-table.c-d-table {
    th {
      border-right: 1px solid #eee;

      &:last-child {
        border-right: 0px solid #eee;
      }
    }
  }
}

.rt-teamgroup-member-onlyinside-table {
  &::-webkit-scrollbar-track {
    background-color: #fff !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fafafa !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
    border: 31px solid #fafafa;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    z-index: 0;
    border-radius: 14px;
    overflow: auto;
    border: 6px solid #fff;
  }

  .table.custom-table.c-d-table {
    th {
      border-right: 1px solid #eee;

      &:last-child {
        border-right: 0px solid #eee;
      }
    }

    .u-w-i-d h4 {
      font-weight: 600;
      margin-bottom: 0;
    }
  }
}

// Add New Member Modal
.custom-modal-addMember {
  &.custom-modal-md {
    max-width: 742px !important;
  }

  .rt-add-new-member-modal {
    .custom-input-field.mat-form-field input {
      color: #222;
      font-size: 16px;
      font-weight: 600;
    }

    .custom-input-field.mat-form-field .mat-form-field-label {
      color: #444;
      font-size: 16px;
    }

    .mat-radio-label,
    .mat-checkbox-layout {
      border: 1px solid #aaa;
      background-color: #fafafa !important;
      border-radius: 8px !important;
    }

    .modal-body-middle {
      max-width: 100%;
      width: 100%;
      max-height: 710px;

      @media screen and (max-width: 1800px) {
        padding-right: 20px;
        max-height: calc(100vh - 250px);
      }
    }
  }

  &.custom-modal .mat-dialog-container {
    padding: 25px;

    @media screen and (max-width: 1800px) {
      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        z-index: 0;
        border-radius: 10px;
        overflow: auto;
      }

      ::-webkit-scrollbar {
        width: 5px;
      }
    }
  }

  .custom-input-field.mat-form-field .mat-form-field-infix {
    margin-bottom: 2px;
  }
}

.rt-staff-member-onlyinside-table {
  .action-icon {
    width: 26px;
  }
}

// Add Team Group Modal
.custom-edit-teamGroup-2 {
  .custom-input-field.mat-form-field input {
    font-weight: 600;
    font-size: 18px;
    color: #222;
  }

  .mat-chip-list-wrapper {
    .mat-chip {
      .user-name {
        font-size: 14px;
        color: #222;
      }
    }
  }
}

.team-group-onlyinside {
  .vertical-tab-group.mat-tab-group .mat-tab-label-container {
    max-height: calc(100vh - 250px);
    overflow: auto;
  }
}

// Team Group Member Only Inside
.rt-teamgroup-member-onlyinside-table {
  .vertical-tab-group.mat-tab-group {
    .mat-tab-header .mat-tab-labels .mat-tab-label {
      span {
        padding: 7px;
      }

      &.mat-tab-label-active {
        background: #edf3fd;
      }
    }
  }

  .teamgroup-list-title {
    border: 1px solid rgba(0, 0, 0, 0.1215686275);
    background: #f9f8f8;
    padding: 4px 20px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #444;
    line-height: 16px;
    margin-top: 8px;
    text-transform: capitalize;
    margin-bottom: 0;
  }
}

// Manage Clients
.rt-clients-overall-scroll-cls {
  ul {
    li {
      &:nth-child(2) {
        .mat-button-wrapper {
          font-size: 16px;
          color: #444;
        }
      }

      &:nth-child(4) {
        .mat-button-wrapper {
          font-size: 16px;
          color: #444;
        }
      }
    }
  }

  .table.custom-table thead tr {
    border-top: 1px solid rgba(0, 0, 0, 0.1215686275);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);

    th {
      font-size: 14px;
      color: #222;
      font-weight: 700;
      border-right: 1px solid #eee;
      min-width: 200px;

      &.mat-column-Services {
        min-width: 250px;
      }

      &.mat-column-Action {
        min-width: 100px;
      }

      &:last-child {
        border-right: 0px solid;
      }

      &.mat-column-staticClientName {
        min-width: 300px;
      }
    }
  }

  .table.custom-table tbody td {
    font-weight: 600;
    padding: 1px 16px;
    border-right: 1px solid #eee;
    min-width: 200px;

    &.mat-column-Services {
      min-width: 250px;
    }

    &.mat-column-Action {
      min-width: 100px;
    }

    &:last-child {
      border-right: 0px solid;
    }

    &.mat-column-staticClientName {
      padding: 5px 16px;
      min-width: 300px;

      .u-w-i-d {
        p {
          text-transform: capitalize;
        }
      }
    }
  }

  .u-w-i-d h4 {
    font-weight: 600;
  }

  .inner-detail-table {
    thead {
      border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    }

    th {
      min-width: 200px;
      padding: 13px;
      border-right: 1px solid #eee;
      font-weight: 700;
      color: #222;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      &:last-child {
        border-right: 0px solid;
      }
    }

    td {
      min-width: 200px;
      padding: 6px 14px !important;
      border-right: 1px solid #eee;

      &:last-child {
        border-right: 0px solid;
      }
    }

    a {
      cursor: pointer;
      text-decoration: none;
    }
  }

  .column-structure-scroll-cls {
    max-height: calc(100vh - 185px);

    @media screen and (max-width: 1800px) {
      max-height: calc(100vh - 178px);
    }
  }

  &.c-d-container {
    @media screen and (max-width: 2000px) {
      padding: 60px 20px 0 20px;
    }
  }
}

// Add Client
.rt-mub-add-client {
  .rt-add-services {
    .rt-add-services-content {
      ul {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 520px;

        li {
          border-bottom: 0px;
          padding: 0 10px;

          &:last-child {
            border-bottom: 1px solid #eee;
          }
        }
      }

      .mat-form-field-wrapper {
        padding-bottom: 0;
      }

      .custom-checkbox {
        font-weight: 700;
        color: #222;
        margin-top: 3px;
      }
    }

    .mat-expansion-panel-body {
      height: 500px;
    }

    .custom-coled {
      min-height: 51px;
      padding: 5px 10px;

      .mat-select-placeholder {
        color: #444;
      }

      &.coled1 {
        border-right: 1px solid #eee;
      }

      .mat-select-value {
        vertical-align: middle;
        line-height: 33px;
      }
    }

    .custom-input-field.mat-form-field .mat-form-field-underline {
      display: none;
    }

    .custom-input-field.mat-form-field .mat-form-field-infix {
      margin-bottom: 0;
      padding: 0;
      border-top: 0;
    }
  }

  .mat-expansion-panel-header {
    height: 48px !important;
  }

  .custom-input-field.mat-form-field input,
  input {
    font-weight: 700;
    color: #222;
  }

  overflow: hidden;

  .brdr {
    border-bottom: 3px solid #ccc;
    padding-bottom: 10px;
  }
}

.column-structure-scroll-cls {
  .mat-header-cell {
    text-transform: capitalize;
  }
}

.mat-drawer.org-setting-sidebar.rt-org-setting-sidebar {
  .nav-item-list>li>a {
    font-weight: 600;
    font-size: 16px;
    max-width: 97%;
    margin: 0 auto;

    &.active {
      background: #edf3fd;
      margin: 0 auto;
      border-radius: 10px;
      font-weight: 600;
      color: #276ef1;
    }
  }

  top: 126px;
  padding-top: 0px;
}

.rt-spc-company-details-only {
  .logo-image {
    width: 30px;
    height: 30px;
  }

  .manage-status {
    .table.custom-table tbody td {
      padding: 6px 16px;
    }
  }
}

// Checklist Settings
.container-custom-width {
  max-width: 90%;
  margin: 0 auto;
}

// Checklist Template Settings
.rt-checklist-template {
  padding-top: 14px;

  .company-info-block {
    &.top {
      border: 1px solid #aaa !important;
      background-color: #fafafa;
      border-radius: 8px;
    }
  }

  .card.mat-card.box-card {
    padding: 10px;
    overflow-x: hidden;
  }

  .custom-w {
    width: 280px;

    @media screen and (max-width: 1700px) {
      width: fit-content;
    }

    @media screen and (max-width: 1450px) {
      width: 150px;
    }

    @media screen and (max-width: 1399px) {
      width: fit-content;
    }

    @media screen and (max-width: 1200px) {
      width: 150px;
    }
  }

  .cards-set {
    max-height: 355px;
    height: 355px;
    overflow: auto;
    transition: all 0.3s ease-in-out;

    &:hover {
      box-shadow: 0 0 8px 1px #00366459 !important;
      border: 1px solid rgba(0, 54, 100, 0.2509803922);
      transition: all 0.3s ease-in-out;
    }
  }

  .block-title-16 {
    text-transform: capitalize;
  }

  .checklist-lable-name {
    color: #222;
  }

  .checklist-lable-name {
    text-transform: capitalize;
  }

  .cards-set.mat-card {
    .mat-button {
      @media screen and (max-width: 1900px) {
        line-height: 25px;
      }

      @media screen and (max-width: 1450px) {
        line-height: 18px;
      }
    }
  }
}

// Add Template Modal
.add-checklist-custom-modal,
.edit-checklist-custom-modal {
  &.custom-modal {
    .mat-dialog-container {
      padding: 20px;
      width: 100vw;
      max-width: 640px !important;
    }
  }

  .unstyle-option {
    height: 500px;
    overflow: auto;

    @media screen and (max-height: 890px) {
      height: calc(100vh - 350px);
    }

    &::-webkit-scrollbar-track {
      background-color: #fff;
      border: 5px solid #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #fff;
    }
  }

  .mat-wrapper .mat-form-field-wrapper {
    padding-top: 10px;
  }

  .anchor-link {
    font-size: 14px;
  }
}

// Add Permission Custom Modal
.add-checklist-permission-custom-modal {
  &.custom-modal {
    max-width: 640px !important;

    .mat-dialog-container {
      padding: 20px;
      width: 100vw;

      @media screen and (max-width: 1800px) {
        padding-bottom: 0;
      }
    }
  }

  .add-checklist-permission-modal-table {
    .table-max-height {
      height: 500px;
      max-height: inherit;
    }

    td {
      padding: 0 !important;

      &.custom-checkbox-block-one-only {
        padding-bottom: 12px !important;
      }
    }
  }
}

// Custom Fields Settigns
.rt-custom-field-settings {
  padding-top: 14px;

  .company-info-block {
    &.top {
      border: 1px solid #aaa !important;
      background-color: #fafafa;
      border-radius: 8px;
    }
  }

  .card.mat-card.box-card {
    padding: 10px;
  }

  .custom-field-box-list>li {
    padding: 8px 0;
  }

  .mat-card-header {
    li {
      .anchor-link {
        font-size: 14px;
      }
    }
  }
}

// addcustomfield-custom-modal
.addcustomfield-custom-modal {
  &.custom-modal {
    max-width: 640px !important;

    .mat-dialog-container {
      padding: 20px;
      width: 100vw;
      max-width: 640px !important;
      height: 700px;

      @media screen and (max-width: 1800px) {
        padding-bottom: 0;
      }
    }
  }

  .anchor-link {
    font-size: 16px;
  }

  .rt-custom-client-field {
    height: 92%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .custom-input-field.mat-form-field .mat-form-field-infix {
      padding-bottom: 2px;
    }
  }
}

// Timesheet Settings
.rt-timesheet-ctm-com {
  padding-top: 14px;

  .company-info-block {
    &.top {
      border: 1px solid #aaa !important;
      background-color: #fafafa;
      border-radius: 8px;
    }
  }

  .card.mat-card.box-card {
    padding: 10px !important;
  }

  .block-title {
    &.bt-one {
      margin-bottom: 10px !important;
    }

    &.bt-two {
      margin-bottom: 0 !important;
    }
  }

  &.timesheet-ctm-com {
    .table.custom-table.timesheet-table {
      th {
        padding: 8px 3px !important;
        border-top: 1px solid rgba(0, 0, 0, 0.1215686275);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);
      }

      td {
        padding: 8px 10px !important;
      }
    }

    .organization-day {
      .c-checkbox-button {
        .mat-checkbox-layout {
          padding: 6px 24px !important;
        }
      }
    }

    .organization-day {
      .mat-card-actions {
        padding-bottom: 0;
      }
    }

    .mg-btm {
      margin-bottom: 10px;
    }

    .card-process-actual .badge-radio {
      &:last-child {
        margin-right: 0;
      }
    }

    .user-confirmation-card .mat-card-header {
      padding: 0 !important;
    }

    .timesheet-month-calendar .calenader-list {
      padding: 10px;
    }

    .user-confirmation-card {
      table {
        thead {
          tr {
            border-top: 1px solid rgba(0, 0, 0, 0.1215686275);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);
          }

          th {
            min-width: 200px;
            padding: 7px 14px !important;
            border-right: 1px solid #eee;
            font-weight: 700 !important;
            color: #222 !important;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            font-size: 14px;

            &:last-child {
              border-right: 0px solid;
            }
          }
        }

        tbody {
          td {
            min-width: 200px;
            padding: 6px 14px !important;
            border-right: 1px solid #eee;
            vertical-align: middle;

            &:first-child {
              width: inherit !important;
              max-width: inherit !important;
              padding: 5px 14px !important;
              border-bottom: 1px solid #eee !important;
              border-right: 1px solid #eee !important;
            }

            &:last-child {
              width: inherit !important;
              max-width: inherit !important;
              padding: 5px 14px !important;
              border-bottom: 1px solid #eee !important;
              border-right: 0px solid;
            }

            &.first-table-data {
              &:first-child {
                padding: 5px 14px !important;
              }
            }

            .u-w-i-d h4 {
              margin-top: 0;
              font-weight: 600;
              text-transform: capitalize;
            }
          }

          a {
            cursor: pointer;
            text-decoration: none;
          }
        }
      }
    }
  }
}

// General Settings
.rt-spc-general-settings {
  padding-top: 14px;

  .company-info-block {
    &.top {
      border: 1px solid #aaa !important;
      background-color: #fafafa;
      border-radius: 8px;
    }
  }

  .card.mat-card.box-card {
    padding: 10px;
  }

  .card.mat-card .mat-card-header {
    padding: 10px 14px;
  }

  .card-custom {
    max-width: 98.5%;
  }

  .input-custom-general-setting {
    max-width: 98%;
  }

  &.mub-general-settings {
    .table.custom-table {
      thead {
        tr {
          border-top: 1px solid rgba(0, 0, 0, 0.1215686275);
          border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);
        }

        th {
          font-size: 14px;
          color: #222;
          font-weight: 700;
          padding-left: 0;
        }
      }

      tbody {
        td {
          font-weight: 600;
          color: #222;
          padding: 5px 0 !important;
        }
      }
    }
  }
}

// Mark as Approval
.rt-mark-as-approval {
  padding-top: 14px;

  .company-info-block {
    &.top {
      border: 1px solid #aaa !important;
      background-color: #fafafa;
      border-radius: 8px;
    }
  }

  .card.mat-card.box-card {
    padding: 10px;
  }

  .card.mat-card .mat-card-header {
    padding: 14px;
  }

  .table.custom-table {
    thead {
      tr {
        border-top: 1px solid rgba(0, 0, 0, 0.1215686275);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);
      }

      th {
        font-size: 14px;
        color: #222;
        font-weight: 700;
        padding: 10px 16px !important;
        border-right: 1px solid #eee;

        &:last-child {
          border-right: 0px solid #eee !important;
        }
      }
    }

    tbody {
      td {
        font-weight: 600;
        color: #222;
        padding: 5px 16px !important;
        border-right: 1px solid #eee;

        &:last-child {
          border-right: 0px solid #eee !important;
        }
      }
    }
  }
}

// Email Configuration Rated
.email-configuration-rated {
  padding-top: 14px;

  .company-info-block {
    &.top {
      border: 1px solid #aaa !important;
      background-color: #fafafa;
      border-radius: 8px;
    }
  }

  .card.mat-card.box-card {
    padding: 10px;
  }

  .card.mat-card .mat-card-header {
    padding: 10px 14px;
  }

  .table.custom-table {
    thead {
      tr {
        border-top: 1px solid rgba(0, 0, 0, 0.1215686275);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);
      }

      th {
        font-size: 14px;
        color: #222;
        font-weight: 700;
        padding: 10px 16px !important;
        border-right: 1px solid #eee;

        &:last-child {
          border-right: 0px solid #eee !important;
        }
      }
    }

    tbody {
      td {
        color: #222;
        padding: 10px 16px !important;
        border-right: 1px solid #eee;

        &:last-child {
          border-right: 0px solid #eee !important;
        }
      }
    }
  }
}

// Organization Settings Header
.organization-settings-only {
  .rt-organization-settings-only-subheader {
    img {
      &.oso-shead-img {
        width: 40px !important;
        height: 40px !important;
      }
    }

    .block-title {
      margin-bottom: 0;
    }

    .sub-block-title {
      margin-bottom: 0;
    }

    &.c-d-board {
      padding: 2px;
    }
  }
}

// Add Email Configuration
.addemail-config-modal {
  &.custom-modal .mat-dialog-container {
    padding: 20px;
  }
}

.rt-email-permission {
  .card.mat-card {
    border: 0px solid #ccc;

    .mat-card-header {
      padding: 0 0 14px 0;
    }
  }

  .custom-input {
    margin-bottom: 10px;
  }

  .table {
    thead {
      tr {
        border-top: 1px solid rgba(0, 0, 0, 0.1215686275);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);

        th {
          font-weight: 700 !important;
          color: #222 !important;
          border-right: 1px solid #eee !important;
          font-size: 14px !important;

          &:last-child {
            border-right: 0px solid #eee !important;
          }
        }
      }
    }

    tbody {
      td {
        font-weight: 600 !important;
        color: #222 !important;
        padding: 5px 14px !important;
        border-right: 1px solid #eee !important;
        text-transform: capitalize !important;
        vertical-align: middle;

        &:last-child {
          border-right: 0px solid #eee !important;
        }
      }
    }
  }

  .mat-card-actions {
    padding: 10px 0;
  }
}

// Edit Email Configuration

.email-modal-dropdown {
  &.custom-modal .mat-dialog-container {
    padding: 20px;
  }
}

.rt-email-dropdown-part {
  .multiselect-dropdown {
    .dropdown-btn {
      margin-bottom: 12px !important;
    }
  }

  .email-validation {
    margin-top: -16px !important;
    margin-bottom: 10px;
  }

  .custom-input-field.mat-form-field input {
    color: #222;
    font-weight: 600;
    background-color: transparent;
  }
}

// Main Account Settings
.notification-userlist-modal {
  &.custom-modal .mat-dialog-container {
    padding: 20px;
    max-width: 652px;
  }

  ul {
    padding: 0;

    li {
      padding: 4px 0;
      border-bottom: 1px solid #eee;
      font-weight: 600;
      color: #222;
    }
  }

  .scroll-fix {
    height: 400px;
  }
}

// Service Management
.rt-services-mngmnt {
  padding-bottom: 0 !important;

  .table.custom-table {
    thead {
      tr {
        border-top: 1px solid rgba(0, 0, 0, 0.1215686275);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);
      }

      th {
        min-width: 200px;
        font-size: 14px;
        color: #222;
        font-weight: 700;
        padding: 10px 16px !important;
        border-right: 1px solid #eee;

        &:last-child {
          border-right: 0px solid #eee !important;
        }
      }
    }

    tbody {
      td {
        min-width: 200px;
        color: #222;
        padding: 5px 16px !important;
        border-right: 1px solid #eee;

        &:last-child {
          border-right: 0px solid #eee !important;
        }

        &.mat-column-expandedDetail {
          padding: 0 !important;
        }
      }
    }

    .inner-detail-table {
      thead {
        border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
      }

      th {
        min-width: 200px;
        padding: 13px;
        border-right: 1px solid #eee;
        font-weight: 700;
        color: #222;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        &:last-child {
          border-right: 0px solid;
        }
      }

      td {
        min-width: 200px;
        padding: 6px 14px !important;
        border-right: 1px solid #eee;

        &:last-child {
          border-right: 0px solid;
        }
      }

      a {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  .mat-expansion-panel-header.mat-expanded {
    height: 48px !important;
  }

  .mat-tab-body.mat-tab-body-active {
    overflow: auto !important;
  }

  .mat-tab-body-wrapper {
    overflow: unset;
  }
}

.add-service-modal {
  &.custom-modal {
    max-width: 70% !important;
    height: 80vh;

    .mat-dialog-container {
      padding: 5px;
      overflow: hidden;
    }
  }

  .rt-add-service {
    .custom-input-field.mat-form-field {
      input {
        font-weight: 600;
        color: #222;
        text-transform: capitalize;
      }

      .mat-form-field-infix,
      .bottom-side-space {
        margin-bottom: 0;
        padding-bottom: 5px;
      }
    }

    input.mat-input-element {
      font-weight: 600;
      color: #222;
    }

    &.mub-add-service .accordion-item .accordion-button:not(.collapsed):after {
      content: none;
    }

    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
      background-color: #fff !important;
    }

    .mat-expansion-panel-header.mat-expanded:focus,
    .mat-expansion-panel-header.mat-expanded:hover {
      background: #fff !important;
    }

    .action-btn-h {
      height: 92%;
    }
  }

  .another-expansion {
    ul.rt-add-services-content {
      overflow-y: hidden;
      overflow-x: hidden;
      max-height: calc(61vh - 30px);

      @media only screen and (min-width: 850px) {
        max-height: calc(61vh - 82px);
      }

      @media only screen and (min-width: 1550px) {
        max-height: calc(61vh - 52px);
      }

      li {
        border: 1px solid #eee;
        border-bottom: 0px;
        padding: 0 10px;

        &:last-child {
          border-bottom: 1px solid #eee;
        }

        .custom-coled {
          min-height: 41px;
          padding: 5px 10px;

          .mat-select-placeholder {
            color: #444;
          }

          &.coled1 {
            border-right: 1px solid #eee;
          }

          .mat-select-value {
            vertical-align: middle;
            line-height: 33px;
          }
        }
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .custom-checkbox {
      font-weight: 700;
      color: #222;
      margin-top: 3px;
    }

    .mat-expansion-panel-body {
    //   height: 42em;
    // max-height: 42em;
    }

    .custom-input-field.mat-form-field .mat-form-field-underline {
      display: none;
    }

    .custom-input-field.mat-form-field .mat-form-field-infix {
      margin-bottom: 0;
      padding: 0;
      border-top: 0;
    }

    .mat-expansion-panel-content {
      overflow: unset;
      max-height: calc(61vh - 30px);
    }
  }

  .mat-expansion-panel-header.mat-expanded {
    height: 48px !important;
  }

  .mub-add-service .accordion-item .accordion-button:after {
    content: none;
  }
}

// Bulk Export Modal
.bulk-export-modal {
  &.custom-modal .mat-dialog-container {
    padding: 14px;
  }

  .rt-bulk-export-client {
    form {
      &.waves-bg {
        background: #eee;
        padding: 12px;
        border: 1px solid #aaa;
        border-radius: 4px;
      }

      h5 {
        font-weight: 700 !important;
        color: #222 !important;
        font-size: 14px;
      }
    }
  }

  li {
    margin: 2px 12px;
  }

  .rt-bulk-export-member {
    form {
      &.waves-bg {
        background: #eee;
        padding: 12px;
        border: 1px solid #aaa;
        border-radius: 4px;
      }

      h5 {
        font-weight: 700 !important;
        color: #222 !important;
        font-size: 14px;
      }

      &.first {
        margin-bottom: 0 !important;
        margin: 13px;
      }
    }
  }
}

.font-14 {
  font-size: 14px !important;
}

.font-style {
  .color_222 {
    color: #222 !important;
  }

  .weight_700 {
    font-weight: 700 !important;
  }

  .weight_600 {
    font-weight: 600 !important;
  }
}

// Back Button
.back-image {
  position: absolute;
  left: 36px;
  top: 30px;

  @media screen and (max-width: 1590px) {
    left: 26px;
    top: 24px;
  }

  @media screen and (max-width: 1250px) {
    left: 20px;
  }

  &.backTwo {
    left: 6px;
    top: 79px;
    z-index: 123;
    cursor: pointer;
    width: 20px;
    text-align: center;
    height: 30px;
    line-height: 28px;
  }
}

// Customize Icon Style
img {
  &.customize-btn {
    width: 20px;
  }
}

// Pie Chart :
.rt-pie-chart-excel {
  &.pie-chart-excel {
    .chart-card {
      width: 320px !important;
      height: 320px !important;
      padding: 10px !important;
      min-width: 320px !important;
    }

    .pie-chart-information {
      margin-bottom: 0 !important;
    }
  }
}

// Global -table scroll
.global-table-scroll {
  max-height: calc(100vh - 255px);

  &.status {
    max-height: calc(100vh - 185px);
  }

  &.roles {
    max-height: calc(100vh - 205px);
  }

  &.member {
    max-height: calc(100vh - 315px);
  }

  &.teamgroup {
    max-height: calc(100vh - 315px);
  }

  &.regular-service {
    max-height: calc(100vh - 220px);
    overflow: auto;
  }

  &.recurrence-service {
    max-height: calc(100vh - 220px);
    overflow: auto;
  }

  @media screen and (max-height: 900px) {
    max-height: calc(100vh - 175px);

    &.member {
      max-height: calc(100vh - 295px);
      overflow-x: auto;
    }

    &.teamgroup {
      max-height: calc(100vh - 325px);
      overflow-x: auto;
    }

    &.roles {
      max-height: calc(100vh - 175px) !important;
    }

    &.regular-service {
      max-height: calc(100vh - 215px);
    }

    &.recurrence-service {
      max-height: calc(100vh - 215px);
    }
  }

  &::-webkit-scrollbar-track {
    background-color: #fff !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fafafa !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
    border: 31px solid #fafafa;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    z-index: 0;
    border-radius: 14px;
    overflow: auto;
    border: 6px solid #fff;
  }
}

// For Cross Icon
.cross-ics-member {
  cursor: pointer;

  &.pstn-absolute {
    position: absolute;
    top: 0;
    right: 0;

    &.right--14 {
      right: -14px;
    }

    &.top--24 {
      top: -24px;
    }

    &.right-14 {
      right: 14px;
    }

    &.right-24 {
      right: 24px;
    }

    &.z-ex {
      z-index: 123;
    }
  }
}

// Postion sticky Header
.sticky-position {
  thead {
    position: sticky;
    top: 0px;
    background: #ccc;
    z-index: 123;
  }

  &.top-m-1 {
    thead {
      top: -1px;
    }
  }
}

// User Report
.rt-user-report-table-independent {
  .customize-icon {
    img {
      width: 20px;
    }

    span {
      font-size: 14px;
      color: #5e5e5e;
      font-weight: 600;
  
    }
  }

  .custom-input {
    height: 40px !important;
  }

  .status-badge {
    .status-title {
      font-size: 13px !important;
    }
  }

  .status-icon-content {
    .icon-text {
      font-size: 14px !important;
    }
  }
}

// Sticky Footer
@media screen and (max-width: 1800px) {
  .sticky-footer {
    position: sticky;
    bottom: 0;
    background: #fff;
    z-index: 123;
    padding-bottom: 20px;
    margin-top: 0 !important;
    padding-top: 20px;

    &.pr-15 {
      padding-right: 15px;
    }
  }
}

// Add member date
.mui-add-member-date .member-date .mat-form-field-flex .datepicker-suffix button.mat-focus-indicator.mat-icon-button.mat-button-base {
  @media screen and (max-width: 2000px) {
    padding-bottom: 5px !important;
  }
}

.cal-modal-btns-report {
  .ok-btn {
    padding: 4px !important;
    color: #fff !important;
    margin-top: 2px !important;
    background-color: #0d6efd;
    opacity: 0.9;
    border-radius: 8px;

    &:hover {
      color: #fff !important;
    }

    &.anchor-link.btn {
      &:hover {
        color: #fff !important;
      }
    }
  }
}

.textarea-scrollable {
  &::-webkit-scrollbar-track {
    background-color: #fff !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fafafa !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    z-index: 0;
    border-radius: 14px;
    overflow: auto;
  }
}

// User Report Table :
.rt-user-report-table-only {
  &.user-report-table-only {
    table {
      thead {
        border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
      }

      th {
        padding: 13px;
        border-right: 1px solid #eee;
        font-weight: 700;
        color: #222;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        &:last-child {
          border-right: 0px solid;
        }
      }

      td {
        padding: 6px 14px !important;
        border-right: 1px solid #eee;
        color: #444;
        font-weight: 500;

        &.mat-column-task_name {
          .other-contebnt {
            border: 1px solid transparent;
            padding: 5px 7px;

            &:hover {
              border: 1px solid #aaa;
              border-radius: 8px;
              background-color: #fafafa;
              box-shadow: 0px 0px 2px 1px #5652524a;
            }
          }
        }

        &:last-child {
          border-right: 0px solid;
        }
      }

      a {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  .mat-tab-label {
    &:focus {
      :not(.mat-tab-disabled) {
        opacity: 1;
      }
    }

    &:not(.mat-tab-disabled) {
      opacity: 1;
    }
  }

  &.rt-more-table-scroll {
    table {
      thead {
        border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
      }

      th {
        padding: 13px;
        border-right: 1px solid #eee;
        font-weight: 700 !important;
        color: #222 !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;

        &:last-child {
          border-right: 0px solid;
        }
      }

      td {
        padding: 6px 14px !important;
        border-right: 1px solid #eee;
        color: #444 !important;
        font-weight: 500;

        &.mat-column-task_name {
          span {
            border: 1px solid transparent;
            padding: 5px 7px;

            &:hover {
              border: 1px solid #aaa;
              border-radius: 8px;
              background-color: #fafafa;
              box-shadow: 0px 0px 2px 1px #5652524a;
            }
          }
        }

        &.mat-column-status {
          .badge {
            text-align: center;

            &.badge {
              padding-left: 20px;
              min-width: 123px;
              padding-right: 20px;
            }
          }
        }

        &:last-child {
          border-right: 0px solid;
        }
      }

      a {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}

.rt-report-tabExpansion-chart {
  .mat-expansion-panel-body {
    padding: 0;
  }
}

// Report Timelog : Header
.rt-mub-time-log-graph {
  .real-time {
    padding: 0 22px !important;

    &.time-expansion {
      padding: 8px 22px !important;
    }
  }

  &.mub-time-log-graph {
    .real-accrodian {
      .mat-expansion-panel-header {
        &.mat-expanded {
          height: 42px;
        }
      }
    }

    .mat-expansion-panel-header {
      height: 38px;
    }
  }
}

// Project Wise Report
.rt-project-report-only {
  .project-containner {
    .card-containner {
      .card-header {
        margin: 0 0 16px !important;
      }
    }
  }

  .rt-project-report-dashboard-table {
    table {
      thead {
        border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
      }

      th {
        font-size: 14px !important;
        padding: 13px;
        border-right: 1px solid #eee;
        font-weight: 700 !important;
        color: #222 !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;

        &:last-child {
          border-right: 0px solid;
        }
      }

      td {
        padding: 6px 14px !important;
        border-right: 1px solid #eee;
        color: #444 !important;
        font-weight: 500;

        &.mat-column-project_name {
          span {
            border: 1px solid transparent;
            padding: 5px 7px;

            &:hover {
              border: 1px solid #aaa;
              border-radius: 8px;
              background-color: #fafafa;
              box-shadow: 0px 0px 2px 1px #5652524a;
            }
          }
        }

        &:last-child {
          border-right: 0px solid;
        }
      }

      a {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}

// Email Management Dashboard
.rt-email-management-dashboard {
  .email-cont {
    margin-top: 60px !important;
  }

  .btn-position {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

// Email List Config
.rt-email-list-config {
  .config-table {
    .table-responsive {
      table {
        thead {
          border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
        }

        th {
          font-size: 14px !important;
          padding: 13px;
          border-right: 1px solid #eee;
          font-weight: 700 !important;
          color: #222 !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;

          &:last-child {
            border-right: 0px solid;
          }
        }

        td {
          padding: 6px 14px !important;
          border-right: 1px solid #eee;
          color: #444 !important;
          font-weight: 500;

          &.mat-column-project_name {
            span {
              border: 1px solid transparent;
              padding: 5px 7px;

              &:hover {
                border: 1px solid #aaa;
                border-radius: 8px;
                background-color: #fafafa;
                box-shadow: 0px 0px 2px 1px #5652524a;
              }
            }
          }

          &:last-child {
            border-right: 0px solid;
          }
        }

        a {
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
}

// Filter Notifications
.rt-mub-filter-notification {
  .notification-top-head {
    margin-bottom: 0;
  }

  .mat-tab-header {
    margin-bottom: 0;
  }

  .rt-select-user {
    &.mat-select {
      padding-top: 0;

      .mat-select-trigger {
        border: 1px solid #aaa !important;
      }
    }
  }

  .rt-select-module {
    &.mat-select {
      padding-top: 0;

      .mat-select-trigger {
        border: 1px solid #aaa !important;
      }
    }
  }
}

.rt-user-list-view {
  .mat-drawer-container {
    padding-left: 0 !important;
  }

  .mat-drawer-inner-container {
    padding-right: 0 !important;
  }
}

.isReadClass {
  .mat-card {
    border: 1px solid #aaaaaa52 !important;
    padding: 10px;

    .notification-list-card {
      .card-user-information {
        .card-profile-title {
          .card-profile-img {
            img {
              width: 30px !important;
              height: 30px !important;
            }
          }
        }
      }
    }
  }

  .notification-wrp-content .mat-tab-header {
    margin-bottom: 16px;
  }
}

// Documents
.rt-myFolder {
  thead {
    border-top: 0px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}

.rt-myFile {
  thead {
    border-top: 0px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}

// Projects
.rt-app-project-list {
  .mat-tab-nav-bar {
    .mat-tab-link {
      height: 38px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .rt-app-project-grid-view {
    .view-btn {
      font-size: 16px !important;
    }

    .project-list-view-common {
      .title {
        font-size: 16px;
      }
    }

    .mat-card {
      padding: 8px;
    }

    .ctm-fav-card-layout {
      .fovorite-title-group {
        .mat-card-title {
          &.card-title-list {
            margin-top: 5px;
          }
        }
      }
    }
  }

  ul.project-gragh-cart {
    &.project-graph-only {
      width: 70%;
    }
  }
}

.list-page-common {
  .all-project-full-height {
    &.rt-all-project-full-height-only {
      height: calc(100vh - 240px);
    }
  }
}

.half-height-one {
  padding: 0px 22px 30px;

  .custom-margin:nth-child(5) {
    margin-top: 20px;
  }
}

// Project Details:
.rt-task-content {
  .task-list-heading {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

// Create Project :
.rt-create-project {
  .custom-input-field.mat-form-field input {
    font-weight: 600;
    color: #222;
    font-size: 16px;
  }

  .description {
    color: #222;
  }

  .mat-chip-list .mat-chip-list-wrapper .mat-chip.u-m-chip.m-s-u-chip span {
    color: #222;
  }
}

.add-folder {
  .custom-input-field.mat-form-field input {
    font-weight: 600;
    color: #222;
    font-size: 16px;
  }
}

.add-group-chat {
  .custom-input-field.mat-form-field input {
    font-weight: 600;
    color: #222;
    font-size: 16px;
  }

  .mat-chip-list .mat-chip-list-wrapper .mat-chip.u-m-chip.m-s-u-chip {
    color: #222;
  }
}

// sHARE nOTE :
.rt-shareNoteForm {
  table {
    thead {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      th {
        border-right: 1px solid #eeeeee !important;
        color: #222 !important;
      }
    }

    tbody {
      td {
        border-right: 1px solid #eeeeee;

        &:last-child {
          border-right: 0px;
        }
      }
    }
  }
}

.rt-task-content {
  .custom-edit-project-name {
    padding: 0 20px;
    width: 100%;

    input {
      font-size: 19px !important;
      font-weight: 600 !important;
      color: #222;
    }
  }
}

// Users > Overview section
.rt-profile-overview-ctn {
  &.profile-overview-ctn .over-view-button {
    padding: 10px 8px;
  }

  .profile-over-inner {
    .padding-l-none {
      padding-left: 0px;
    }

    .padding-r-none {
      padding-right: 0px;
    }

    .custom-flex {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      .over-view-cal {
        height: 100%;
      }
    }

    .row {
      margin: 0;
    }
  }
}

// Active
.mat-tab-label {
  &.mat-tab-label-active {
    opacity: 1;
  }
}

.msgContentBox {
  padding-right: 70px !important;
}

.rt-quick-reply {
  .radio-action-block {
    label {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  .rt-quick-reply-table {
    table {
      thead {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        th {
          font-size: 14px !important;
          padding: 13px;
          font-weight: 700;
          color: #222 !important;
          border-right: 1px solid #eee;

          &:last-child {
            border-right: 0px solid;
          }
        }
      }

      tbody {
        td {
          color: #444;
          font-weight: 500;

          &.mat-column-title {
            color: #222;
            font-weight: 600;
          }
        }
      }
    }
  }
}

// Add Quick Reply
.add-time-log-model {
  input.mat-input-element {
    font-weight: 700;
    color: #222;
    padding-bottom: 5px;
  }

  textarea.mat-input-element {
    font-weight: 500;
    color: #444;
  }
}

.rt-view-member-block .custom-input {
  @media screen and (max-width: 1360px) {
    width: 200px;
  }
}

.task--content-table {
  .mat-slider.mat-accent .mat-slider-thumb-label {
    width: 28px !important;
    background-color: #16b975 !important;
  }
}

.rt-add-time-log-model {
  .add-time-log-body {
    .mat-form-textarea {
      &.mat-form-textarea-only {
        .mat-form-field-infix {
          textarea.pending-approval-field {
            height: 70px !important;
          }
        }
      }
    }
  }
}

.rt-timesheet-month-table {
  table {
    thead {
      th {
        &:first-child {
          min-width: 380px;
        }
      }
    }

    tbody {
      tr {
        td {
          min-height: 48px;
          align-items: middle;

          &.task_name-style-timesheet {
            border: 1px solid transparent;

            .user-name {
              padding: 4px 6px 6px 6px;
            }

            &:hover {
              .user-name {
                border: 1px solid #aaa;
                border-radius: 8px;
                background-color: #fafafa;
                box-shadow: 0px 0px 2px 1px #5652524a;
              }
            }
          }
        }
      }
    }
  }
}

.rt-timesheet-month-table .task_name-style {
  min-width: 290px;
  padding: 0 0 0 34px !important;
}

.timelog-for-table {
  table {
    &.timelog-view-table {
      thead {
        tr {
          th {
            &.timelog_task_name {
              text-align: center;
              min-width: 190px;
              width: 190px;
              max-width: 200px;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            max-width: 100px;

            &.timelog_task_name {
              min-width: 190px;
              width: 190px;
              max-width: 200px;
            }
          }
        }
      }
    }
  }
}

.set-width {
  width: 100% !important;
}

.mat-column-status span.text-capitalize {
  width: fit-content !important;
}

.company-details-only .custom-badge {
  justify-content: flex-start !important;

  &.bg-transparent {
    background-color: transparent;
  }

  .bg-prime {
    background: #eee;
    padding: 2px 10px;
    border-radius: 20px;
  }
}

// Task List Small One Only
.chatmenuclsopenwidth {
  .user-chat-box-header-search {
    padding: 0 24px 5px !important;
    border-bottom: 1px solid #e0e0e0 !important;
    border-top: 0px solid #e0e0e0 !important;
  }

  img.controll {
    margin-right: 3px;
  }

  .ql-editor.ql-blank {
    max-width: 100%;
  }
}

.ql-editor.ql-blank {
  max-width: 96%;

  @media screen and (max-width: 1780px) {
    max-width: 93%;
  }

  @media screen and (max-width: 1600px) {
    max-width: 90%;
  }

  @media screen and (max-width: 1350px) {
    max-width: 88%;
  }

  @media screen and (max-width: 1250px) {
    max-width: 86%;
  }
}

// Discussion - : Modals
// Add Group Chat modal Only
.add-group-chat-modal {}

// Add Member List
.onlycustom-meber-cls {
  .bg-dark-grey input.custom-input {
    border: 1px solid #aaa !important;
    background-color: #fafafa;
    color: #222 !important;
    font-size: 16px;
  }
}

// Customize Heading
.customize-heading {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 123;
  padding-bottom: 20px;
}

// Bulk Edit Disable
.bulk-edit-action a {
  font-size: 14px;
  padding-right: 12px;
  font-weight: 700;
}

.custom-width-card {
  &.col-xl-4 {
    @media screen and (min-width: 1400px) and (max-width: 1600px) {
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    @media screen and (min-width: 1200px) and (max-width: 1320px) {
      flex: 0 0 auto;
      width: 50%;
    }
  }
}

// Projects Responsive
.rt-app-project-grid-view {
  .fovorite-title-group {
    .cutom-width {
      min-width: 125px;
      justify-content: flex-end;
    }
  }

  .project-list-view-common {
    padding: 8px 28px;
  }
}

.rt-app-project-list {
  .row-height {
    max-height: calc(100vh - 155px);
    overflow: hidden;
  }

  .rt-app-project-list-view {
    .tab-list-kanban-cards-for-close-main-block.rt-tabListKanbanCardsForCloseMainBlock .mat-tab-body-content .kanban-view-design-only.kanban-view-cards-for-only .kanban-internal-cards {
      max-height: calc(100vh - 270px);
    }

    .tab-list-thrice-block .tab-list-thrice .user-profile-task-list .list-table-hscroll {
      bottom: 25px;
      max-height: calc(100vh - 200px);
    }
  }
}

// Authentication Reset Password

.password-assitance,
.custom-input-choose-new-pswrd {
  padding-bottom: 20px;
}

// Image Preview
.right-action-btn {
  button {
    &.right-menu-item {
      background-color: transparent;
      border: 0px solid transparent;

      img {
        margin-right: 10px;
        border: 1px solid #000;
        padding: 4px;
        width: 33px;
        height: 30px;
        border-radius: 7px;
      }
    }
  }
}

.confirm-submit {
  p {
    display: flex;
    gap: 7px;
    line-height: 22px !important;
  }

  .test-note {
    font-weight: 700;
  }
}

.rt-task-content {
  .unread {
    td {
      font-weight: 700;

      .task--title {
        font-weight: 700;
      }
    }
  }
}

// For Task Calendar
.dot-calendar {
  .fc-daygrid-day-events {
    margin-top: 32px;
  }

  .fc-daygrid-day-events {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    bottom: 0;
  }

  .fc-daygrid-day-bottom {
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
  }

  .fc-daygrid-event-harness {
    min-width: 15px;
    margin-top: 0;
  }

  .fc-daygrid-event-harness {
    margin-top: 0 !important;
  }

  .fc-daygrid-event-harness {
    left: 0px;
    position: relative;
    right: 0px !important;
    top: 0px !important;
    bottom: 0px !important;
    width: 10px;
    visibility: visible !important;
    display: flex;
    align-items: end;
    margin-bottom: 10px;
  }

  &.custom-calendar {
    .fc-list-event-dot {
      padding: 4.5px;
    }
  }
}

.list-page-common .all-project-full-height.rt-all-project-full-height-only.rt_only_this{
  height: calc(100vh - 18em) !important;
    .full-height{
      height: 80% !important;
      overflow-y: auto;
      max-height: 100%;
  }
}