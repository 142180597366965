.custom-input-group {
  width: 360px;
  display: flex;
  align-items: center;
  background: #fafafa;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  height: 40px;

  .mat-select {
    max-width: 116px;
    padding-left: 16px;
    border-right: 1px solid #eeeeee;
    padding-right: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #616161;
  }

  input.form-control {
    border: 0;
    background: transparent url(../icons/searchicon.svg) no-repeat;
    background-position: left 15px center;
    box-shadow: none;
    padding-left: 45px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #9e9e9e;
  }
}

.custom-ipnut-select {
  .mat-select {
    background: #fafafa;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    padding: 7px 16px 8px 12px;
    width: 120px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #616161;
  }
}

button.custom-input-btn {
  background: #fafafa;
  border: 1px solid #aaa;
  border-radius: 8px;
  height: 40px;
  min-width: 40px;

  .mat-button-wrapper {
    display: flex;
    align-items: center;
  }
}

button.custom-input-btn:hover {
  color: #121212 !important;
}

.mat-select-panel.custom--select {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0 12px 12px #21212114 !important;
  border-radius: 8px;
  min-width: 216px;

  .mat-option {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #616161;
    padding: 9px 24px;
    height: auto;
  }
}

.action--list {
  display: inline-block;
  position: absolute;
  right: 36px;
  transform: translateY(10px);
  z-index: 10;
}

.task--tab {
  .mat-tab-header {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 16px;
    width: calc(100% - 80px);
    margin: 0 40px;
    margin-bottom: 0 !important;
  }

  .mat-tab-body-wrapper {
    min-height: calc(100vh - 182px);
    background: #fff;
  }

  .mat-tab-body {
    overflow: hidden !important;
    height: calc(100vh - 174px);

    .create-task-scroll-box {
      min-height: 100%;
    }
  }

  .custom-height {
    height: 100%;
    overflow: auto;
  }
}

.task--expansion-panel.mat-expansion-panel {
  border-radius: 0;
  box-shadow: none !important;
  margin-bottom: 0;

  .mat-expansion-panel-header {
    padding: 0 36px;
    height: 36px !important;

    .mat-expansion-panel-header-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.0025em;
      color: #212121;
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}

.custom--table-header {
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: none !important;

  .mat-header-row {
    background: #f9f8f8;
    min-height: auto;
    border-bottom: 0;

    .mat-header-cell {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.005em;
      min-width: 200px;
      max-width: 200px;
      color: #222;
      padding-left: 0;
      padding-right: 0;
      border-right: 1px solid #eeeeee;
      padding: 10px;

      &:first-child {
        min-width: 610px;
        max-width: 100%;
        padding-left: 36px;
      }

      &:last-child {
        border-right: 0;
        padding-right: 0;
      }
    }
  }
}

.task--content-table {
  .mat-row {
    border-bottom: 0;
    align-items: stretch;

    &.higlighted,
    &:hover {
      background: #f9f8f8;
    }

    &.unread {
      position: relative;
      background: #f9f8f8;

      &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 4px;
        background: #276ef1;
        left: 0;
        top: 0;
      }
    }

    &:first-child {
      .mat-cell {
        border-top: 1px solid #eeeeee;
      }
    }
  }

  .unread {
    position: relative;
    background: #f9f8f8;

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 4px;
      background: #276ef1;
      left: 0px;
      top: 0px;
    }
  }

  .mat-cell {
    min-width: 200px;
    max-width: 200px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0;
    padding-right: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #616161;
    padding: 7px 10px;
    border-bottom: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;

    @media screen and (max-width: 1439px) {
      font-size: 13px;
    }

    @media screen and (max-width: 1300px) {
      font-size: 12px;
    }

    &:nth-child(2) {
      margin-left: 36px;
      margin-right: auto;
      padding-left: 0;
    }

    &:last-child {
      margin-right: 36px;
      border-right: 0;
      padding-right: 0;
    }
  }
}

.circle--checkbox {
  .mat-ripple {
    display: none;
  }

  .mat-checkbox-frame {
    border-radius: 50%;
    border-color: #bdbdbd;
  }

  .mat-checkbox-background {
    border-radius: 50%;
  }

  &.mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: #16b975 !important;
    }
  }

  &:hover {
    .mat-checkbox-frame {
      border-color: #67acf0;
    }
  }
}

:root .custom-checkbox.mat-checkbox-checked.black-bordered-checkbox {
  .mat-checkbox-background {
    background-color: transparent;
    border: 1px solid #000;

    .mat-checkbox-checkmark-path {
      stroke: #000 !important;
    }
  }
}

.square--mini {
  height: 8px;
  width: 8px;
  min-width: 8px;
  border-radius: 2px;
  display: inline-block;

  &-low {
    background: #16b975;
  }

  &-medium {
    background: #fab222;
  }

  &-high {
    background: #dc3535;
  }
}

.custom--status-menu-btn {
  padding: 6px;

  &.on-hover-show {
    padding: 6px 0;
    background: transparent;

    >img {
      display: none;
    }
  }

  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;

  >img {
    margin-left: 2px;
    width: 7px;
  }

  &:hover {
    padding-left: 6px;
    padding-right: 6px;
    background: #eeeeee;

    >img {
      display: block;
    }
  }
}

:root .custom--status-menu {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 12px 12px rgb(33 33 33 / 8%);
  border-radius: 8px;

  .mat-menu-content {
    padding: 0;

    .mat-menu-item {
      height: auto;
      line-height: unset;
      padding: 12px 27px;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.005em;
      color: #616161;

      &:hover {
        color: #616161 !important;
      }

      @media screen and (max-width: 1300px) {
        padding: 7px 19px;
      }
    }
  }

  &.user--detail-dropdown {
    padding: 16px;

    .user--img {
      height: 64px;
      width: 64px;
      margin-right: 16px;

      img {
        height: 64px;
        width: 64px;
      }

      .online--status {
        right: 10px;
      }
    }

    h4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.0025em;
      color: #212121;
      margin-bottom: 4px;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #616161;
      margin-bottom: 0;
    }
  }
}

.user--img {
  position: relative;
  height: 34px;
  width: 34px;
  min-height: 34px;
  min-width: 34px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px !important;

  img.img-avatar {
    height: 34px;
    width: 34px;
    object-fit: cover;
    border-radius: 50%;

    &.custom-max-w-h {
      max-width: 32px;
      max-height: 32px;
    }
  }

  .online--status {
    background: #fab222;
    border: 1px solid #ffffff;
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    bottom: 0px;
    right: 0px;
    position: absolute;

    &.online--status-inside {
      bottom: 0px !important;
      right: -1px !important;
      width: 7px;
      height: 7px;
    }

    &.default-active-status {
      background: green;
    }
  }
}

.default-active-status {
  background: green;
}

.task--title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.005em;
  color: #444;
  max-width: 20em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &::first-letter {
    text-transform: capitalize;
  }
}

.hover--element {
  .hover-sho-element {
    display: none;
  }

  &:hover {
    .hover-sho-element {
      display: block;
    }
  }
}

.user--list {
  >li {
    margin-left: -10px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.extra--user-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: #2f3941;
}

.action--list {
  .mat-button {
    height: 30px;

    .mat-button-wrapper {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.005em;
      color: #444;
    }
  }
}

.select--filter-dropdown {
  border: 1px solid #eeeeee !important;
  box-shadow: 0 12px 12px #21212114 !important;
  border-radius: 8px !important;
  min-width: 360px !important;
}

.mat-drawer.customize--drawer {
  z-index: 100;
  width: 360px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(238, 238, 238);
  height: calc(100% - 180px);
  top: 180px;
  box-shadow: none !important;
  padding: 24px 4px 24px 24px;
  position: fixed;
}

.customize-box {
  display: flex;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.005em;
  color: #616161;
}

.customize-item-list {
  margin-top: 16px;

  >li:not(:last-child) {
    margin-bottom: 16px;
  }
}

.custom-select-overlay {
  transform: translate(-17px, 34px) !important;
}

.multi-select-chip-menu {
  border: 1px solid #eeeeee !important;
  box-shadow: 0 8px 8px #21212114 !important;
  border-radius: 8px !important;
  min-width: 360px !important;

  ul {
    padding: 16px 25px 0;

    >li:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &.filter-menu {
    // min-width: 300px !important;
    // max-width: 547px;
    max-width: inherit;

    .mat-menu-content:not(:empty) {
      padding: 15px 24px;
    }
  }

  .mat-dialog-content {
    margin: 0;
    padding: 0;
    overflow: visible;
  }

  .custom-menu-list {
    padding: 0 15px;
  }
}

.bottom-action-div {
  background: #212121;
  box-shadow: 0 -8px 16px #2121211f;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 100000;
  width: fit-content;
  width: 100vw;
  max-width: 552px;
  transition: 0.2s ease-in;
  transition-delay: 0.2s;

  &.bulk-action-div {
    @media screen and (max-width:1720px) {
      left: auto !important;
      right: 70px !important;
    }
    @media screen and (max-width:1400px) {
      right: 20px !important;
    }
  }

  .circle-div {
    border: 1px solid #bdbdbd;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 16px;
  }

  .text-div {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #e0e0e0;
  }

  ul {
    column-gap: 16px;
  }
}

.mat-menu-panel.custom-sub-menu-dropdown-menu {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 8px 8px rgb(33 33 33 / 8%);
  border-radius: 8px;
  min-width: 360px;
  padding: 16px;
  max-width: 360px;

  .form-control {
    border: 0;
    border-bottom: 1px solid #eeeeee;
    border-radius: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #9e9e9e;
  }

  .grey--stripe {
    background: #f5f5f5;
    padding: 5px 12px;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.005em;
    color: #9e9e9e;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: right;
      letter-spacing: 0.005em;
      color: #212121;
    }
  }

  &.calender-sub-menu {
    min-width: 288px;
  }

  &.checklist-menu {
    min-width: 530px;
    max-width: 530px;
    text-align: left;

    .mat-menu-content {
      padding: 17px 0;
    }
  }
}


@media screen and (max-height: 910px) {
  .mat-menu-panel.custom-sub-menu-dropdown-menu {
    &.calender-sub-menu {
      min-width: 288px;
      max-height: 560px;
      overflow-y: scroll;
    }
  }

}

@media screen and (max-height: 840px) {
  .mat-menu-panel.custom-sub-menu-dropdown-menu {
    &.calender-sub-menu {
      min-width: 288px;
      max-height: 450px;
      overflow-y: scroll;
    }
  }

}

@media screen and (max-height: 720px) {
  .mat-menu-panel.custom-sub-menu-dropdown-menu {
    &.calender-sub-menu {
      min-width: 288px;
      max-height: 385px;
      overflow-y: scroll;
    }
  }
}


.progress--slider {
  width: 100%;

  &.mat-slider-horizontal {
    .mat-slider-wrapper {
      height: 8px;

      .mat-slider-track-wrapper {
        height: inherit;
        border-radius: 4px;

        .mat-slider-track-background,
        .mat-slider-track-fill {
          height: inherit;
        }

        .mat-slider-track-background {
          background-color: #e0e0e0;
        }

        .mat-slider-track-fill {
          background-color: #73d5ac !important;
        }
      }

      .mat-slider-thumb {
        background: #16b975 !important;
        box-shadow: 0px 1px 1px rgb(33 33 33 / 16%) !important;
      }
    }
  }
}

.mat-slider-green {
  &.mat-slider.mat-accent {

    .mat-slider-track-fill,
    .mat-slider-thumb-label {
      background-color: #16b975 !important;
    }
  }
}

.dropdown-has-sub-menu {
  button.mat-menu-item {
    >img {
      width: 16px;
      height: 16px;
    }

    .mat-menu-submenu-icon {
      display: none;
    }
  }
}

.custom--datepicker {
  .mat-calendar-controls {
    margin-top: 0;

    >button {
      .mat-button-wrapper {
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.005em;
        color: #212121;
      }
    }
  }

  .mat-calendar-previous-button::after,
  .mat-calendar-next-button::after {
    border-color: #616161;
  }

  .mat-calendar-table-header {
    th {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.005em;
      color: #9e9e9e;
    }
  }

  .mat-calendar-body {
    .mat-calendar-body-label {
      display: none;
    }

    .mat-calendar-body-cell {
      font-weight: 500;
      font-size: 14px;
      line-height: 48px;
      text-align: center;
      letter-spacing: 0.005em;
      color: #616161;
    }
  }

  .mat-calendar-table-header-divider:after {
    background: #eeeeee;
  }
}

.bulk-eidt-menu-overlay {
  &+.cdk-overlay-connected-position-bounding-box {
    >.cdk-overlay-pane {
      margin-bottom: 25px;
      margin-left: -65px;
    }
  }
}

.due-data-div {
  border: 1px solid #eeeeee;
  border-width: 1px 0 1px 0;
  padding: 14px;

  span {
    width: 50%;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #616161;

    &+span {
      color: #9e9e9e;
    }

    .customize-item-list {
      margin-top: 16px;

      >li:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    .custom-select-overlay {
      transform: translate(-17px, 34px) !important;
    }

    .multi-select-chip-menu {
      border: 1px solid #eeeeee !important;
      box-shadow: 0 8px 8px #21212114 !important;
      border-radius: 8px !important;
      min-width: 360px !important;

      ul {
        padding: 16px 25px 0;

        >li:not(:last-child) {
          margin-bottom: 18px;
        }
      }
    }

    .bottom-action-div {
      background: #212121;
      box-shadow: 0 -8px 16px #2121211f;
      border-radius: 16px 16px 0 0;
      padding: 16px;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 100000;
      width: fit-content;
      width: 100vw;
      max-width: 552px;
      transition: 0.2s ease-in;
      transition-delay: 0.2s;

      .circle-div {
        border: 1px solid #bdbdbd;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 16px;
      }

      .text-div {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.005em;
        color: #e0e0e0;
      }

      ul {
        column-gap: 16px;
      }
    }

    .mat-menu-panel.custom-sub-menu-dropdown-menu {
      background: #ffffff;
      border: 1px solid #eeeeee;
      box-shadow: 0px 8px 8px rgb(33 33 33 / 8%);
      border-radius: 8px;
      min-width: 360px;
      padding: 16px;
      max-width: 360px;

      .form-control {
        border: 0;
        border-bottom: 1px solid #eeeeee;
        border-radius: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #9e9e9e;
      }

      .grey--stripe {
        background: #f5f5f5;
        padding: 5px 12px;
        font-weight: 700;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.005em;
        color: #9e9e9e;

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          text-align: right;
          letter-spacing: 0.005em;
          color: #212121;
        }
      }

      &.calender-sub-menu {
        min-width: 300px;
        max-width: 300px;
        overflow: hidden;

        .mat-select-value,
        input {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.005em;
          color: #616161;
        }

        .custom-error-msg {
          font-size: 10px;
        }

        .mat-form-field.mat-form-field-hide-placeholder {
          .mat-form-field-infix {
            .mat-input-element::placeholder {
              color: #616161 !important;
              -webkit-text-fill-color: #616161 !important;
            }
          }
        }
      }

      &.checklist-menu {
        min-width: 357px;

        .mat-menu-content {
          padding: 17px 0;
        }
      }
    }

    .progress--slider {
      width: 100%;

      &.mat-slider-horizontal {
        .mat-slider-wrapper {
          height: 8px;

          .mat-slider-track-wrapper {
            height: inherit;
            border-radius: 4px;

            .mat-slider-track-background,
            .mat-slider-track-fill {
              height: inherit;
            }

            .mat-slider-track-background {
              background-color: #e0e0e0;
            }

            .mat-slider-track-fill {
              background-color: #73d5ac;
            }
          }

          .mat-slider-thumb {
            background: #16b975;
            box-shadow: 0px 1px 1px rgb(33 33 33 / 16%);
          }
        }
      }
    }

    .dropdown-has-sub-menu {
      button.mat-menu-item {
        >img {
          width: 16px;
          height: 16px;
        }

        .mat-menu-submenu-icon {
          display: none;
        }
      }
    }

    .custom--datepicker {
      .mat-calendar-controls {
        margin-top: 0;

        >button {
          .mat-button-wrapper {
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.005em;
            color: #212121;
          }
        }
      }

      .mat-calendar-previous-button::after,
      .mat-calendar-next-button::after {
        border-color: #616161;
      }

      .mat-calendar-table-header {
        th {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          letter-spacing: 0.005em;
          color: #9e9e9e;
        }
      }

      .mat-calendar-body {
        .mat-calendar-body-label {
          display: none;
        }

        .mat-calendar-body-cell {
          font-weight: 500;
          font-size: 14px;
          line-height: 48px;
          text-align: center;
          letter-spacing: 0.005em;
          color: #616161;
        }
      }

      .mat-calendar-table-header-divider:after {
        background: #eeeeee;
      }
    }

    .bulk-eidt-menu-overlay {
      &+.cdk-overlay-connected-position-bounding-box {
        >.cdk-overlay-pane {
          margin-bottom: 25px;
          margin-left: -65px;
        }
      }
    }

    .due-data-div {
      border: 1px solid #eeeeee;
      border-width: 1px 0 1px 0;
      padding: 14px;

      span {
        width: 50%;
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #616161;

        &+span {
          color: #9e9e9e;
        }
      }
    }
  }

  .dragCursor {
    margin-right: 16px;
    cursor: move;
  }

  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    cursor: move;

    .mat-cell {
      min-width: 200px;
      max-width: 200px;
      padding-left: 0;
      padding-right: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #9e9e9e;
      padding: 17px 0;
      border-bottom: 0;

      &:first-child {
        min-width: 576px;
        margin-left: 36px;
      }

      &:last-child {
        max-width: initial;
        flex: 1 1 auto;
        margin-right: 36px;
      }
    }
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

.custom-field-card {
  .mat-card-content {
    overflow: visible !important;
  }
}

.create-task-scroll-box {
  width: 100%;
  height: calc(100vh - 11em) !important;
}

.rt-dashboard-content {
  .user-profile-task-list {
    &.math_block-user-profile-tasklist-tab {
      &.math_ui-onHover {
        &.dashboard-class {
          &.dashboard-dyanamic-list-only {
            .create-task-scroll-box {
              height: auto !important;
            }

            .ng-scrollbar-wrapper {
              position: unset;
              left: initial;
              right: initial;
              top: initial;
              bottom: initial;

              .ng-scroll-viewport-wrapper {
                position: unset;
                overflow: unset;

                .ng-scroll-viewport {
                  position: unset;
                  contain: unset;
                  will-change: unset;
                  overscroll-behavior: unset;
                  overflow-y: unset;
                  overflow-x: unset;

                  .list-table-hscroll {
                    &::-webkit-scrollbar-thumb {
                      background: #ffffff;
                    }

                    &::-webkit-scrollbar-track {
                      background: #ffffff !important;
                    }
                  }
                }
              }
            }

            .mat-expansion-panel-body {
              max-height: 415px;

              &::-webkit-scrollbar-thumb {
                background: #bdbbbb;
              }
            }

            .custom-height-dashboard-task-list {
              height: 506px !important;
            }
          }
        }
      }
    }
  }
}

.doc-card {
  .cmn-txt {
    white-space: nowrap;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-body {
    height: 156px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.filter-filed-list {
  padding: 0;

  >li {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;

    >.custom-input-btn {
      width: 163px;
      height: 34px;

      .mat-button-wrapper {
        font-size: 14px;
        line-height: 16px;
      }

      .mat-select-arrow {
        margin-left: auto !important;
      }
    }

    .custom-input {
      height: 34px;
      font-size: 14px;
    }

    a {
      img {
        width: 16px;
      }
    }

    &:nth-child(2) {
      align-items: start;

      .flex-grow-1.ctm-select-filed {
        flex-grow: unset;

        .form-container-cls {
          gap: 10px;
          display: flex;
          flex-direction: column;
          margin-top: 5px;

          span {
            input {
              background: #fafafa;
              border: 1px solid #eeeeee;
              border-radius: 8px;
              padding: 9px;
              height: 30px;
              width: 66px;
              font-size: 14px;
              margin-top: 6px;

            }

            .mat-button-toggle-checked {
              background-color: #d6e4f8;
              border: 1px solid #276ef1;
            }

            

            .label-heading {
              font-size: 12px !important;
              font-weight: 600;
            }
          }

        }
        @media screen and (width <= 1280px) {
          .mat-button-toggle-group-appearance-standard {
            border: 0px solid transparent !important;
          }
        }
      }

      .remove-filter-icon {
        position: relative;
        top: 10px;
      }
    }

    &:nth-child(1) {
      align-items: start;

      .flex-grow-1.ctm-select-filed {
        flex-grow: unset;

        .form-container-cls {
          gap: 10px;
          display: flex;
          flex-direction: column;
          margin-top: 5px;

          span {
            input {
              background: #fafafa;
              border: 1px solid #eeeeee;
              border-radius: 8px;
              padding: 9px;
              height: 30px;
              width: 66px;
              font-size: 14px;
              margin-top: 6px;

            }

            .mat-button-toggle-checked {
              background-color: #d6e4f8;
              border: 1px solid #276ef1;
              
            }

            .label-heading {
              font-size: 12px !important;
              font-weight: 600;
            }
          }

        }
        @media screen and (width <= 1280px) {
          .mat-button-toggle-group-appearance-standard {
            border: 0px solid transparent !important;
          }
        }
      }

      .remove-filter-icon {
        position: relative;
        top: 10px;
      }
    }

    &:nth-child(3) {
      align-items: start;

      .flex-grow-1.ctm-select-filed {
        flex-grow: unset;

        .form-container-cls {
          gap: 10px;
          display: flex;
          flex-direction: column;
          margin-top: 5px;

          span {
            input {
              background: #fafafa;
              border: 1px solid #eeeeee;
              border-radius: 8px;
              padding: 9px;
              height: 30px;
              width: 66px;
              font-size: 14px;
              margin-top: 6px;
            }

            .mat-button-toggle-checked {
              background-color: #d6e4f8;
              border: 1px solid #276ef1;
            }

            

            .label-heading {
              font-size: 12px !important;
              font-weight: 600;
            }
          }

        }
        @media screen and (width <= 1280px) {
          .mat-button-toggle-group-appearance-standard {
            border: 0px solid transparent !important;
          }
        }
      }

      .remove-filter-icon {
        position: relative;
        top: 10px;
      }
    }
  }

  &~div {
    .blue-gredient-btn {
      height: 40px;
    }
  }

  button:hover {
    color: #000 !important;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    // font-size: 14px;
    // line-height: 38px;
    // font-weight: 500;
    // color: #616161;

    font-size: 14px;
    padding: 0px 18px;
    line-height: 36px;
    font-weight: 500;
    color: #616161;

    @media screen and (max-width: 1280px) {
      line-height: 38px !important;
    }
  }


}

.task-search {
  width: 100vw;
  max-width: 400px;
}

.top-action-list {

  button.custom-input-btn,
  .blue-gredient-btn {
    height: 40px;

    .mat-button-wrapper {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.task-search {
  input {
    height: 40px;
    font-size: 14px;
  }
}

.minimize-task-list {
  position: fixed;
  bottom: 0;
  right: 15px;
  z-index: 10;
  margin-bottom: 0;
  column-gap: 12px;
  display: flex;
  justify-content: flex-end;

  .task-box {
    padding: 10px 24px;
    background: #f8d7d7;
    border: 0.5px solid #dc35357a;
    box-shadow: 0px -8px 16px rgba(33, 33, 33, 0.12);
    border-radius: 16px 16px 0px 0px;
    width: 250px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #212121;
    display: flex;
    align-items: center;
  }
}

.new-minimize-task-list {
  position: fixed;
  right: 30px;
  bottom: 10px;
  z-index: 10;
  display: flex;
  padding: 5px;
  margin-bottom: 0;
  column-gap: 10px;
  background: gainsboro;
  border-radius: 6px;

  .task-box {
    background: orange;
    padding: 5px 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 12px;

    img {
      width: 6px;
      margin-left: 5px;
    }
  }
}

.create-task-scroll-box .ng-scroll-content {
  display: block;
}

.bulk-edit-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.custom-class .fc-daygrid-event-harness:nth-last-child(2) {
  display: block;
  visibility: visible !important;
}

.custom-class .fc-daygrid-event-harness {
  display: none;
}

.custom-class .fc-daygrid-event-harness:nth-last-child(2) a.fc-event.fc-event-draggable {
  border-color: transparent !important;
  background-color: transparent !important;
}

.custom-class .fc-daygrid-event-harness {
  display: none;
}

.custom-class .fc-daygrid-event-harness:nth-last-child(2) .fc-event-main .content-event {
  display: none;
}

.custom-class .fc-daygrid-day-bottom {
  display: none;
}

.custom-class .fc-daygrid-event-harness:nth-last-child(2) a.fc-event.fc-event-draggable .content-event {
  display: none;
}

.custom-calendar .fc-header-toolbar.fc-toolbar .fc-toolbar-chunk div {
  display: flex;
}

.custom-calendar .fc-header-toolbar.fc-toolbar .fc-toolbar-chunk {
  display: flex;
  align-items: center;
}

.custom-calendar .fc-button-group button.fc-dayGridMonth-button {
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
}

.custom-calendar .fc-button-group button.fc-timeGridDay-button {
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
}

.custom-class .fc-daygrid-event-harness.fc-daygrid-event-harness-abs a.fc-event {
  border-color: transparent !important;
  background-color: transparent !important;
}

table.fc-col-header {
  width: 100% !important;
}

.fc-daygrid-body.fc-daygrid-body-balanced {
  width: 100% !important;
}

// Common component related css start
.calendar-type-module {
  .data-type {
    color: #212121;
    font-weight: 800;
  }
}

// Common component related css over

.calendar-type-module {

  .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
  .mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background: #276ef1 !important;
  }
}

.calendar-type-module {
  .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    border-color: #276ef1 !important;
  }
}

.calendar-type-module {

  .mat-radio-button.mat-primary .mat-radio-inner-circle,
  .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
    background-color: #276ef1 !important;
  }
}

.calendar-type-module {

  .md-drppicker td.active,
  .md-drppicker td.active:hover {
    background-color: #276ef1 !important;
    border-radius: 100% !important;
    opacity: 1;
  }
}

.calendar-type-module {

  .md-drppicker td,
  .md-drppicker th {
    border: initial !important;
  }
}

.multi-select-chip-menu.filter-menu {
  .md-drppicker {
    box-shadow: none;
    display: flex;
  }
}

.calendar-type-module {

  .md-drppicker .calendar th,
  .md-drppicker .calendar td {
    font-size: 15px;
    min-width: 33px;
  }
}

.calendar-type-module {
  span.mat-checkbox-label {
    color: #121212;
  }
}

.anchor-link {
  &.link-with-border {
    border: 1px solid #ccc;
    padding: 5px 6px;
    letter-spacing: 0.005em;
    margin-left: 1em;
  }
}

.anchor-link:hover {
  color: #276ef1 !important;
}

.my-task-list-related-cls {
  table {
    width: 100%;
  }
}


.multi-select-chip-menu ul {
  padding: 20px;
}

.my-task-list-related-cls {

  tr.mat-row,
  tr.mat-footer-row {
    height: 18px;
  }
}

.my-task-list-related-cls {
  .user-short-name {}

  .user--img .online--status {
    bottom: -3px;
    right: -7px;
  }
}

td.mat-cell.cdk-cell.cdk-column-expand.mat-column-expand {
  border-right: none;
  width: 1em;
  min-width: 1em !important;
}

.task--content-table .sub-task-row .mat-cell:last-child {
  margin-right: 36px;
  border-right: 0;
  padding-right: 0;
}

.custom-class .fc-daygrid-event-harness:nth-last-child(2) a {
  border-color: transparent !important;
  background-color: transparent !important;
}

.custom-class .fc-daygrid-day-events {
  pointer-events: none;
}

a.fc-daygrid-more-link.fc-more-link {
  pointer-events: none;
}

// 12/05/23

.custom-calendar {
  .fc-header-toolbar {
    padding: 16px 36px;

    .fc-toolbar-chunk {
      .fc-toolbar-title {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.0025em;
        color: #212121;
        width: 9em;
      }

      .fc-prev-button {
        background-color: transparent;
        border: none;
        color: #616161;

        &:focus {
          box-shadow: unset;
        }

        &:hover {
          color: #616161 !important;
        }
      }

      .fc-next-button {
        background-color: transparent;
        border: none;
        color: #616161;

        &:focus {
          box-shadow: unset;
        }

        &:hover {
          color: #616161 !important;
        }
      }

      .fc-button-group {
        .fc-button {
          background-color: transparent;
          color: #616161 !important;
        }

        .fc-button-primary {
          border-color: #616161 !important;
        }

        .fc-dayGridMonth-button {
          border-top-left-radius: 8px !important;
          border-bottom-left-radius: 8px !important;
        }

        .fc-custom-button {
          border-top-right-radius: 8px !important;
          border-bottom-right-radius: 8px !important;
        }

        .fc-button-active {
          background-color: #edf3fd !important;
          border-color: #276ef1 !important;
          color: #276ef1 !important;

          &:focus {
            outline: none !important;
            box-shadow: none !important;
          }
        }
      }
    }
  }
}

.custom-calendar {
  .fc-col-header {
    .fc-scrollgrid-sync-inner {
      a {
        padding: 10px 4px 0;
        font-family: Manrope;
        font-style: normal;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.005em;
        color: #222 !important;
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
      }
    }

    .fc-col-header-cell {
      background-color: #fafafa !important;
      height: 40px !important;
    }
  }
}

.custom-calendar .fc-scrollgrid {
  border: none !important;
}

.custom-calendar {
  .fc-col-header {
    background-color: #f9f8f8 !important;
    height: 40px !important;

    .fc-col-header-cell {
      background-color: #fafafa !important;
    }

    table {
      thead {
        height: 40px !important;
      }
    }
  }

  .fc-daygrid-day-top {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center !important;
    align-items: center !important;
    height: 34px !important;

    a {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.005em;
      color: #9e9e9e !important;
      text-decoration: none;
    }
  }

  .fc-list-event-dot {
    border-radius: unset !important;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #212121;
    background-color: rgb(0, 255, 0);
    padding: 3.5px;
    border-color: #ffffff !important;
    border: 3px solid;
    border-radius: 2px !important;
  }

  .content-event {
    img {
      height: 23px;
      width: 23px;
    }

    .fc-event-main {
      span {
        background-color: transparent !important;
        padding-left: 2px !important;
      }
    }
  }

  .cal-count-ctm {
    background-color: transparent !important;
    margin-left: 9px;
    line-height: 17px;
    box-shadow: 0px 0px 3px #000;
    padding: 0 5px;
    border-radius: 4px;
    font-size: 11px;
  }
}

.custom-calendar.fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-end),
.custom-calendar.fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-start) {
  border: 0;
  border-radius: 4px;
  height: 34px;
}

.custom-calendar.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.custom-calendar.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  border: 0;
  border-radius: 4px;
  height: 34px;
}

.custom-calendar {
  .content-event div>span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #212121;
    padding-left: 5px;
    display: flex;
    align-items: center;
  }

  .content-event .cal-event-title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    padding-left: 10px;
    width: 100%;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #212121;
  }
}

.custom-calendar .fc-daygrid-day-bottom {
  width: 100%;
  text-align: center;
  padding-top: 5px;
  position: absolute;
  bottom: 0px;
  font-size: 12px;
  color: #276ef1;
  font-weight: 600;
  cursor: pointer;
  margin-top: 0px !important;
}

.custom-calendar a.fc-daygrid-more-link.fc-more-link {
  text-align: center;
  float: unset;
  width: 100%;
  padding-top: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #276ef1;
  display: none;
  text-decoration: none;
}

.cdk-overlay-connected-position-bounding-box .chatmenuclsopenwidth {
  min-width: 450px;
  max-width: 450px;
  width: 100%;
}

.close-mdl-ctm {
  position: absolute;
  right: 6px;
  top: 63px;
  z-index: 999;

  button {
    width: 20px;
    height: 20px;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;

    &:hover {
      color: #000 !important;
    }
  }
}

.full-model-cal-ctm .fc-list-event .fc-list-event-time {
  display: none;
}

.full-model-cal-ctm .fc-list-table {
  border: 0;
}

.full-model-cal-ctm .fc-list-event-title {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #212121;
  border: 0;
}

.full-model-cal-ctm.fc-direction-ltr .fc-list-table .fc-list-event-graphic {
  padding-right: 0;
  border: 0;
  width: auto;
  width: 13%;
}

.full-model-cal-ctm.fc-direction-ltr .fc-list-table .fc-list-event-title {
  padding-left: 0;
}

.full-model-cal-ctm.fc-direction-ltr .fc-list-table .fc-list-event-graphic {
  border: 0;
}

.full-model-cal-ctm .fc-list-day-cushion .fc-list-day-text {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #212121;
}

.full-model-cal-ctm td.fc-list-event-graphic {
  display: none;
}

.full-model-cal-ctm td.fc-list-event-title.ng-star-inserted {
  padding: unset;
}

.full-model-cal-ctm span.d-flex.align-items-center {
  padding: 8px 14px;
  width: 95%;
  margin: 6px;
  border-radius: 8px;
  position: relative;
}

.full-model-cal-ctm .user_image {
  height: 16px;
  width: 16px;
  border-radius: 50px;
  margin-left: 10px;
  border: 2px solid #fff;
}

.full-model-cal-ctm .fc-list-event-dot {
  border-radius: unset !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: #212121;
  background-color: #0f0;
  padding: 3.5px;
  border-color: #fff !important;
  border: 3px solid;
  border-radius: 2px !important;
}

.full-model-cal-ctm .cal-event-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  padding-left: 10px;
}

.custom-calendar .content-event img.full-user-img {
  border-radius: 50px;
  margin-left: 10px;
  border: 2px solid #fff;
}

.full-model-cal-ctm img.user_image_sidebar {
  border-radius: 50px;
  margin-left: 10px;
  border: 2px solid #fff;
  position: absolute;
  right: 10px;
  top: 8px;
}

.full-model-cal-ctm .cal-count-ctm {
  background-color: transparent !important;
  margin-left: 9px;
  line-height: 17px;
  box-shadow: 0 0 3px #000;
  padding: 0 5px;
  border-radius: 4px;
  font-size: 11px;
}

.custom-calendar .content-event .shareicon-with-count {
  margin-left: 3px !important;
  margin-right: 3px !important;
  padding-top: 2px;
}

.task--content-table tr.mat-row.cdk-row.sub-task-row {
  display: none;
}

.task--content-table.task-subtask tr.mat-row.cdk-row.sub-task-row {
  display: table-row;
}

.list-table-hscroll {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bdbbbb;
  }
}

.math_ui_block-dashboard-rest-content {
  .math_ui_inside-tasks {
    .math_ui-onHover.dashboard-class {
      .list-table-hscroll {
        .my-task-list-related-cls {
          &::-webkit-scrollbar {
            width: 3px;
            height: 3px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba(187, 187, 187, 0.2);
          }
        }
      }
    }
  }
}

.list-table-hscroll .d-flex.flex-column.my-task-list-related-cls {
  width: 100%;
}

.task--content-table tr.mat-row.cdk-row.sub-task-row {
  display: none;
}

.task--content-table.task-subtask tr.mat-row.cdk-row.sub-task-row {
  display: table-row;
}

.list-table-hscroll {
  .title-click-class {}
}

@media (min-width: 1667px) and (max-width: 1920px) {
  body .list-table-hscroll.dynamic-width .custom--table-header .mat-header-row .mat-header-cell:first-child {
    min-width: 628px;
  }

  body .list-table-hscroll.dynamic-width .custom--table-header .mat-header-row .mat-header-cell {
    min-width: 260px;
    max-width: 260px;
  }

  body .list-table-hscroll.dynamic-customize .custom--table-header .mat-header-row .mat-header-cell:first-child {
    min-width: 613px;
  }

  body .list-table-hscroll.dynamic-customize .custom--table-header .mat-header-row .mat-header-cell {
    min-width: 211px;
    max-width: 211px;
  }

  // User Profile
  body .user-profile-ctm .list-table-hscroll.dynamic-width .custom--table-header .mat-header-row .mat-header-cell:first-child {
    min-width: 612px;
  }

  body .user-profile-ctm .list-table-hscroll.dynamic-width .custom--table-header .mat-header-row .mat-header-cell {
    min-width: 210px;
    max-width: 210px;
  }

  body .user-profile-ctm .list-table-hscroll.dynamic-customize .custom--table-header .mat-header-row .mat-header-cell:first-child {
    min-width: 611px;
  }

  body .user-profile-ctm .list-table-hscroll.dynamic-customize .custom--table-header .mat-header-row .mat-header-cell {
    min-width: 200px;
    max-width: 200px;
  }
}

// Task Layout Chages
.cal-multi-select-chip-menu-ctm {
  border: 1px solid #eeeeee !important;
  box-shadow: 0px 12px 12px rgba(33, 33, 33, 0.08) !important;
  border-radius: 12px !important;

  .mat-menu-content {
    padding: 20px;
  }

  .cal-title-ctm {
    font-weight: 700;
  }

  ul {
    padding: 8px 0;
    margin-bottom: 0;
  }

  .wrap-select-cal-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .selcted-date-ctm {
      span {
        font-weight: 700;
      }
    }

    .cal-modal-btns {
      button {
        padding: 0;
        margin: 0;
        font-weight: 600;
      }
    }
  }
}

.cdk-overlay-container {
  .ctm-default-dropdown {
    border: 1px solid #eeeeee !important;
    box-shadow: 0px 12px 12px rgba(33, 33, 33, 0.08) !important;
    border-radius: 12px !important;
    overflow: hidden;

    .mat-menu-content {
      ul {
        overflow: auto;
      }
    }
  }
}

// Task Filter CSS Changes
.top-task-filter-module-ctm {
  .mat-menu-content {
    .block-title {
      margin-bottom: 14px;
    }

    .mat-form-field-infix {
      padding: 0;
    }

    ul {
      margin-bottom: 15px !important;

      li {
        margin-bottom: 0;

        &:first-child {
          .remove-filter-icon {
            padding-top: 10px;
          }
        }

        .c-s-input.custom-input {
          height: 40px;
          margin-top: 12px;
        }

        .mat-form-field-wrapper {
          padding-bottom: 0 !important;
        }

        .mat-form-field {
          .mat-form-field-underline {
            display: none;
          }

          .mat-select {
            background: #fafafa;
            border: 1px solid #eeeeee;
            border-radius: 8px;
            padding: 9px;
            height: 40px;

            .mat-select-value {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              letter-spacing: 0.005em;
              color: #616161;
            }
          }
        }

        .ctm-select-filed {
          .mat-form-field-label {
            left: 9px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.005em;
            color: #616161;
          }
        }

        .flex-grow-1.ctm-select-filed {
          .mat-form-field {
            width: 100%;
          }
        }
      }
    }
  }
}

.ctm-shareNoteForm {
  height: 87vh;

  .share-note-modal-ctm {
    .user-short-name {
      width: 34px;
      height: 34px;
      min-height: 34px;
      min-width: 34px;
      font-size: 11px;
    }
  }
}

.share-note-modal-ctm {
  display: flex !important;
  align-items: center;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  img {
    margin-right: 8px;
  }

  &.no-visible {
    overflow: visible;

    img {
      margin-right: 0px;
    }
  }
}

.custom-dropdown-menu-ctm {
  .mat-menu-content {
    ul::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ul::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 10px;
    }

    /* Handle */
    ul::-webkit-scrollbar-thumb {
      background: #e0e0e0;
      border-radius: 10px;
    }

    /* Handle on hover */
    ul::-webkit-scrollbar-thumb:hover {
      background: #e0e0e0;
    }

    ul {
      margin: 10px 0 0 0 !important;

      li:last-child {
        padding-bottom: 10px;
      }

      li:first-child {
        margin-top: 0 !important;
      }
    }
  }
}

.ctm-dropdown-scroll .mat-menu-content ul::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */
.filter .mat-menu-content ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #eeeeee !important;
  border-radius: 10px !important;
}

/* Handle */
.filter .mat-menu-content ul::-webkit-scrollbar-thumb {
  background: #e0e0e0 !important;
  border-radius: 10px !important;
}

/* Handle on hover */
.filter.mat-menu-content ul::-webkit-scrollbar-thumb:hover {
  background: #e0e0e0;
}

.user-profile-task-list {
  .list-table-hscroll {
    padding-bottom: 10px;
    overflow-x: scroll;
  }
}

.user-profile-ctm .create-task-scroll-box {
  width: 100%;
  height: calc(100vh - 12em) !important;
}

.user-profile-ctm .list-table-hscroll {
  overflow: auto;
  height: 72vh;
}

@media screen and (min-width: 1920px) {
  .user-profile-ctm .list-table-hscroll {
    overflow: auto;
    height: 80vh;
  }

  .user-dms-container {
    height: calc(100vh - 76px) !important;
  }
}

//profile overview csss-----
.profile-overview-ctn {
  .profile-over-inner {
    height: calc(100vh - 180px);
    overflow: auto;
    overflow-x: hidden;
    background: #fafafa;
  }

  .over-view-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 14px 8px;

    button {
      background: #ffffff;
      border: 1px solid #eeeeee;
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.005em;
      color: #616161;
      padding: 6px 8px 6px 8px;

      &:hover {
        color: #616161 !important;
      }
    }

    .active {
      background-color: #edf3fd !important;
      border-color: #276ef1;
      color: #276ef1;

      &:hover {
        color: #276ef1 !important;
      }
    }

    .over-view-week-btn {
      border-radius: 0px 4px 4px 0px;
    }

    .over-view-month-btn {
      border-radius: 4px 0px 0px 4px;
    }

    .over-view-cal-btn {
      border: none;
      border-radius: inherit;
      padding: 3px 8px 3px 8px;
      background: transparent;
      min-width: auto;
      padding-left: 15px !important;

      .mat-button-focus-overlay {
        background-color: transparent !important;
      }
    }
  }

  .over-view-cal {
    padding: 26px 20px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 8px 8px 5px 5px;
  }

  .over-view-card-task {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .over-view-card-task-title {
    display: flex;
    flex-direction: column;

    p {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.0025em;
      color: #616161;
      margin-bottom: 4px !important;
    }

    h2 {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 30px;
      color: #212121;
      margin-bottom: 0;
    }
  }

  .task-list-com {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .complted-task-border {
    border-bottom: 3.5px solid #16b975;
  }

  .incomplted-task-border {
    border-bottom: 3.5px solid #f2793d;
  }

  .over-due-task-border {
    border-bottom: 3.5px solid #e54a4a;
  }

  .total-task-border {
    border-bottom: 3.5px solid #5d92e3;
  }

  .mat-card:not([class*="mat-elevation-z"]) {
    box-shadow: none !important;
  }

  .over-chart {
    .over-chart-title {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      display: flex;
      align-items: center;
      letter-spacing: 0.0025em;
      color: #212121;
      margin-bottom: 23px !important;
    }

    .over-view-chart-list {
      display: flex;
      align-items: center;
    }

    .over-chart-list-view {
      width: 85%;
      padding-top: 30px;

      .over-view-card-task {
        .over-view-chart-list {
          p {
            font-family: "Manrope";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.005em;
            color: #9e9e9e;
            margin-left: 10px;
          }

          span {
            b {
              font-family: "Manrope";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 22px;
              text-align: right;
              letter-spacing: 0.005em;
              color: #212121;
            }
          }
        }
      }
    }
  }

  .over-view-priority {
    position: relative;

    .donut-inner {
      position: absolute;
      bottom: 58px;
      left: 55px;

      span {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.005em;
        color: #212121;
      }
    }
  }
}

.over-view-performance {
  .over-chart-title {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0025em;
    color: #212121;
    margin-bottom: 12px !important;
  }

  .over-view-performance-flex {
    display: flex;
    align-items: center;

    p {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #9e9e9e;
      padding: 0px 10px;
    }

    span b {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      text-align: right;
      letter-spacing: 0.005em;
      color: #212121;
    }
  }
}

@media (min-width: 1800px) {
  .profile-overview-ctn .profile-over-inner {
    max-width: 100% !important;
  }

  body .weekClass .fc-scrollgrid.fc-scrollgrid-liquid thead {
    top: -1px !important;
  }
}

@media (max-width: 1480px) {
  .profile-overview-ctn .over-view-cal {
    padding: 26px 15px;
  }

  .profile-overview-ctn .over-view-card-task-title p {
    font-size: 12px;
  }
}

@media (max-width: 1380px) {
  .over-view-performance .over-view-performance-flex {
    margin: 10px 0 0 10px;
  }
}

@media (max-width: 1300px) {
  body .priority-canvas {
    canvas {
      height: 120px !important;
      width: 120px !important;
    }
  }

  .profile-overview-ctn .over-view-priority .donut-inner {
    bottom: 48px;
    left: 44px;
  }

  .profile-overview-ctn .over-view-canvas canvas {
    height: 103px !important;
  }
}

.over-view-canvas {
  .chart-format {
    height: 155px !important;
    margin-top: 2em;
  }
}

.priority-canvas {
  canvas {
    height: 142px !important;
    width: 142px !important;
  }
}

.profile-overview-ctn {
  .over-view-priority {
    width: 142px;
    height: 142px;
    margin: 0 auto;

    &.priority-canvas {
      &.priority-canvas-detail {
        .donut-inner {
          right: unset;
          width: 45px !important;
          left: 50% !important;
          transform: translate(-75%, 79%) !important;
          position: absolute !important;
          text-align: center;
        }
      }
    }
  }
}

.custom-calendar {
  .fc-timeGridWeek-view {
    .fc-scrollgrid-section-body {
      .fc-scroller-liquid-absolute {
        display: none !important;
      }
    }

    .fc-scrollgrid-section-liquid {
      display: none !important;
    }
  }
}

.custom-calendar .fc-timeGridWeek-view table thead tr th .fc-scroller-harness .fc-scroller table thead tr th:first-child {
  width: 0 !important;
}

.custom-calendar .fc-timeGridWeek-view table thead tr th .fc-scroller-harness .fc-scroller table thead tr th {
  border: none !important;
}

.custom-calendar .fc-timeGridWeek-view table thead tr th .fc-scroller-harness .fc-scroller table thead tr {
  border-bottom: 1px solid #ddd;
}

.custom-calendar .fc-timeGridWeek-view table tbody tr td .fc-scroller-harness .fc-scroller table tbody tr td:first-child {
  opacity: 0;
  visibility: hidden;
}

.custom-calendar .fc-timeGridWeek-view table tbody tr td .fc-scroller-harness .fc-scroller table tbody {
  height: 100vh;
}

.custom-calendar.weekClass {
  .fc-view-harness.fc-view-harness-active {
    height: calc(100vh - 303px) !important;
    overflow-y: auto !important;
  }

  .fc-scrollgrid.fc-scrollgrid-liquid thead {
    position: sticky !important;
    top: 0 !important;
    z-index: 9999 !important;
  }

  .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-weekCustom-button {
    background-color: #edf3fd !important;
    border-color: #276ef1 !important;
    color: #276ef1 !important;
    z-index: 1;

    &:focus {
      box-shadow: none !important;
    }
  }
}

// day calendar css ------

.dayClass {
  .fc-view-harness.fc-view-harness-active {
    height: calc(100vh - 303px) !important;
    overflow-y: auto !important;
  }

  .fc-timeGridDay-view {
    table {
      tbody {
        tr {
          td {
            border: none !important;

            &:first-child {
              border-bottom: 1px solid #ddd !important;
            }

            .fc-scroller-harness.fc-scroller-harness-liquid {
              .fc-scroller.fc-scroller-liquid-absolute {
                .fc-timegrid-body {
                  .fc-timegrid-cols {
                    table {
                      tbody {
                        tr {
                          td {
                            .fc-timegrid-col-frame {
                              .fc-timegrid-col-events {
                                .fc-timegrid-event-harness.fc-timegrid-event-harness-inset {
                                  a {
                                    border-left: 3px solid;
                                    border-top: 0px;
                                    border-bottom: 0px;
                                    border-right: 0px;
                                    box-shadow: none;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-custom-button {
    background-color: #edf3fd !important;
    border-color: #276ef1 !important;
    color: #276ef1 !important;
    z-index: 1;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.dayClass {
  .fc-timeGridDay-view {
    table {
      thead {
        tr {
          th {
            .fc-scroller-harness {
              .fc-scroller {
                table {
                  thead {
                    tr {
                      th {
                        text-align: left;

                        .fc-scrollgrid-sync-inner {
                          .fc-col-header-cell-cushion {
                            padding-left: 20px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.custom-calendar.timeGridDay .fc-view-harness.fc-view-harness-active,
.custom-calendar.timeGridWeek .fc-view-harness.fc-view-harness-active {
  height: calc(72vh) !important;
  overflow-y: auto !important;
}

// --------------Dashbord css---------------

.dashboard-content {
  padding-top: 70px;
  background-color: #e5e5e5;
  padding-left: 15px !important;
  padding-right: 15px !important;

  .dashboard-over-all-card {
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(33, 33, 33, 0.08);
    border-radius: 8px;
    padding: 25px;

    .dashboard-over-summary-list {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .total-task-title {
        display: flex;
        align-items: center;

        .dashboard-bg-image {
          width: 57px;
          height: 57px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        p {
          font-family: "Manrope";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 14px;
          letter-spacing: 0.0025em;
          color: #222;
          margin-bottom: 0;
          margin-left: 12px;
        }
      }

      .total-task-count {
        h2 {
          font-family: "Manrope";
          font-style: normal;
          font-weight: 800;
          font-size: 30px;
          line-height: 20px;
          text-align: right;
          color: #212121;
        }
      }
    }

    &.new-spc-dashboard-padding {
      padding: 15px 25px;
      border: 1px solid transparent;

      &:hover {
        box-shadow: 0px 1px 8px 1px #00366459;
        border: 1px solid #00366440;
      }
    }

    &.new-spc-dashboard-padding-two {
      padding: 7px 15px !important;
    }
  }

  .dashboard-title {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.0015em;
    color: #212121;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dashboard-today-sum {
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(33, 33, 33, 0.08);
    border-radius: 8px;
    padding: 9px;

    .dashboard-today-sum-inn {
      background: #edf3fd;
      border-radius: 8px;
      height: 220px;
      padding: 28px 18px;

      .dashboard-today-sum-bg {
        width: 57px;
        height: 57px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.dashboard-today-sum-bg-second {
        background: #d0f1e3 !important;
      }

      .dashboard-today-sum-title {
        p {
          font-family: "Manrope";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.0025em;
          color: #616161;
          margin: 16px 0 11px !important;
        }

        h2 {
          font-family: "Manrope";
          font-style: normal;
          font-weight: 800;
          font-size: 30px;
          line-height: 20px;
          color: #212121;
          margin-bottom: 0;
        }
      }
    }

    .pad {
      padding-right: 4px !important;
    }

    .pad-left {
      padding-left: 4px !important;
    }
  }
}

.dashboard-static-chart {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .static-chart-list {
    display: flex;
    align-items: center;

    .over-view-card-task {
      .over-view-chart-list {
        display: flex;
        align-items: center;

        p {
          font-family: "Manrope";
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.0025em;
          color: #616161;
          margin-right: 10px;
        }
      }
    }
  }

  .static-chart-list {
    .over-chart-list-view {
      display: flex;
      align-items: center;
      position: relative;
      top: -8px;
    }

    .static-check-btn {
      .c-radio-button .mat-checkbox-layout {
        border-radius: 8px 0px 0px 8px !important;

        &:last-child {
          border-radius: 0px 8px 8px 0px !important;
        }
      }
    }
  }
}

.dashboard-static-chart {
  .static-chart-list {
    .static-check-btn {
      .mat-checkbox-layout {
        border-radius: 8px 0px 0px 8px !important;
      }

      .week-check-box {
        .mat-checkbox-layout {
          border-radius: 0px 8px 8px 0px !important;
        }
      }

      .c-checkbox-button.mat-checkbox-checked .mat-checkbox-layout {
        border-width: 1px;
      }

      .c-checkbox-button.mat-checkbox-checked.mat-accent .mat-checkbox-background {
        background-color: transparent !important;
      }

      .c-checkbox-button.mat-checkbox-checked .mat-checkbox-layout {
        background-color: #edf3fd !important;
      }

      .mat-checkbox-inner-container {
        display: none;
      }

      .c-checkbox-button.mat-checkbox-checked .mat-checkbox-layout {
        padding-left: 15px;
      }

      .dash-drop {
        padding-left: 15px;

        .mat-form-field-underline {
          height: 0px !important;
        }

        .mat-form-field-infix {
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          padding: 5px 7px 8px;

          .mat-select-trigger {
            .mat-select-value {
              .mat-select-value-text {
                .mat-select-min-line {
                  font-family: "Manrope";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 10px;
                  line-height: 12px;
                  letter-spacing: 0.0025em;
                  color: #616161;
                }
              }
            }
          }

          .mat-form-field-wrapper {
            padding-bottom: 0px !important;
          }
        }
      }
    }
  }
}

.team-incompleted-task {
  height: 335px;

  .team-incompleted-task-inn {
    .team-incompleted-task-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background-color: #fafafa;
      border-radius: 4px;
      padding: 7px 10px;
      height: 36px;

      .team-name {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.005em;
        color: #9e9e9e;
      }
    }

    .user-list {

      height: 245px;
      overflow-y: auto;
      overflow-x: hidden;

      p {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #222;
      }

      .user-count {
        float: right;
        padding-right: 10px;
        padding-top: 5px;
      }

      .user-name {
        margin-left: 8px;
      }

      img {
        width: 32px;
        height: 32px;
        border-radius: 50px;
      }

      .user-list-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 0;
        border-bottom: 1px solid #eeeeee;

        .user-list-wrap-user {
          display: flex;
          align-items: center;
        }
      }

      &::-webkit-scrollbar-track {
        background-color: #fff !important;
        box-shadow: inset 0 0 5px #fff !important;
        border: 5px solid #fff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #fff;
        border: 5px solid #fff;
      }
    }
  }
}

.priority-task-block {
  height: 335px;

  canvas {
    margin: 10px auto !important;
  }

  .priority-task-inner {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 9px;
    flex-direction: column;
    align-content: center;

    .priority-task {
      display: flex;
      align-items: center;
      padding-left: 22px;

      p {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #9e9e9e;
        padding: 0px 10px;
      }

      span {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        text-align: right;
        letter-spacing: 0.005em;
        color: #212121;
      }
    }
  }
}

.dashboard-recent-project {
  .inner-detail-card {
    padding: 14px 24px 15px !important;

    .dash-mat-card-header {
      margin-bottom: 10px;
      width: 100%;

      .dash-mat-card-header-inn {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
          font-family: "Manrope";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 28px;
          letter-spacing: 0.0025em;
          color: #212121;
          cursor: pointer;
          max-width: 40%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      P {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #9e9e9e;

        span {
          color: #616161;
        }
      }
    }

    .day-count {
      width: 100%;

      .day-count-inner {
        display: flex;
        align-items: center;
        background: #fef0d3;
        border-radius: 4px;
        padding: 4px 8px 4px 8px;
        margin-bottom: 14px;
        width: fit-content;
        color: #c88e1b;

        p {
          font-family: "Manrope";
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 16px;
          text-align: right;
          letter-spacing: 0.005em;
          color: #c88e1b;
        }
      }
    }

    .dash-task-completed-ctn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      p {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #616161;
      }

      span {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        text-align: right;
        letter-spacing: 0.005em;
        color: #9e9e9e;
      }
    }

    .dash-user-list {
      .dash-user-list-block {
        margin-top: 10px;
        display: flex;

        .user-short-name-list {
          display: flex;

          .user-short-name {
            position: relative;
            height: 34px;
            width: 34px;
            min-height: 34px;
            min-width: 34px;
            border-radius: 50%;
            border: 1px solid #fff;
            display: inline-block;
            margin-right: 0px !important;
            align-items: center;
            display: flex;
          }
        }

        .more-user-list {
          display: flex;
          align-items: center;
          justify-content: center;

          a {
            font-family: "Manrope";
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            line-height: 13px;
            letter-spacing: 0.005em;
            color: #212121;
            height: 34px;
            width: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            filter: drop-shadow(0px 4px 12px rgba(33, 33, 33, 0.08));
            background-color: #fff;

            &:hover {
              color: #212121 !important;
            }
          }
        }

        .user--img {
          margin-right: 0 !important;
          border: none;

          img {
            border: 1px solid #fff;
          }
        }

        .user--img .online--status {
          background: #fab222;
          border: 1px solid #ffffff;
          display: inline-block;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          bottom: -7px;
          right: -3px;
          position: absolute;
        }
      }
    }
  }

  .slick-next {
    position: absolute;
    top: -30px;

    &::before {
      border: none;
      background-color: transparent;
    }
  }

  .slick-prev {
    position: absolute;
    top: -30px;
    z-index: 99999;
    right: 30px;
    left: unset !important;

    &::before {
      border: none;
      background-color: transparent;
    }
  }

  .slick-prev.slick-disabled {
    display: block !important;
  }

  .slick-next.slick-disabled {
    display: block !important;
  }
}

.dashboard-activity-ctm-scroll {
  .filter-notification-page-ctm .scrollWindow {}

  .filter-notification-page-ctm .mat-card .notification-list-card .card-title-ctm:before {
    z-index: 9;
  }

  .filter-notification-page-ctm .mat-card .notification-list-card .card-title-ctm:after {
    z-index: 1;
  }

  .filter-notification-page-ctm .mat-card {
    width: 93%;
  }

  .filter-notification-page-ctm.filter-notification-page-ctm-parent {
    padding: 0 !important;
    height: 100%;
  }
}

.dashboard-note-sec {
  .note-title {
    padding-bottom: 10px;

    p {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #616161;
    }
  }
}

.recent-discussion .mat-tabe-content .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content {
  overflow: auto;
  width: 100%;
}

.recent-discussion {
  padding: 16px !important;

  .user-name h2,
  .user-name .count-containner {
    width: calc(100% - 25px) !important;
  }

  .discussion-list-title {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.0025em;
    color: #212121;
    margin: 10px 10px;
  }
}

@media (min-width: 1800px) {
  .dashboard-over-all-card.recent-discussion.mb-4 mat-tab-group.mat-tab-group.mat-tabe-content {
    height: 46vh;
  }
}

// Dashboard Css with Media Query

// For Empty Dashboard Content

.dashboard-content.math_ui_block-dashboard-rest-content {
  .math_ui_inside-overall-summary {
    .dashboard-today-sum {
      height: 76%;

      .empty-block-task {
        height: 100%;
        margin: 0;
        text-align: center;

        .empty-block-task-inner {
          max-width: 224px;
          margin: 0 auto;
          padding: 0;

          .dashboard-bg-image-dashboard {
            width: 58px;
            height: 58px;
            border-radius: 50px;
            line-height: 53px;
            margin: 0 auto;
            text-align: center;
            display: inherit;

            img.calendar {
              width: 30px;
              height: 30px;
            }
          }

          button.add-task {
            img {
              padding-right: 5px;
            }

            display: flex;
            align-items: center;
            margin: 0 auto;
          }
        }
      }

      @media screen and (max-width: 1200px) {
        height: 74%;
      }

      &.new-spc-dashboard-today-sum {
        height: 75.5%;
      }
    }
  }

  .empty-block-task {
    height: 100% !important;
    overflow: hidden;
    background: #edf3fd;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

// Dashboard Css

body .dashboard-calendar .fc-view-harness.fc-view-harness-active {
  min-height: 265px;
}

.math_ui_inside-dash-activity-notes {
  .math_ui_inside-dashboard-activity {
    .filter-notification-page-ctm {
      .mat-card {
        border-bottom: 1px solid #eeeeee;
        border-top: 0px solid transparent;
        border-left: 0px solid transparent;
        border-right: 0px solid transparent;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;

        &:last-child {
          padding-bottom: 3em;
        }
      }

      .scrollWindow {
        height: 100% !important;
      }
    }

    .filter-notification-page-ctm .mat-card .notification-list-card .card-title-ctm:before,
    .filter-notification-page-ctm .mat-card .notification-list-card .card-title-ctm:after {
      content: none;
    }
  }
}

.span-class p span {
  display: inline-block;
}

// Dashboard Css with Media Query End

// Statistic Css With Media queries

.math_ui_block-dashboard-rest-content {
  .math_ui_inside-statistics {
    .square--mini {
      height: 10px;
      width: 10px;
    }

    .c-checkbox-button.mat-radio-button.mat-accent .mat-radio-inner-circle {
      background-color: #edf3fd !important;
    }

    .dashboard-static-chart {
      .static-chart-list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;

        .static-check-btn .dash-drop {
          @media screen and (max-width: 1040px) {
            padding-left: 10px;
            padding-top: 0px;
            padding-bottom: 0;
          }
        }

        @media screen and (max-width: 1040px) {
          justify-content: flex-start;
        }

        .static-check-btn .dash-drop .mat-form-field-infix {
          width: 90px;
        }
      }

      @media screen and (max-width: 1040px) {
        gap: 25px;
      }
    }

    .dashboard-title {
      @media screen and (max-width: 1040px) {
        margin-bottom: 0;
      }
    }

    .math_ui_inside-dashboard-calendar {
      .dashboard-calendar .fc .fc-toolbar.fc-header-toolbar {
        @media screen and (max-width: 1150px) {
          gap: 5px;
        }
      }
    }

    .priority-task-block {
      canvas {
        @media screen and (max-width: 1170px) {
          margin: 20px 0 !important;
        }
      }

      .priority-task-inner {
        flex-wrap: wrap;
      }
    }

    .team-incompleted-task .team-incompleted-task-inn .user-list .user-list-wrap .user-list-wrap-user {
      @media screen and (max-width: 1420px) {
        max-width: 65%;
      }
    }

    .user-list-wrap-count {
      @media screen and (max-width: 1420px) {
        max-width: 35%;
      }
    }

    .team-incompleted-task .team-incompleted-task-inn .user-list .user-name {
      word-break: break-word;
    }
  }

  .user-profile-task-list.math_block-user-profile-tasklist-tab.math_ui-onHover.dashboard-class .list-table-hscroll {
    height: 100%;
    padding-bottom: 0 !important;
  }
}

// Statistic Css With Media queries End

body .dashboard-calendar {
  .dash-custom-calendar {
    .fc-view-harness.fc-view-harness-active {
      height: 266px !important;
      overflow-y: hidden !important;
    }
  }
}

.dashboard-calendar {
  padding: 16px !important;

  .custom-calendar .fc-header-toolbar {
    padding: 16px 0px !important;
  }

  .custom-calendar .fc-view-harness.fc-view-harness-active {
    height: calc(100vh - 484px) !important;
    overflow-y: auto !important;
  }

  .fc-scrollgrid-sync-table {
    height: auto !important;
  }

  table {
    thead {
      tr {
        border-bottom: 1px solid #eeeeee !important;

        th {
          border: none !important;

          .fc-scroller-harness {
            .fc-scroller {
              table {
                background-color: transparent !important;

                tbody {
                  border-bottom: 1px solid #eeeeee !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.dashboard-calendar .custom-calendar .fc-col-header .fc-col-header-cell {
  background-color: transparent !important;
}

.dashboard-calendar .fc-theme-standard td,
.fc-theme-standard th {
  border: none !important;
  cursor: pointer;
}

.dashboard-calendar .fc-theme-standard td,
.fc-theme-standard tr {
  border: none !important;
  border: none !important;
}

.dashboard-calendar .fc .fc-daygrid-day.fc-day-today {
  background-color: #276ef1;
  border-radius: 50px;
  color: #fff;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: unset !important;
  border-radius: 50%;
  margin: auto;
}

.dashboard-calendar .fc .fc-daygrid-day.fc-day-today a {
  color: #fff !important;
}

.dashboard-calendar .fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
}

.dashboard-full-model-cal-ctm {
  .fc-list-day-cushion {
    background-color: #fff;
    border-top: 1px solid #eeeeee;
    z-index: 99;
  }

  .fc-list {
    border: none;
  }

  .fc-list-day-cushion .fc-list-day-text {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    color: #212121;
  }

  .fc-list-day>* {
    z-index: 9;
  }
}

.dashboard-note-sec {
  .notes-fixed-scroll {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.recent-discussion {
  .discussion-radio-status {
    .mat-radio-group {
      .mat-radio-button {
        .mat-radio-label {
          margin-right: 10px;
          margin-top: 15px;
        }
      }
    }
  }
}

.recent-discussion .mat-tabe-content .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content .user-chat-box-cover {
  padding: 0 15px;
}

.recent-discussion .custom-attachment-style span {
  margin-left: 5px;
  max-width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-over-all-card full-calendar.full-model-cal-ctm .fc-header-toolbar.fc-toolbar {
  display: none;
}

.priority-task-block {
  .dashboard-priority-count {
    position: relative;

    span {
      position: absolute;
      margin: 0 auto;
      font-family: Manrope;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.0015em;
      color: #616161;
      width: 200px;
    }
  }
}

.dash-custom-calendar {
  .fc-view-harness.fc-view-harness-active {
    table {
      tbody {
        .fc-scrollgrid-section-body {
          height: 250px !important;
        }

        .fc-col-header {
          background-color: transparent !important;
        }
      }
    }
  }
}

.weekClass {
  table {
    tbody {
      tr {
        th {
          .fc-scroller-harness {
            .fc-scroller {
              table {
                thead {
                  tr {
                    th {
                      &:first-child {
                        display: none !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.custom-calendar {
  .fc-toolbar-chunk {
    .fc-button-group {
      .fc-dayGridMonth-button {
        text-transform: capitalize;
      }
    }
  }
}

@media only screen and (max-width: 1779px) and (min-width: 1537px) {
  body .dashboard-calendar .fc-view-harness.fc-view-harness-active {}

  .recent-discussion .mat-tabe-content .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content {
    height: calc(100vh - 420px);
  }
}

@media only screen and (max-width: 1535px) and (min-width: 1200px) {
  body .dashboard-calendar .fc-view-harness.fc-view-harness-active {}

  .recent-discussion .mat-tabe-content .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content {
    height: calc(100vh - 420px);
  }
}

@media only screen and (max-width: 1505px) and (min-width: 1200px) {
  .dashboard-note-sec .notes-fixed-scroll {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .recent-discussion {
    .mat-tabe-content .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content .user-chat-box-cover .user-chat-box .user-name {
      padding-left: 7px;
    }
  }

  .dashboard-calendar .fc-direction-ltr .fc-toolbar>*> :not(:first-child) {
    margin-left: 0em;
  }
}

.full-model-calendar-ctm {

  .fc-header-toolbar.fc-toolbar {
    .fc-toolbar-chunk {
      .fc-toolbar-title {
        opacity: 0;
      }

      .fc-today-button {
        opacity: 0;
      }

      .fc-button-group {
        .fc-prev-button {
          opacity: 0;
        }

        .fc-next-button {
          opacity: 0;
        }
      }
    }
  }

  .fc-list-sticky .fc-list-day>* {
    z-index: 9;
  }
}

.task-calendar-ctm {
  top: 75px;
}

.custom-calendar .fc-h-event .fc-event-main {
  height: 100%;
  display: flex;
  align-items: center;
}

a.fc-event.fc-event-start.fc-event-past.fc-daygrid-event.fc-daygrid-block-event.fc-h-event:hover {
  box-shadow: 0px 1px 10px #ccc;
}

.math_block-user-profile-task-filter {
  .custom-task-filter {
    .user-short-name-other {
      align-items: center;
      justify-content: center;
      background: #f2793d;
      border: 1px solid #ffffff;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.005em;
      color: #fff;
      height: 34px;
      width: 34px;
      min-height: 34px;
      min-width: 34px;
      border-radius: 50%;
    }

    .image-text {
      background-color: greenyellow !important;
    }

    .select-all {
      display: flex;
      width: 100%;
      align-items: center;
    }
  }
}

.profile-overview-ctn .over-view-cal mat-card.mat-card {
  padding: unset;
}

.math_ui_block-dashboard-rest-content {
  .math_ui_inside-tasks {}

  .math_ui_inside-dashboard-activity,
  .math_ui_inside-dashboard-note-sec {
    max-height: 495px;
    min-height: 344px;
    overflow: hidden;
  }

  .math_ui_inside-dashboard-note-sec {
    .custom-height-blank-pinnotes {
      height: 350px;
    }
  }

  .grid.pin-data.math_ui_block-inside-grid-pin-data {
    mat-card {
      width: 100%;
      height: 100%;
      min-height: 238px;
    }
  }

  .math_ui_inside-discussion-project {
    .dashboard-over-all-card.dashboard-recent-project {}

    .recent-discussion.math_ui_inside-recent-discussion {
      max-height: 570px;
      min-height: 100%;
      overflow: auto;

      &.recent-discussion-dashboard-scroll-only {
        overflow: unset;

        .mat-tab-body-wrapper {
          max-height: 440px;
          overflow: auto;

          &::-webkit-scrollbar-track {
            background-color: #fff !important;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #fff !important;
          }
        }
      }

      .mat-tab-labels {
        justify-content: space-between !important;
      }
    }

    .discussion-block-nine {
      min-height: 506px;
    }
  }
}

.user-profile-task-list.math_block-user-profile-tasklist-tab.math_ui-onHover.dashboard-class {
  .create-task-scroll-box {
    height: 100%;

    .ng-scroll-content {
      height: 100%;
    }
  }
}

.dashboard-content.math_ui_block-dashboard-rest-content {
  .math_ui_inside-discussion-project {
    .mat-tabe-content {
      height: 100%;

      .mat-tab-body-wrapper {
        .mat-tab-body {
          .mat-tab-body-content {
            height: 100%;

            .user-chat-box-cover {
              height: auto;
              max-height: 100%;
            }
          }
        }
      }
    }
  }

  .math_ui_inside-statistics {
    .math_ui_inside-dashboard-calendar {
      .math_ui_inside-card-right {
        full-calendar.full-model-cal-ctm {}
      }

      full-calendar.full-model-cal-ctm.dashboard-full-model-cal-ctm.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
        min-height: 92%;
      }
    }
  }

  .math_ui_inside-dash-activity-notes {
    .note-card.mat-card {
      .mat-card-content p {
        word-wrap: break-word;
      }
    }
  }
}

body .dashboard-calendar .fc-view-harness.fc-view-harness-active {
  min-height: 100%;
  max-height: 100%;

  @media screen and (max-width: 1880px) {}
}

.math_ui_inside-dashboard-calendar {
  .math_ui_inside-card-right>div {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 66%;

    @media screen and (min-width: 1980px) {
      height: 67%;
    }

    @media screen and (max-width: 1880px) {
      height: 64%;
    }

    @media screen and (max-width: 1660px) {
      height: 60%;
    }

    @media screen and (max-width: 1460px) {
      height: 56%;
    }

    @media screen and (max-width: 1240px) {
      height: 52%;
    }

    @media screen and (max-width: 1040px) {
      height: 48%;
    }
  }
}

.math_ui_block-dashboard-rest-content {
  .grid.pin-data.math_ui_block-inside-grid-pin-data mat-card {
    width: 100%;
    height: 178px;
    min-height: unset;
    overflow: auto;
  }

  .empty-block-notes {
    img.set-notes-img {
      object-fit: cover;
      width: 186px;
    }
  }

  .no-discussion-block {
    position: relative;
    height: 350px;

    .no-discussion-part {
      display: block;
      text-align: center;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }
  }

  .priority-task-block {
    .priority-task-inner {
      .priority-task:first-child {}
    }
  }
}

.team-incompleted-task.dashboard-over-all-card.math_ui_inside-custom-parts .team-incompleted-task-inn .empty-block-task {
  background-color: transparent;

  .empty-block-task-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.dashboard-over-all-card.dashboard-recent-project>div {
  height: 100%;
}

.dashboard-content.math_ui_block-dashboard-rest-content .empty-block-task {
  &.recent-project-blank {
    background-color: transparent;

    .empty-block-task-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

app-recent-projects.dashboard-over-all-card.dashboard-recent-project {
  .card.mat-card.box-card {
    min-height: 208px;
  }
}

.dashboard-activity-ctm-scroll {
  .filter-notification-page-ctm.filter-notification-page-ctm-parent {
    .view-all {
      button {
        &:hover {
          color: #276ef1 !important;
        }
      }
    }
  }
}

app-recent-projects.dashboard-over-all-card.dashboard-recent-project mat-card-content.mat-card-content {
  .dash-user-list {
    position: relative;

    a.mat-menu-trigger.anchor-link {
      position: absolute;
      left: 97px;
      top: 9px;
    }
  }
}

.dashboard-static-chart {
  span.mat-radio-label-content {
    font-family: Manrope;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.005em;
    text-align: center;
  }

  label.mat-radio-label {
    padding: 3px 8px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  .week-check-box {
    label.mat-radio-label {
      padding: 3px 8px !important;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  .mat-select-arrow {
    margin: 6px 4px 0 4px;
  }
}

.mat-select-panel-wrap {
  .mat-select-panel.mat-primary {
    span.mat-option-text {
      font-family: Manrope;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.005em;
      text-align: left;
    }

    .mat-option {
      padding: 0 15px;
    }

    .mat-form-field-infix:focus {
      outline: none;
    }
  }
}

.static-check-btn {
  .mat-form-field.mat-focused {
    .mat-form-field-ripple {
      background-color: transparent;
    }
  }
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: transparent !important;
}

.mub-credit-confirm {
  button.mat-focus-indicator.confirm.mat-flat-button.mat-button-base.mat-primary.cdk-focused.cdk-program-focused:focus {
    background-color: #fff !important;
    background: #fff !important;

    span.mat-ripple.mat-button-ripple {
      background: transparent !important;
    }
  }
}

.mub-credit-confirm {
  button.mat-focus-indicator.confirm.mat-flat-button.mat-button-base.mat-primary.cdk-focused.cdk-program-focused {
    background-color: #fff !important;
    background: #fff !important;

    span.mat-ripple.mat-button-ripple {
      background: transparent !important;
    }
  }

  .mat-button-focus-overlay {
    background-color: transparent !important;
  }
}

// Subscription Styles

.mub-subscription-user {
  .mat-tab-label-container {
    .mat-tab-labels {
      gap: 20px;
    }

    .ctm-nots-tabs .mat-tab-list .mat-tab-label-content {
      margin-right: 20px;
    }
  }
}

// Common Quill Editor For chating
.common-quill-editor {
  .mat-chip-list-wrapper {
    margin: 0px !important;
  }

  .chat-box-attach {
    overflow: auto;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border: 5px solid #f5f1f5;
  }
}

// Organization Members Styles
.add-member-group {
  button.list-btn {
    &:hover {
      color: #212121 !important;
    }
  }
}

// Organization Service Header Styles  
.service-header-cls button.list-btn:hover{
  &:hover {
    color: #212121 !important;
  }
}


.service-export-custom-dropdown-menu .mat-menu-item:hover {
  color: #212121 !important;
}


.service-export-custom-dropdown-menu .mat-menu-item {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: .005em;
  color: #616161;
  padding: 9px 24px;
  height: auto;
  display: flex;
  align-items: center;
}

// Organization Client Styles
.mub-view-client {
  button.list-btn {
    &:hover {
      color: #212121 !important;
    }
  }
}

// Block Edit Attachments Styles
.right--block.mub-dms-comment-block-add-edit {
  .attachment-design {
    .card.attachment--card {
      .card-body {
        overflow-y: auto;
        height: calc(100vh - 20em);
        width: 100%;
        max-width: 100%;
        padding-bottom: 0 !important;
        border-bottom: 1px solid #eee;

        div.row {
          margin: 0px;

          .col-12 {
            padding: 0;
          }
        }

        &.card-body-inside {
          height: 156px !important;
        }
      }
    }
  }
}

// Task Filter Component
.tab-list-thrice-block {
  .main-content-coantainer {
    padding: 90px 20px;
  }

  .tab-list-thrice {
    .user-profile-task-list .list-table-hscroll {
      padding-bottom: 10px;
      position: fixed;
      width: 100%;
      bottom: 0;
      overflow: auto !important;
      height: 100%;
    }

    .tab-list-kanban-internal {

      // background-color: red;
      .kanban-internal {
        .kanban-internal-pipe-dropdown {
          .internal-pipe {
            display: flex;
            flex-direction: row;
            align-items: center;

            span.mat-select-min-line {
              font-size: 14px;
              font-weight: 700;
              line-height: 18px;
              letter-spacing: 0.005em;
              color: #222;
              text-align: left;
            }
          }
        }

        .internal-line {
          border-left: 1px solid #eeeeee;
          margin-left: 12px;
          padding-left: 12px;
          padding-top: 5px;
          padding-bottom: 5px;
          display: inline-flex;
          align-items: center;
          gap: 9px;

          .last-span-stage {
            padding-left: 15px;
            position: relative;

            &:before {
              content: "";
              position: absolute;
              left: 0;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: #222;
              top: 32%;
            }
          }

          .mat-form-field-subscript-wrapper {
            display: none;
          }
        }

        .settingss {
          .text-typo {
            font-size: 14px;
          }

          .globe {
            margin-right: 5px;
            width: 14px;
          }
        }

        .mat-form-field-infix {
          width: 270px;
          padding-bottom: 0;
        }
      }

      .kanban-internal-cards {
        .kanban-container {
          gap: 15px;
          padding-top: 0.5em;

          h6.process-title {
            padding-left: 10px;
            font-size: 14px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 0.0025em;
            text-align: left;
            color: #212121;
            margin: 0;
          }
        }
      }
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
      height: 0;
    }
  }
}

// Date Picker

.mui-add-member-date {
  .member-date {
    .mat-form-field-flex {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: nowrap;
      flex-direction: row;

      .mat-form-field-infix {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .datepicker-suffix {
        button.mat-focus-indicator.mat-icon-button.mat-button-base {
          padding-bottom: 14px;
        }
      }
    }
  }
}

// Task List Tree Start

// Recurring Task
.tab-list-recurring-subblocks {
  mat-table.mat-table.cdk-table.sub-task-row {
    mat-row.mat-row.cdk-row.cdk-drag.task_subtasks {
      .mat-cell {
        &:nth-child(1) {
          padding-right: 38px;
        }

        &:nth-child(5) {
          padding-left: 10px;
        }
      }
    }
  }
}

.text-dwindle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;

  &.d-590 {
    max-width: 590px !important;
  }

  &.d-480 {
    max-width: 480px !important;
  }

  &.d-330 {
    max-width: 330px !important;
  }

  &.d-240 {
    max-width: 240px !important;

    &.mx-w-1325 {
      @media screen and (max-width: 1325px) {
        max-width: 325px !important;
      }
    }
  }

  &.d-200 {
    max-width: 200px !important;
  }

  &.d-150 {
    max-width: 150px !important;
  }

  &.d-100 {
    max-width: 100px !important;
  }

  &.d-85 {
    max-width: 85px !important;
  }

  &.d-80 {
    max-width: 80px !important;
  }

  &.d-50 {
    max-width: 50px !important;
  }

  &.woi {
    display: inherit;
    margin-bottom: 0;
  }

  &.mx-100 {
    max-width: 100%;
  }

  &.wdb {
    display: block;
  }
}

tr.mat-row.cdk-row.cdk-drag.cdk-drag-disabled {
  td.mat-cell.cdk-cell.sub-table-recurr-td {
    &:nth-child(6) {
      padding-left: 10px !important;
    }
  }
}

.my-task-list-related-cls {
  .task--content-table {
    tr.mat-row.cdk-row.cdk-drag.cdk-drag-disabled {
      td.mat-cell.cdk-cell {
        &:nth-child(6) {
          padding-left: 10px !important;
        }
      }
    }
  }
}

td.sub-task-row {
  padding-top: 0;
  padding-bottom: 0;
}

tr.mat-row.cdk-row.empty-sub-task-row {
  display: none;
}

.sub-task-row.inner-sub-task-row td.mat-cell.cdk-cell.cdk-column-subtask.mat-column-subtask.inner-sub-task-row {
  padding-top: 0;
  padding-bottom: 0;
}

.task--content-table.task-subtask tr.mat-row.cdk-row.sub-task-row.empty-sub-task-row {
  display: none;
}

tr.mat-row.cdk-row.task-recurring-child {
  padding-top: 0;
  padding-bottom: 0;
  height: 0;
}

tr.mat-row.cdk-row.task-recurring-child tr.task_subtasks {
  &:nth-child(1) {}
}

td.mat-cell.cdk-cell.sub-task-row.cdk-column-subtask.mat-column-subtask.empty-sub-task-row {
  padding-top: 0;
  padding-bottom: 0;
}

// Task > Subtask
.task-recurrsubtask-subtask {
  .mat-cell {
    &:nth-child(1) {
      min-width: 475px;
    }
  }
}

.tab-list-task-subtaskss {}

.inner-sub-task-row {
  .mat-cell {}
}

// Task sub-task

.tab-list-task-subtasks {
  .task-subtask-parent {
    .mat-cell {
      min-width: 210px;

      &:nth-child(2) {}
    }
  }

  .sub-task-row.inner-sub-task-row {
    .inner-sub-task-row {
      .mat-table.sub-task-row {
        .mat-header-row {
          .mat-header-cell {
            &:nth-child(1) {
              min-width: 613px !important;
              width: 613px !important;
            }
          }
        }

        .task-subtask-subtask {
          .mat-cell {
            min-width: 210px;

            &:nth-child(1) {
              padding-right: 38px !important;
              padding-left: 0;
            }

            &.mat-column-task_name {
              padding-right: 10px !important;
              padding-left: 3.5em;
              min-width: 47.95em !important;
              width: 100%;
            }

            &:nth-child(2) {
              padding: 12px 10px;
            }
          }
        }
      }
    }
  }
}

.tab-list-task-subtaskss {

  // For last child Padding
  .tab-list-task-subtasks {
    .task--content-table {
      .sub-task-row.inner-sub-task-row {
        .inner-sub-task-row {
          .mat-table.sub-task-row {
            .task-subtask-subtask {
              .mat-cell {
                &:last-child {
                  padding-left: 12px 10px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Task List Tree End

// Organization Modules
.about-company-scroll {
  resize: none;
  min-height: 25px !important;
  padding-top: 0px !important;
  margin-bottom: -16px !important;

  &::-webkit-scrollbar-track {
    background-color: #fff !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #fafafa !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
    border: 31px solid #fafafa;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    z-index: 0;
    border-radius: 14px;
    overflow: auto;
    border: 8px solid #ccc;
  }
}

// Chat room header alignment small one
.chat-room-main-block {
  .only-image {}
}

// Edit Billing Details
.custom-modal-password {
  .custom-input-field.mat-form-field .mat-form-field-infix {
    margin-bottom: 0;
    margin-top: 15px;
  }
}

// Task List for Date
.task-list-date-only {
  .task-list-date-left--block {
    .task-list-date-create-task-block {
      .task-list-date-create-task-form {}
    }
  }
}

.task-list-date-task-due-date {
  .task-list-date-due-date {
    .mat-calendar-header {
      padding: 0 8px;
    }

    .mat-calendar-controls {
      margin: 0;
    }
  }
}

// Task List Status Like User Report Status

.design_status-task-filter-bg {
  .input-wo_outline {
    &:focus {
      outline: none;
    }
  }
}

.multi-select-chip-menu {
  &.status-select-chip-menu {
    min-width: 350px !important;
    max-width: 300px !important;
    max-height: inherit !important;
    border-radius: 8px !important;
    overflow: visible;

    .search-status {
      position: sticky;
      background-color: #fff;
      z-index: 123;
      top: 0;
      padding-top: 8px;
    }

    .mat-menu-content:not(:empty) {
      padding-top: 0;
    }

    .custom-input {
      height: 48px;
      width: 100%;
    }

    ul {
      padding: 0;
      overflow: auto;
      max-height: 260px;

      li {
        padding: 6px 24px;
        margin-bottom: 0;
      }

      &::-webkit-scrollbar-track {
        background-color: #f5f1f5 !important;
        z-index: 0;
        border-radius: 10px;
        overflow: auto;
        box-shadow: inset 0 0 5px #e0e0e0 !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        z-index: 0;
        border-radius: 10px;
        overflow: auto;
      }

      &::-webkit-scrollbar {
        width: 7px;
        background-color: #f5f1f5 !important;
        z-index: 0;
        border-radius: 10px;
        overflow: auto;
      }
    }
  }
}

.pointer-arrow {
  cursor: pointer;
}

// Kanban View Design Only
.kanban-view-design-only {
  .typo-main-heading {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #616161;
  }

  .typo-sub-heading {
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #9e9e9e;
  }

  .badge-custom {
    width: 43px;
    height: 24px;
    border-radius: 4px;
    padding: 4px 8px;
    color: #212121;
    text-align: center;
    line-height: 16px;
  }

  .typo-date {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #616161;
  }

  .typo-percent {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #616161;
  }
}

// For Only Pin Task
.For-only-pin-task-drag-drop {
  .For-only-pin-task-drag-drop-table {
    tr {
      td {
        padding-top: 0px !important;
        padding-bottom: 0 !important;

        &.mat-column-due_date {
          div {
            height: 48px;
            min-width: 211px;
            line-height: 44px;
          }
        }
      }
    }
  }
}

// Task Only Calendar Right Only
.task-calendar-only-right-side {
  .task-calendar-right-side-inside {
    display: flex;
    align-items: center;
  }
}

.custom-client-field {
  .forLabel-heading {
    .mat-form-field-label-wrapper {
      top: 0;
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 16px;
  }

  label#mat-form-field-label-3 {
    margin-top: 6px;
  }

  .mat-form-field-infix {
    padding-top: 16px !important;
  }

  &.mat-form-field {
    .mat-form-field-flex {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .mat-form-field-appearance-fill {
    .mat-form-field-flex {
      padding: 0 !important;
    }

    .mat-form-field-subscript-wrapper {
      padding: 0 !important;
    }
  }
}

// Add New Members styles
.mui-add-service-drop-li {
  .user-short-name {
    &.no-more-border {
      border: 0px solid transparent;
    }
  }
}

// task-list for editor
.task-list-date-create-task-form {
  .custom-height-for-editor {
    height: calc(100vh - 270px);
  }
}

.add-task-rght-block-tab .mat-tab-body {
  height: calc(100vh - 216px);
}

.add-task-rght-block-tab .msgContentBox {
  height: calc(100vh - 245px) !important;
}

// Project Report Listing View
.project-listing-view {
  .trim-info {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 25px;
    width: 100%;
    max-width: 300px;
  }
}

// kanban view design for add
.kanban-view-design-only {
  &.kanban-view-cards-for-only {
    .kanban-internal-cards {
      .custom-kanban-closed-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 19.4%;

        .add-task-kanban {
          width: 99%;
          margin-bottom: 15px;
        }

        .create-task-scroll-box {}
      }
    }

    .custom-class-for-closed-only {
      .mt-3 {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

.tab-list-kanban-cards-for-close-main-block {
  .mat-tab-body-content {
    &::-webkit-scrollbar-track {
      background-color: #f5f1f5 !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      box-shadow: inset 0 0 5px #e0e0e0 !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 4px solid #fff;
    }

    &::-webkit-scrollbar {
      width: 7px;
      background-color: #f5f1f5 !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
    }
  }
}

.custom-calendar .fc-header-toolbar.fc-toolbar .fc-toolbar-chunk .fc-button-group {
  position: absolute;
  right: 2em;
}

.custom-calendar.dayGridMonth .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button[title="Month"],
.custom-calendar.timeGridWeek .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button[title="Week"],
.custom-calendar.timeGridDay .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button[title="Day"] {
  border: 1px solid #276ef1 !important;
  color: #276ef1 !important;
}

.fc-button-group button.fc-monthCustom-button.fc-button.fc-button-primary {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.fc-timeGridDay-view tr.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid {
  display: none;
}

.fc-timeGridDay-view.fc-view.fc-timegrid td.fc-timegrid-axis.fc-scrollgrid-shrink {
  display: none;
}

.dot-calendar a.fc-event {
  border-color: transparent !important;
  pointer-events: none;
  background-color: transparent !important;
}

.blue-message {
  img {
    filter: invert(10%) sepia(8%) saturate(7496%) hue-rotate(172deg) brightness(85%) contrast(107%);
  }
}

app-dashboard-calendar button.fc-today-button {
  position: relative;
  left: -5em;
}

.rt-quick-reply {
  .radio-action-block {
    padding: 6px 24px;

    label {
      display: block;
      margin-bottom: 5px;
    }

    .mat-radio-button {
      margin-bottom: 5px;
    }

    .mat-radio-label {
      margin-right: 10px;
      display: flex;
      align-items: center;
    }

    .mat-radio-label-content {
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.005em;
      color: #616161;
    }

    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
      border-color: #276ef1;
    }

    .mat-radio-button.mat-accent .mat-radio-inner-circle {
      background-color: #276ef1;
    }
  }

  .rt-quick-reply-table {
    table {
      box-shadow: none;

      thead {
        tr {
          height: 40px;

          min-height: auto;
          border-bottom: 0;
        }

        th {
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.005em;
          min-width: 200px;
          max-width: 200px;
          color: #616161;
          border-right: 1px solid #eeeeee;
          padding: 10px;
          background: #f9f8f8;

          &:first-child {
            padding-left: 24px;
            max-width: 100px;
            min-width: 100px;
          }

          &.mat-header-cell {
            border-bottom-style: solid;
          }

          &.mat-header-cell {
            &:last-child {
              text-align: right !important;
              padding-right: 24px;
              max-width: 40px;
              min-width: 40px;
            }
          }

          &.mat-column-action {
            max-width: 100px;
          }
        }
      }

      tbody {
        tr {
          td {
            min-width: 200px;
            max-width: 200px;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.005em;
            color: #616161;
            padding: 7px 10px;
            border-bottom: 1px solid #eeeeee;
            border-right: 1px solid #eeeeee;

            &:first-child {
              padding-left: 24px;
              max-width: 100px;
              min-width: 100px;
            }

            &:last-child {
              text-align: right;
              padding-right: 24px;
              max-width: 40px;
              min-width: 40px;
            }

            &.mat-column-action {
              max-width: 100px;
            }
          }
        }
      }
    }
  }
}

.position-custom {
  li {
    position: relative;
  }

  .task-acion-bar {
    position: relative;
  }
}

.position-custom-relative {
  position: relative;
}

.custon-data-list {
  background: white;
  position: absolute !important;
  z-index: 10;
  width: 96%;
  padding: 10px 0;
  max-height: 200px;
  overflow-y: auto;
  top: 166px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #0000000f;
  border: 1px solid #e4eaec !important;

  .items {
    cursor: pointer;

    * {
      cursor: pointer;
    }

    padding: 5px 10px;
    border-radius: 4px;

    label {
      font-size: 12px;
      font-weight: bold !important;
      width: 100%;
    }

    span {
      font-size: 13px;
    }

    &:hover {
      background: #f2fbff;
    }
  }

  &.bottom-side {
    bottom: 38px;
    top: unset;
  }

  &.custon-data-list-1,
  &.custon-data-list-3 {
    top: 60px;
  }

  &.custon-data-list-4--subtask {
    top: 50px;
  }
}

.custon-data-list {
  &.quil-editor-quick-reply-dropdown {
    bottom: 50px !important;
    left: 0 !important;
  }
}

.list-block-checklist {
  .check-list-main {
    display: flex;
    align-items: center;
    width: 100%;

    .input-block {
      width: 95%;

      .input-block-field {
        width: 100% !important;
      }
    }
  }

  .calendar-date {
    min-width: 145px;
  }
}

.task-checklist-tab {
  .span-tab {
    padding: 14px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #eee;
    box-shadow: none;
  }
}

.checklist_showed {
  max-width: 200px;
  text-align: center;
  padding: 20px;
}

.checklist-menu {
  min-width: 130px !important;
  padding: 7px !important;
  line-height: 0px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
}

:root .checklist-niest-import-subtask2 {
  max-width: 530px;
}

.anchor-link {
  &.forDoneHover {
    font-size: 14px;
    padding-right: 12px;
    font-weight: 700;
    padding: 7px;
    border-radius: 8px;
    margin: 0 12px 12px 0;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;

    &:hover {
      border-color: #276ef1;
      transition: all 0.3s ease-in-out;
    }
  }
}

.new-tooltip-cls-add2 {
  .tooltip-inner {
    padding: 2px 9px !important;
  }

  bs-tooltip-container {
    min-width: 129px;
  }

  .tooltip-block {
    gap: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;

    &:last-child {
      padding-bottom: 0;
    }

    img.img-avatar {
      height: 29px;
      width: 29px;
      border-radius: 50%;
      object-fit: cover;
      border: 1px solid #000;
    }

    .name-text {
      font-size: 14px;
    }
  }

  .bs-tooltip-top .tooltip-arrow::before {
    bottom: 0px !important;
    top: unset !important;
  }
}

// Task Recycle Bin
.math_block-user-profile-tasklist-tab-recycle {

  .mat-column-taskName,
  .mat-column-assignee,
  .mat-column-report {
    overflow: visible;

    .hoverimg {
      left: -46px;
      top: 38px;
    }
  }
}

.overflow-vis {
  padding-bottom: 30px;
}

.file-folder-view {
  .math_ui-onHover-sub-block {
    li {
      background-color: #212121;

      .online-status-sidebar {
        background: #fab222;
        border: 1px solid #ffffff;
        display: inline-block;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        position: absolute;
        bottom: 0px !important;
        right: -1px !important;

        &.default-active-status {
          background: green;
        }

        &.default-red-active-status {
          background: red;
        }
      }

      .user-short-name {
        margin-right: 0;
        margin-left: 0 !important;
      }
    }
  }
}

.margin-right-none1 {
  margin-right: 0 !important;
}

// List View Table
.rt-document-content-block {
  .list-view-table {
    .online-status-sidebar {
      width: 8px;
      height: 8px;
      position: absolute;
      bottom: 5px;
      right: 0px;
      border-radius: 50%;
      border: 1px solid #ccc;
    }
  }
}

.main-span {
  position: relative;
  display: inline-block;

  .img-wh {
    height: 34px !important;
    width: 34px !important;
  }
}

.main-app-member {
  position: absolute;
  left: -46px;
  top: 29px;

  &.app-one {
    top: 35px;
  }

  &.app-two {
    top: 30px;
  }

  &.app-three {
    top: 38px;
  }

  &.app-four {
    left: 4px;
    top: 38px;

    &.math_ui-onHover-sub-block li:before {
      content: "";
      left: 7px !important;
    }
  }

  &.app-five {
    top: 37px;

    &.math_ui-onHover-sub-block li:before {
      content: "";
      left: 54px !important;
    }
  }

  &.app-six {
    left: 5px;
    top: 38px;

    &.math_ui-onHover-sub-block li:before {
      content: "";
      left: 7px !important;
    }

    ul {
      margin: 0 !important;
    }
  }

  &.app-seven {
    left: 32px;
    top: 48px;

    &.math_ui-onHover-sub-block li:before {
      content: "";
      left: 7px !important;
    }

    ul {
      margin: 0 !important;
    }
  }

  &.app-eight {
    left: 2px;
    top: 37px;

    &.math_ui-onHover-sub-block li:before {
      content: "";
      left: 7px !important;
    }

    ul {
      margin: 0 !important;
    }
  }

  &.app-nine {
    top: 38px;
  }

  &.app-ten {
    top: 29px;
    left: -51px;

    ul {
      margin: 0 !important;
    }
  }
}

.math_ui_inside-dashboard-activity {
  .user--img-one {
    .app-five {
      span.online--status {
        bottom: 0 !important;
        right: -1px !important;
      }

      .math_ui-onHover-sub-block {
        min-width: 140px;
      }

      top: 37px;
      left: 0;

      &.math_ui-onHover-sub-block li:before {
        content: "";
        left: 7px !important;
      }
    }
  }
}

.user--img-one {
  .app-five {
    span.online--status {
      bottom: 0 !important;
      right: -1px !important;
    }

    .math_ui-onHover-sub-block {
      min-width: 140px;
    }
  }
}

.user--img-three {
  min-width: 28px;
  width: 28px;
  margin-right: 0px !important;

  span.online--status {
    top: 17px;
    left: 18px;
  }
}

.user--img-four {
  height: 33px;
  width: 30px;
}

.user--img-five {
  span.online--status {
    top: 26px;
    left: 23px;
  }

  .app-eleven {
    top: 38px;

    .assignee_status-quo {
      line-height: 22px;

      span.online--status {
        top: auto;
        left: 19px;
        bottom: -1px !important;
      }
    }
  }

  .user-short-name {
    height: 34px !important;
    width: 34px !important;
  }
}

.user--img-six {
  span.online--status {
    bottom: -12px;
    right: -8px;
  }

  .app-tweleve {
    top: 37px;

    .assignee_status-quo {
      line-height: 22px;

      .online--status {
        top: 17px;
        left: 18px;
      }
    }
  }
}

.user--img-seven {
  span.online--status {
    bottom: -12px;
    right: -8px;
  }

  .app-thirteen {
    top: 37px;

    .assignee_status-quo {
      line-height: 22px;

      .online--status {
        top: 17px;
        left: 18px;
      }
    }
  }
}

.user--img-eight {
  span.online--status {
    bottom: -13px;
    right: -8px;
  }

  .app-fourteen {
    top: 37px;

    .assignee_status-quo {
      line-height: 22px;

      .online--status {
        top: 17px;
        left: 18px;
      }
    }
  }
}

.user--img-nine {
  span.online--status {
    bottom: -13px;
    right: -8px;
  }

  .app-fifteen {
    top: 37px;

    .assignee_status-quo {
      line-height: 22px;

      .online--status {
        top: 17px;
        left: 18px;
      }
    }
  }
}

.user--img-ten {
  span.online--status {
    bottom: -13px;
    right: -8px;
  }

  .app-sixteen {
    top: 37px;

    .assignee_status-quo {
      line-height: 22px;

      .online--status {
        top: 17px;
        left: 18px;
      }
    }
  }
}

.user--img-eleven {
  .user-short-name {
    margin: 0 !important;
  }

  span.online--status {
    bottom: -8px;
    right: -8px;
  }

  .app-seventeeen {
    left: -28px;
    top: 47px;

    .assignee_status-quo {
      line-height: 22px;

      .online--status {
        top: 17px;
        left: 18px;
      }
    }

    &.math_ui-onHover-sub-block li:before {
      content: "";
      left: 35% !important;
    }
  }
}

.user--img-two {
  width: 45px;
  min-height: 34px;
  min-width: 34px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  margin-right: 10px;
  align-items: center;

  .user-short-name li {
    margin-left: 0;
  }

  .online--status {
    bottom: 2px;
    right: -17px;
  }

  img.user-img-personalized {
    min-width: 32px;
    min-height: 32px;
  }

  .user-short-name {
    min-width: 34px;
    margin-left: 0;
    margin-right: 0;
  }
}

.mat-tooltip {
  background: #000 !important;
}

.user-margin-right {
  &.u-w-i-d .user-short-name {
    margin-right: 0;
  }
}

.rt-staff-member-onlyinside-table {
  .math_ui-onHover-sub-block {
    .tip_lastseen {
      display: flex;
      align-items: center;
      gap: 5px;

      p {
        font-size: 8px !important;
        color: #fff;
        line-height: 8px;
      }
    }
  }
}

.ctm-user-list-view .file-folder-view ul li .user-list-view-wrap {
  gap: 20px;
}

// For Last Seen

.math_ui-onHover-sub-block {
  .tip_lastseen {
    display: flex;
    align-items: center;
    gap: 5px;

    p {
      font-size: 8px !important;
      color: #fff !important;
      line-height: 8px !important;
    }
  }
}

.rt-createTaskFormTaskList-left {
  .app-left-field-date {
    border: 1px solid transparent;

    &:hover {
      border-color: #276ef1a6;
    }

    span {
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.005em;
      line-height: 3;
      padding: 5px 10px;
      display: inline-block;
    }

    .span-two {
      color: rgb(199, 198, 198);
      display: block;
    }
  }
}

.custom-add-client {
  .app-left-field-date {
    border: 1px solid transparent;

    &:hover {
      border-color: #276ef1a6;
    }

    span {
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.005em;
      line-height: 3;
      padding: 5px 10px;
      display: inline-block;
    }

    .span-two {
      color: #9e9e9e;
      display: block;
    }
  }
}

// New Folder DMS
.new-folder-dropdown {
  max-width: 96%;
  margin: 0 auto;

  .mat-chip-list-wrapper {
    max-height: 34px;
    overflow: hidden;
  }

  .anchor-link {
    min-width: 65px;
  }
}

.new-folder-mat-menu {
  width: 500px;
  max-width: 100% !important;
}

// Only for height
.only-for-height {
  .only-for-height-chart {
    mat-expansion-panel-header {
      &.mat-expansion-panel-header {
        @media screen and (min-width: 2000px) {
          min-height: 66px;
        }
      }
    }
  }
}

mat-calendar.mat-calendar.calendar-design {
  .mat-calendar-controls {
    margin-top: 0;
  }

  .mat-calendar-header {
    padding-top: 0px;
  }

  margin-top: -8px !important;
  margin: auto;
  width: 60%;
}

.grid-col-space {
  display: grid;
  grid-template-columns: auto auto;
}

.cal-modal-btns .mat-button-base.mat-primary.mat-button-disabled:hover {
  color: #00000042 !important;
}

.cal-date-cal mat-calendar.mat-calendar {
  width: 60%;
}

.dms-half-width .ql-editor.ql-blank {
  max-width: 93%;
}

.lazy-loaded-checkbox span.mat-checkbox-frame {
  border-color: #bdbdbd;
}



.time-height-cls .mat-form-field .mat-form-field-wrapper {
  height: 70px;
}

.time-height-cls .mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0px;
  margin-top: -0.25em;
  position: relative;
  height: 70px;
}

.frequency-cls .mat-select .mat-select-trigger .mat-select-value .mat-select-placeholder, 
.frequency-cls .mat-select .mat-select-trigger .mat-select-value .mat-select-value-text{
  font-size: 14px;
}

.border-divion-cls{
  border-right: 2px solid #e0e0e0;
}

.performace-ontrack-cls{
  color: #fab222;
}

.performace-delay-cls{
  color: #dc3535;
}

.performace-before-time-cls{
  color: #16b975;
}

.filter-filed-list>li {
  .ctm-select-filed {
    display: flex;
    align-items: center;
    column-gap: 10px !important;
    margin-bottom: 10px;
    align-items: start !important;
  }
}