@import "./custom-variable";

.c-d-container {
  padding: 60px 20px;

  @media screen and (max-width: 1800px) {
    padding-bottom: 0;
  }
}

.s-list {
  > li {
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;
    .sub-block-title {
      color: #212121;
      margin-bottom: 4px;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}

.c-d-board {
  padding: 6px 0;
  background-color: $bodyBg-color;
}

.c-s-i-list {
  position: relative;
  top: 35px;
  z-index: 10;
}

.u-w-i-d {
  > img {
    height: 34px;
    width: 34px;
    min-height: 34px;
    min-width: 34px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
  }
  h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #212121;
    margin-bottom: 4px;
  }
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #616161;
    margin-bottom: 0;
  }
  .user-short-name {
    margin-right: 15px;
  }
}

.a-r-list {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}
app-add-client > form {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.footer-action-block {
  position: sticky;
  bottom: 0;
  background: #fff;
  padding: 20px 0;
}
.custom-add-client {
  .mat-dialog-container {
    padding: 10px 5px 10px 10px;
    mat-expansion-panel-header,
    mat-expansion-panel-header:hover {
      background: unset !important;
    }
  }
}
.c-f-block {
  max-height: 476px;
  overflow-y: auto;
}
.u-t-g {
  > img {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    object-fit: cover;
  }
}
.g-m-list {
  > li {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .progress-circle-bar-block {
    round-progress {
      &,
      & svg {
        height: 198px !important;
        width: 198px !important;
      }
    }
  }
}
.org-setting__page {
  padding-top: 60px;
  .c-d-board {
    position: sticky;
    top: 60px;
    z-index: 90;
  }
  .mat-drawer-content {
    min-height: calc(100vh - 162px);
  }
}
.e-msg {
  font-size: 75%;
}

.add-btn-group {
  margin-bottom: -40px;
  position: relative;
  z-index: 10;
  width: fit-content;
  margin-left: auto;
}

.chip-multi-select {
  .custom-input-field {
    min-width: 310px;
  }
}
.select-all {
  padding: 10px 16px;
}

.right-serach-section {
  position: relative;
  border: 1px solid $box-border-color;

  &.right-table-search-section {
    margin-left: 0;
    margin-right: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 8px;
    left: 40px;
    width: 1px;
    height: 20px;
    background-color: $search-placeholder-color;
  }

  img {
    position: absolute;
    top: 9px;
    left: 10px;
    width: 18px;
    height: 18px;
  }

  input {
    max-width: 462px;
    height: 34px;
    padding: 12px 15px 12px 55px;
    color: #000 !important;
    font-size: $font-size-14 !important;
    font-family: "Poppins", sans-serif !important;
    border: none;
    border-radius: 5px;

    &::placeholder {
      color: $search-placeholder-color !important;
      font-size: $font-size-14 !important;
      font-family: "Poppins", sans-serif !important;
    }
  }
}

// Company Details Modules New Issues Fix

.company-details-only {
  .custom-margin {
    display: block;
    margin: 0 auto;
  }
  .custom-badge {
    display: inline-flex;
    justify-content: center;
  }
  .clientonly-height {
    .mat-card {
      min-height: 217px;
    }
  }
  .circle-cog {
    background-color: #fff;
    width: 38px;
    height: 38px;
    border: 1px solid #eee;
    border-radius: 50%;
    text-align: center;
    line-height: 33px;
    display: inline-block;
    img {
      width: 17px;
      height: 17px;
    }
  }
  .company-icons {
    min-width: 80px;
    height: 80px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
    border: 10px solid #fff !important;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fafafa !important;
    z-index: 0;
    border-radius: 10px;
    overflow: auto;
    border: 31px solid #fafafa;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    z-index: 0;
    border-radius: 14px;
    overflow: auto;
    border: 6px solid #ccc;
  }
}

// Role Management Modules

.role-management-only {
  .table-responsive {
    &.custom-table-role-management {
      &::-webkit-scrollbar-track {
        background-color: #fff !important;
        z-index: 0;
        border-radius: 10px;
        overflow: auto;
      }
      &::-webkit-scrollbar {
        width: 4px;
        background-color: #fafafa !important;
        z-index: 0;
        border-radius: 10px;
        overflow: auto;
        border: 31px solid #fafafa;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        z-index: 0;
        border-radius: 14px;
        overflow: auto;
        border: 6px solid #fff;
      }
    }
  }
  .table {
    &.custom-table {
      &.header-bg {
        th {
          border-right: 1px solid #eee;
        }
      }
      tbody {
        tr {
          th {
            border-right: 0px solid transparent !important;
          }
          td {
            border-right: 1px solid #eee;
          }
        }
      }
    }
    .inner-role-table {
      tbody {
        td {
          border-right: 0px solid transparent !important;
        }
      }
    }
  }
}

// Add Company Module
.add-company-only {
  .phone-number-list {
    button {
      &:hover {
        color: #000000de !important;
      }
    }
  }
  .mat-form-field-wrapper {
    padding-top: 12px;
  }
}

// View Status Modules
.view-status-only {
  .custom-table-status {
    &::-webkit-scrollbar-track {
      background-color: #fff !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
    }
    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fafafa !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 31px solid #fafafa;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      z-index: 0;
      border-radius: 14px;
      overflow: auto;
      border: 6px solid #fff;
    }
  }
}

// Staff Member Modules
.table-responsive {
  &.staff-member-onlyinside-table {
    &::-webkit-scrollbar-track {
      background-color: #fff !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
    }
    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fafafa !important;
      z-index: 0;
      border-radius: 10px;
      overflow: auto;
      border: 31px solid #fafafa;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      z-index: 0;
      border-radius: 14px;
      overflow: auto;
      border: 6px solid #fff;
    }
  }
}
.view-member-block {
  .view-member-tabs {
    input.form-control.c-s-input.custom-input {
      @media screen and (max-width: 1340px) {
        width: 90%;
        margin-left: auto;
        max-width: 100% !important;
      }
      @media screen and (max-width: 1280px) {
        width: 70%;
      }
    }
  }
  .mat-tab-body-wrapper {
    position: unset;
    overflow: unset;
    display: unset;
  }
  .mat-tab-body {
    position: unset;
  }
}
.update-client-details {
  &.custom-modal .mat-dialog-container {
    padding: 30px 30px 0 30px;
  }
}
