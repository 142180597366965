.task-search .c-s-input {
  border: 1px solid #aaa !important;
  background-color: #fafafa;
}

.mat-tab-label-active .mat-tab-label-content {
  color: #222;
}

.custom--table-header th.mat-header-cell {
  border-top: 1px solid #0000001f;
}

.mat-column-task_name span.mat-checkbox-inner-container {
  height: 18px;
  width: 18px;
}

.mat-column-status span.mat-menu-trigger {
  font-weight: 900;
}

ul.ctm-calendar-btn input.form-control.c-s-input {
  border: 1px solid #aaa !important;
  background-color: #fafafa;
  height: 40px;
  font-size: 14px;
  min-width: 400px;
}

app-task-checklist-template mat-expansion-panel.mat-expansion-panel.checklist-expansion-panel {
  margin: unset;
}

app-task-checklist-template .mat-expansion-panel-header.mat-expanded {
  height: unset;
}

.checklist-box .anchor-link:hover {
  border-color: var(--bs-btn-active-border-color);
}

.checklist-box .anchor-link {
  margin-top: 0.8em !important;
  margin-bottom: 0.8em !important;
}

.checklist-lists {
  padding-left: 50px;
  border: 1px solid #eeeeee;
  margin-bottom: unset;
}

.add-notes-class .ql-editor {
  font-size: 14px;
}

app-add-notes .color-indicator img {
  height: 1.7em;
  width: auto;
}

span.task--title.text-dwindle {
  max-width: 20em !important;
}

app-calendar-view td.fc-day.fc-daygrid-day {
  height: 19em;
}

.mat-column-progress .progress--slider.mat-slider-horizontal .mat-slider-wrapper {
  height: 4px;
  top: unset;
}

.mat-column-progress .mat-slider-thumb {
  width: 15px;
  height: 15px;
  bottom: -8px;
}

.mat-column-progress div {
  width: 90%;
}

th.mat-calendar-table-header-divider {
  display: none;
}

.reminder-block input[type="time"] {
  font-size: 24px;
}

.mat-column-progress mat-slider.mat-slider.mat-slider-horizontal {
  height: inherit;
}

:root .custom--status-menu .mat-menu-content .mat-menu-item .inner-button {
  padding: 8px 14px;
}

:root .priority-menu .mat-menu-content .mat-menu-item {
  padding: 8px 14px;
}

.custom-project-popup input.form-control.c-s-input.custom-input {
  height: 32px;
  margin-top: unset;
}

.custom-project-popup {
  padding: 10px 15px;
}

.custom-assignee.multi-select-chip-menu ul>li {
  margin-bottom: 4px;
}

.custom-project-popup .no-data-available {
  background-color: #f8f8f8;
  padding: 0.5em;
  border-radius: 8px;
}

.status-set {
  width: 280px;
}

.mat-column-status span.text-capitalize {
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.w-65 {
    width: 65%;
  }
}

.mat-column-assignee .user--img .online--status,
.mat-column-reporter .user--img .online--status {
  bottom: -1px;
  right: 2px;
}

.dynamicwidth-popup.mat-menu-panel {
  max-width: initial;
}

.empty-label-set {
  margin-left: 3.4em;
}

.scroll-fix {
  height: 200px;
  overflow: auto;
}

.mat-column-status .custom-badge.status-badge {
  max-width: 165px;
}

.textarea-uploadation-part {
  padding-top: 1em;
  padding-bottom: 1em;
}

.custom-modal-md.onlycustom-meber-cls {
  max-width: 50% !important;
}

.example-form-field .mat-form-field-flex {
  border: 1px solid #aaa !important;
  background-color: #fafafa;
  border-radius: 8px;
  padding-left: 1em;
  display: flex;
  align-items: center;
  height: 40px;
}

.example-form-field .mat-form-field-infix {
  border-top: unset;
  padding: 1em 0em !important;
  top: unset !important;
}

.discussion-radio-status label.mat-radio-label {
  padding: 6px 15px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.discussion-radio-status .mat-radio-container {
  display: none;
}

.discussion-radio-status .mat-radio-button .mat-radio-label-content {
  padding: unset;
}

.discussion-radio-status .mat-radio-checked label.mat-radio-label {
  border-color: var(--primary-color);
}

.discussion-radio-status .mat-radio-checked span.mat-radio-label-content {
  color: var(--primary-color);
}

.open-preview-cls .mat-dialog-container {
  padding: 1em;
}

.open-preview-cls img {
  width: 30px;
  height: 30px;
}

.open-preview-cls .image-preview-content .preview-img-open {
  width: 100%;
  height: auto;
}

.open-preview-cls {
  max-width: 65% !important;
}

.open-preview-cls .modal-body {
  padding: 0 0.5em;
}

.heading-search-containner .example-form-field span.mat-form-field-label-wrapper {
  display: flex;
  align-items: center;
}

.heading-search-containner .example-form-field span.mat-form-field-label-wrapper .mat-form-field-label {
  position: relative;
  top: unset;
  color: #444;
}

.timesheet-manually-cls.rt-timesheet-manually-cls .list-inline-item:not(:last-child) {
  margin-right: 0;
}

.timesheet-manually-cls li.list-inline-item a.anchor-link {
  font-size: 14px;
  border: 1px solid #ccc;
  padding: 7px 32px;
  line-height: 22px;
  letter-spacing: 0.005em;
  margin-left: 1em;
}

.second-unstyled-list li.list-inline-item ul.list-unstyled.list-inline.custom-tab-list.tab-sm li.list-inline-item button {
  font-weight: 600;
  padding: 6px 16px;
}

.text-size-change {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 93%;
}

.mat-tab-label-active {
  color: #222;
}

li.d-flex.align-items-center.my-1.set-list {
  border: 1px solid transparent;
  border-bottom: 1px solid #eeeeee;
  padding: 9px 15px;
  background: #fafafa;
  border-radius: 12px;
}

.sub-font {
  font-size: 14px;
}

ul.list-unstyled.attachment-doc-list {
  margin-bottom: 0.5em;
}

li.d-flex.align-items-center.my-1.set-list:hover {
  box-shadow: 0 0 10px #00000021;
  border-color: #67acf0;
}

.set-list mat-error {
  display: none;
}

.footer-action-button-right button.mat-focus-indicator.mat-icon-button.mat-button-base.mat-primary,
.footer-action-button-left button.mat-focus-indicator.mat-icon-button.mat-button-base.mat-primary {
  border-radius: 8px;
  height: auto;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.5em;
  line-height: 0;
  padding-right: 0.2em;
}

.footer-action-button-left button.mat-focus-indicator.mat-icon-button.mat-button-base.mat-primary {
  padding: 0.5em 0.8em 0.5em 0.5em;
}

.footer-action-button-right button.mat-focus-indicator.mat-icon-button.mat-button-base.mat-primary.mat-button-disabled:hover,
.footer-action-button-left button.mat-focus-indicator.mat-icon-button.mat-button-base.mat-primary.mat-button-disabled:hover {
  border: unset;
}

.footer-action-button-right button.mat-focus-indicator.mat-icon-button.mat-button-base.mat-primary:hover,
.footer-action-button-left button.mat-focus-indicator.mat-icon-button.mat-button-base.mat-primary:hover {
  border: 1px solid #0d6efd;
}

table.table.list-view-table .user--img {
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
}

.add-time-log-body .mat-form-textarea .mat-form-field-infix {
  min-height: 5em;
}

.add-time-log-body .mat-form-textarea .mat-form-field-infix textarea {
  max-height: 10em;
}

.only-for-card-time-log.timelog-for-table thead {
  border-bottom: 1px solid #aaa !important;
  border-top: 1px solid #aaa !important;
}

.math_ui_block-kanban-master .col-md-4 mat-card.mat-card.mat-focus-indicator.card.box-card.company-info-block.ng-star-inserted {
  max-height: 355px;
  height: 355px;
  overflow: auto;
}

.kanban-container.math_ui-block-kanban.kanban-internal .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding-top: 0.8em;
}

app-kanban-view .create-task-scroll-box {
  height: calc(100vh - 17.5em) !important;
}

.kanban-view-design-only.kanban-view-cards-for-only .kanban-internal-cards .custom-kanban-closed-card .user--img .online--status {
  bottom: -2px;
  right: 3px;
}

app-kanban-view .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  font-size: 12px;
  color: #222;
}

.tab-list-kanban-internal.kanban-view-design-only.kanban-view-cards-for-only .mat-select-arrow {
  color: #222;
}

.create-task-scroll-box .rounded.border-class:first-child {
  margin-top: 0.5em !important;
}

.kanban-view-design-only .typo-main-heading {
  max-width: 12em !important;
  width: 100% !important;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  margin-bottom: 0;
}

.kanban-container .mat-slider-thumb {
  bottom: -8px;
  width: 16px;
  height: 16px;
}

mat-tab-body .fc-view-harness.fc-view-harness-active {
  height: 130vh !important;
}

.rt-dashboard-content app-recent-projects.dashboard-over-all-card.dashboard-recent-project .card.mat-card.box-card {
  border: 1px solid rgba(0, 54, 100, 0.2509803922);
}

.custom-calendar .fc-scrollgrid {
  border-top: 1px solid rgba(0, 0, 0, 0.1215686275) !important;
}

.custom-calendar .fc-col-header .fc-col-header-cell {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275) !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #fff;
}

.custom-calendar.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-top a {
  background-color: #001528;
  border-radius: 50%;
  padding-left: 0.5em;
  padding-right: 0.5em;
  color: #fff !important;
}

th.fc-col-header-cell.fc-day.fc-day-wed.fc-day-today a.fc-col-header-cell-cushion {
  color: #fff !important;
}

th.fc-col-header-cell.fc-day.fc-day-wed.fc-day-today {
  background-color: #001528 !important;
}

.custom-calendar.timeGridDay .fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title {
  width: 11em;
}

button.fc-today-button.fc-button.fc-button-primary {
  text-transform: capitalize;
}

ul.project-gragh-cart {
  width: 40%;
  flex: 1;
  min-width: calc(40% - 10px);
  margin: 5px;
  margin-left: 1em;
  box-sizing: border-box;
}

.mega-chart-menusscroll {
  overflow-y: auto;
  max-height: 275px;
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}

.dms-content-block.rt-document-content-block .list-view-table tbody tr td:first-child {
  border: 0;
}

quill-editor .textarea-emoji-control .ql-stroke {
  stroke: #444 !important;
}

quill-editor .textarea-emoji-control .ql-fill {
  fill: #444 !important;
}

app-dashboard-calendar .custom-calendar.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-top a {
  background-color: inherit;
}

.main-sidebar.mat-drawer {
  transition: 300ms ease-in-out;
}

mat-drawer.mat-drawer.main-sidebar.dms-sidebar.document-sidebar {
  width: 240px;
}

.mat-tabe-content
  .mat-tab-body-wrapper
  .mat-tab-body
  .mat-tab-body-content
  .user-chat-box-cover {
  background-color: #fff !important;
}
.mat-tabe-content
  .mat-tab-body-wrapper
  .mat-tab-body
  .mat-tab-body-content
  .active {
  position: relative;
  background: #edf3fd !important;
  margin: 0 auto !important;
  max-width: 97%;
  border-radius: 10px;
}
.mat-tabe-content
  .mat-tab-body-wrapper
  .mat-tab-body
  .mat-tab-body-content
  .active
  h2 {
  color: #276ef1 !important;
  font-weight: 600 !important;
}

.mat-tabe-content .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content .user-chat-box-cover {
  width: 95%;
  margin: auto;
}

.time-sheet-calendar ul.list-unstyled {
  margin-bottom: 0;
}

.time-sheet-calendar .mat-form-field-wrapper {
  border: 1px solid #aaa !important;
  background-color: #fafafa;
  border-radius: 8px;
}

.download-cls-add {
  border: 1px solid #aaa !important;
}

.checklist-member-team li:hover {
  background: #f9f8f8;
}

.timesheet-module-task-list thead {
  border-top: 1px solid rgba(0, 0, 0, 0.1215686275);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);
}

.total-time-sheet-modal {
  max-width: 65em !important;
  border-radius: 8px;
  min-height: 15em;
}

.fixed-header-table thead tr {
  vertical-align: middle;
}

.timesheet-report table thead tr th {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.005em;
  min-width: 200px;
  max-width: 200px;
  color: #222;
  border-right: 1px solid #eeeeee;
  padding: 10px;
  border-top: 1px solid #0000001f;
  border-bottom: 1px solid #0000001f;
}

.timesheet-report table button.btn.mail-btn:active {
  border: unset;
}

.rt-timesheet-month-table .task_name-style {
  min-width: 290px;
  padding: 0.5rem;
}

.rt-timesheet-month-table .task_name-style .text-dwindle.d-200 {
  width: 180px !important;
}

.time-based-menu.custom-dropdown-menu {
  padding: 6px 10px;
}

.timesheet-report tfoot {
  background: #f9f8f8;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);
}

.custom-model-width {
  border-radius: 8px;
}

.mat-menu-content input.country-search {
  width: 20em !important;
  height: 34px;
  border: none;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  padding: 20px 20px 24px;
  position: fixed;
  z-index: 2;
  margin-top: -0.5em;
}

input.country-search+.country-list-button.mat-menu-item {
  margin-top: 42px;
}

.task-list-date-due-date .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0;
  border: 0;
  padding-bottom: 0.5em;
}

span.timer-block mat-label {
  position: relative;
  top: -6px;
  background-color: #fff;
  font-size: 12px;
}

.task-list-date-due-date input[type="time"] {
  font-size: 20px;
}

.add-task-rght-block-tab .msgContentBox {
  height: calc(100vh - 20em) !important;
}

td.mat-cell.cdk-cell.cdk-column-due_date.mat-column-due_date span.mat-menu-trigger:hover {
  font-weight: bold;
  border: 1px solid;
  border-radius: 8px;
  transition: 0.1s ease-in-out;
}

td.mat-cell.cdk-cell.cdk-column-due_date.mat-column-due_date span.mat-menu-trigger {
  cursor: pointer;
  padding: 4px 8px;
  transition: 0.1s ease-in-out;
}

.client_name_title {
  font-size: 12px;
  line-height: 1.5;
}

.client_comp_title {
  font-size: 10px;
  line-height: 1.5;
}

.mat-checkbox.circle--checkbox span.mat-checkbox-background {
  background-image: url(../images/whietcheckicon.svg);
  background-position: center;
  background-size: 73%;
  background-repeat: no-repeat;
}

.circle--checkbox:hover .mat-checkbox-frame {
  background-color: rgb(22, 185, 117);
  border-color: none;
}

.circle--checkbox .mat-checkbox-frame {
  background-color: #ccc;
}

form.add-time-log-model.rt-add-time-log-model mat-form-field.mat-form-field.custom-input-field.w-100.ng-tns-c28-2449.mat-primary.ng-star-inserted.mat-form-field-type-mat-input.mat-form-field-appearance-standard.mat-form-field-can-float {
  padding-right: 1em;
}

.edit-task-disable {
  pointer-events: none !important;
  opacity: 0.8;
}

.trim-info.others-text {
  padding: 5px 7px;
}

.mub-time-log-common-header.report-header-for-date .search-dropdown .select-custom.second.cst-date {
  font-size: 14px;
}

.error-message .mat-form-field-wrapper {
  border-color: red !important;
}

.closed-drawer app-user-list-view mat-tab-group.mat-tab-group.tab-list-thrice .mat-tab-label {
  padding: 0px !important;
  min-width: 9em;
}

.due_date_class .Overdue {
  display: none;
}

.due_date_class .Upcoming {
  display: none;
}

.due_date_classed .Today {
  display: none;
}

.due_date_classed .Upcoming {
  display: none;
}

.full-model-cal-ctm.full-model-calendar-ctm.task-calendar-only-right-side img.full-user-img {
  border-radius: 50px;
  margin-left: 10px;
  border: 2px solid #fff;
  position: absolute;
  right: 10px;
  top: 5px;
  height: 23px;
  width: 23px;
}

.custom-calendar.fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
  height: 86%;
}

@media only screen and (min-width: 1024px) and (max-width: 1580px) {
  .component-heading {
    font-size: 20px;
  }

  .closed-drawer app-user-list-view mat-tab-group.mat-tab-group.tab-list-thrice .mat-tab-label {
    min-width: 7em;
  }

  .closed-drawer app-user-list-view .math_block-user-profile-task-filter .task-search {
    max-width: 6em;
  }

  .math_block-user-profile-task-filter .task-search {
    max-width: 15em;
  }

  .rt-task-content .task-list-heading {
    font-size: 12px;
    padding-left: 0;
    padding-right: 0;
    line-height: 1.5;
  }

  .custom-input-field.mat-form-field.task-name input {
    font-size: 14px;
  }

  .mat-slider-horizontal {
    height: unset !important;
  }

  .mat-slider-horizontal .mat-slider-wrapper {
    top: unset !important;
  }

  .custom-modal.add-task-modal {
    max-width: 96vw !important;
    max-height: 94vh !important;
  }

  .custom--table-header .mat-header-row .mat-header-cell {
    font-size: 12px !important;
  }

  .task--title {
    max-width: 22em;
    font-size: 12px !important;
  }

  .task--content-table td.mat-cell.cdk-cell {
    font-size: 12px;
  }

  .task--expansion-panel.mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
    font-size: 16px !important;
  }

  .user-profile-ctm .create-task-scroll-box {
    height: calc(100vh - 13em) !important;
  }
}

.mat-menu-panel.custom-user-list ul.list-unstyled .online--status,
.mat-menu-panel.custom-user-list ul.list-unstyled .default-active-status {
  z-index: 5;
  position: relative;
  height: 7px;
  width: 7px;
  left: -25px;
  bottom: -13px;
  border-radius: 50%;
}

.mat-menu-panel.custom-user-list ul.list-unstyled .online--status {
  background: #fab222;
}

.mat-menu-panel.custom-user-list ul.list-unstyled .default-active-status {
  background: green;
}

.task--content-table-task-subtask.task-subtask td.mat-cell.cdk-cell.cdk-column-expand.mat-column-expand {
  display: table-cell !important;
}

.dash-drop {
  padding-left: 15px;

  .mat-form-field-underline {
    height: 0px !important;
  }

  .mat-form-field-infix {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 5px 7px 8px;

    .mat-select-trigger {
      .mat-select-value {
        padding-left: 0.5em;

        .mat-select-value-text {
          .mat-select-min-line {
            font-family: "Manrope";
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.0025em;
            color: #616161;
          }
        }
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 0px !important;
    }
  }
}

.dash-drop.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.custom--status-menu::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f1f5 !important;
  z-index: 0;
  border-radius: 10px;
  overflow: auto;
}

.custom--status-menu::-webkit-scrollbar-thumb {
  background-color: #ccc;
  z-index: 0;
  border-radius: 10px;
  overflow: auto;
}

.set-cntnt {
  height: 200px;
}

button.mat-focus-indicator.mat-menu-trigger.assignee-menu.mat-menu-item.mat-menu-item-submenu-trigger.mat-menu-item-highlighted {
  background-color: transparent;
}

.mat-menu-item.mat-menu-item-submenu-trigger:hover:not([disabled]) {
  background: transparent !important;
}

.mat-menu-item.mat-menu-item-submenu-trigger:focus:not(:focus-visible) {
  background: transparent !important;
}
.tab-list-thrice-block .tab-list-thrice .dash-drop.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}